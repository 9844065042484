import {useContext} from 'react';
import './App.css';
import Main from './pages/Main/Main';
import {firebaseAuth} from './firebase/provider/AuthProvider';
import Signup from './components/SignUp';
import Signin from './components/SignIn';
import {Switch,Route, Redirect} from "react-router-dom";

function App() {

  //const { token } = useContext(firebaseAuth)
  //console.log({token});
  //const {handleSignout,} = useContext(firebaseAuth)
  
  return (
    <Main></Main>
  );
/*
  return (
  
    <React.Fragment>
      <Switch>
        <Route exact path='/' render={rProps => token === null ? <Signin /> : <Main></Main>} />
        <Route path='/' render={rProps => token === null ? <Redirect to="/" /> : <Main></Main>} />
        <Route exact path='/signin' component={Signin} />
        <Route exact path='/signup' component={Signup} />    
      </Switch>
    </React.Fragment>
  );
  */
 
}

/*

        <Route exact path='/' render={rProps => token === null ? <Signin /> : <Main></Main>} />
        <Route path='/' render={rProps => token === null ? <Redirect to="/" /> : <Main></Main>} />
        <Route exact path='/signin' component={Signin} />
        <Route exact path='/signup' component={Signup} />      

*/

export default App;
