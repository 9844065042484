import { Charter } from "../Charter/Charter";
import { Call_Data, Call_Data_List } from "../DummyData";

export const ResidentMaintenanceScreen = () => {

    return(
        <div className='resident_call_screen'>
        <div className="flex-column">
           {/*} <div className="flex-row">
                <div>Carer Time in Room</div>
                <div>Carer Visits</div>
                <div>Proactive visits</div>
            </div>
    */}
            <div className="flex-row">
                <Charter data={Call_Data} chartType="HorizontalBarChart" />
                {/*<div>Chart</div>
                <div>Pie Chart</div>*/}
            </div>
            <div>
                <Charter chartType={"DataTable"} data={Call_Data_List} />
            </div>
        </div>
    </div>
    );
}