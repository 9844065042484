import { useState } from "react";
import { ResidentCallScreen } from "./ResidentCallScreen";
import { ResidentMoveScreen } from "./ResidentMoveScreen";
import { ResidentCareScreen } from "./ResidentCareScreen";
import { ResidentMaintenanceScreen } from "./ResidentMaintenanceScreen";
import { ResidentNotesScreen } from "./ResidentNotesScreen";

export const ResidentContent = () => {

    const Nav_Options = ["Call", "Move", "Care", "Maintenance", "Notes"];
    const [selectedOption, setSelectedOption] = useState(Nav_Options[0]);

    return(
        <div className='resident_content'>

            <div className="resident_nav">
                {Nav_Options.map((option, index) => {return(<div onClick={()=>setSelectedOption(option)} className="options" key={index}>{option}</div>)})}
            </div>

            <div className="resident_content_selection">
                {selectedOption=="Call" && <ResidentCallScreen />}
                {selectedOption=="Move" && <ResidentMoveScreen />}
                {selectedOption=="Care" && <ResidentCareScreen />}
                {selectedOption=="Maintenance" && <ResidentMaintenanceScreen />}
                {selectedOption=="Notes" && <ResidentNotesScreen />}
            </div>    

        </div>
    );
}