export const Call_Data = [
    {value:10, item: "Emergency"},
    {value:3, item: "Call"},
    {value:7, item: "Attendance"},
    {value:4, item: "Assistance"},
    {value:4, item: "Care"}
  ];


export const Care_Summary = [
    {value:4, item: "Hygiene"},
    {value:2, item: "Continence"},
    {value:7, item: "Hydration"},
    {value:3, item: "Sleep"},
    {value:4, item: "Nutrition"},
];

export const Move_Data = [
    {"carer": "James", start: "01:15", end: "01:19", duration: "4", callRaised:"Yes"},
    {"carer": "Angela", start: "03:22", end: "03:30", duration: "8", callRaised:"Yes"},
    {"carer": "Claire", start: "07:30", end: "07:35", duration: "5", callRaised:"Yes"},
    {"carer": "Chris", start: "11:31", end: "11:41", duration: "10", callRaised:"No"},
    {"carer": "Heather", start: "12:47", end: "12:47", duration: "0", callRaised:"Yes"},
    {"carer": "Ryan", start: "14:56", end: "14:59", duration: "3", callRaised:"Yes"},
    {"carer": "Angela", start: "15:23", end: "15:34", duration: "11", callRaised:"Yes"},
    {"carer": "Heather", start: "16:42", end: "16:47", duration: "5", callRaised:"No"},
    {"carer": "James", start: "18:59", end: "19:05", duration: "6", callRaised:"Yes"},
    {"carer": "Chris", start: "20:27", end: "20:31", duration: "4", callRaised:"No"},
    {"carer": "Angela", start: "21:33", end: "21:55", duration: "22", callRaised:"Yes"},
    {"carer": "Heather", start: "22:01", end: "22:18", duration: "17", callRaised:"Yes"},
    {"carer": "James", start: "23:44", end: "23:58", duration: "14", callRaised:"Yes"},
];


export const Resident_Notes = [
  {date: "2021-02-22", "carer": "Angela", note: "Daughter called to say they they would like to have a review of their care plan"},
  {date: "2021-02-22", "carer": "Angela", note: "Daughter will be picking up some clothes for the resident"},
  {date: "2021-02-22", "carer": "Angela", note: "Grandchildren will be visiting tomorrow to take them out for a walk"}
];

export const RoomUnitData = {
  "Room Unit" : ["00:00", "01:00", "02:00", "03:00", "04:00", "05:00", "06:00", "07:00", "08:00", "09:00", "10:00", "11:00", "12:00", "13:00", "14:00", "15:00", "16:00", "17:00", "18:00", "19:00", "20:00", "21:00", "22:00", "23:00"],
  "En Suite" : ["00:00", "01:00", "02:00", "03:00", "04:00", "05:00", "06:00", "07:00", "08:00", "09:00", "10:00", "11:00", "12:00", "13:00", "14:00", "15:00", "16:00", "17:00", "18:00", "19:00", "20:00", "21:00", "22:00", "23:00"]
};

export const Carer_Time_In_Room = [
    {value:10, item: "Time in room"},
    {value:30, item: "Call time"},
];


export const Call_Data_List = [
    {
      "AccessoryType": "",
      "aqRef": "MainPanel1202100022210",
      "beaconId": "First Floor",
      "callType": "Call",
      "carer": "Room Unit",
      "clearType": "Clear",
      "clearedbyPanel": "Main Panel",
      "duration": "56",
      "end": "12:03:06",
      "journeyRef": "jrny_MainPanel1202100022210",
      "locTx": "002.2",
      "panelRef": "Main Panel",
      "room": "Bedroom 2",
      "start": "2023-01-11 12:02:10.131797Z",
      "startFullDate": "12:02:10",
      "txCode": "002.2",
      "unitId": "002.2",
      "zone": "First Floor"
    },
    {
      "AccessoryType": "",
      "aqRef": "MainPanel1203180022318",
      "beaconId": "First Floor",
      "callType": "Call",
      "carer": "Room Unit",
      "clearType": "",
      "clearedbyPanel": "Main Panel",
      "duration": "434",
      "end": "12:21:37",
      "journeyRef": "jrny_MainPanel1203180022318",
      "locTx": "002.2",
      "panelRef": "Main Panel",
      "room": "Bedroom 2",
      "start": "2023-01-11 12:03:18.160961Z",
      "startFullDate": "12:03:18",
      "txCode": "002.2",
      "unitId": "002.2",
      "zone": "First Floor"
    },
    {
      "AccessoryType": "",
      "aqRef": "MainPanel122137002212137",
      "beaconId": "First Floor",
      "callType": "",
      "carer": "Room Unit",
      "clearType": "Call",
      "clearedbyPanel": "Main Panel",
      "duration": "9",
      "end": "12:21:46",
      "journeyRef": "jrny_MainPanel1203180022318",
      "locTx": "002.21",
      "panelRef": "Main Panel",
      "room": "Bedroom 2",
      "start": "2023-01-11 12:21:37.332950Z",
      "startFullDate": "12:21:37",
      "txCode": "002.21",
      "unitId": "002.21",
      "zone": "First Floor"
    },
    {
      "AccessoryType": "",
      "aqRef": "MainPanel12214600222146",
      "beaconId": "First Floor",
      "callType": "Call",
      "carer": "Room Unit",
      "clearType": "Clear",
      "clearedbyPanel": "Main Panel",
      "duration": "3209",
      "end": "13:15:15",
      "journeyRef": "jrny_MainPanel1203180022318",
      "locTx": "002.2",
      "panelRef": "Main Panel",
      "room": "Bedroom 2",
      "start": "2023-01-11 12:21:46.553068Z",
      "startFullDate": "12:21:46",
      "txCode": "002.2",
      "unitId": "002.2",
      "zone": "First Floor"
    },
    {
      "AccessoryType": "",
      "aqRef": "MainPanel1237060032377",
      "beaconId": "Second Floor",
      "callType": "Call",
      "carer": "Room Unit",
      "clearType": "Clear",
      "clearedbyPanel": "Main Panel",
      "duration": "194",
      "end": "12:40:21",
      "journeyRef": "jrny_MainPanel1237060032377",
      "locTx": "003.2",
      "panelRef": "Main Panel",
      "room": "Bedroom 3",
      "start": "2023-01-11 12:37:06.901159Z",
      "startFullDate": "12:37:06",
      "txCode": "003.2",
      "unitId": "003.2",
      "zone": "Second Floor"
    },
    {
      "AccessoryType": "",
      "aqRef": "MainPanel12382700453827",
      "beaconId": "Ground Floor",
      "callType": "Attendance",
      "carer": "Room Unit",
      "clearType": "Clear",
      "clearedbyPanel": "Main Panel",
      "duration": "2214",
      "end": "13:15:22",
      "journeyRef": "jrny_MainPanel12382700453827",
      "locTx": "004.5",
      "panelRef": "Main Panel",
      "room": "Bedroom 4",
      "start": "2023-01-11 12:38:27.685871Z",
      "startFullDate": "12:38:27",
      "txCode": "004.5",
      "unitId": "004.5",
      "zone": "Ground Floor"
    },
    {
      "AccessoryType": "",
      "aqRef": "MainPanel12402300154023",
      "beaconId": "Ground Floor",
      "callType": "Attendance",
      "carer": "Room Unit",
      "clearType": "Assistance",
      "clearedbyPanel": "Main Panel",
      "duration": "2",
      "end": "12:40:25",
      "journeyRef": "jrny_MainPanel14374100123741",
      "locTx": "001.5",
      "panelRef": "Main Panel",
      "room": "Bedroom 1",
      "start": "2023-01-11 12:40:23.535136Z",
      "startFullDate": "12:40:23",
      "txCode": "001.5",
      "unitId": "001.5",
      "zone": "Ground Floor"
    },
    {
      "AccessoryType": "",
      "aqRef": "MainPanel12402500144025",
      "beaconId": "Ground Floor",
      "callType": "Assistance",
      "carer": "Room Unit",
      "clearType": "Accessory",
      "clearedbyPanel": "Main Panel",
      "duration": "2",
      "end": "12:40:27",
      "journeyRef": "jrny_MainPanel14374100123741",
      "locTx": "001.4",
      "panelRef": "Main Panel",
      "room": "Bedroom 1",
      "start": "2023-01-11 12:40:25.540262Z",
      "startFullDate": "12:40:25",
      "txCode": "001.4",
      "unitId": "001.4",
      "zone": "Ground Floor"
    },
    {
      "AccessoryType": "",
      "aqRef": "MainPanel12402600324026",
      "beaconId": "Second Floor",
      "callType": "Call",
      "carer": "Room Unit",
      "clearType": "Clear",
      "clearedbyPanel": "Main Panel",
      "duration": "2",
      "end": "12:40:28",
      "journeyRef": "jrny_MainPanel12402600324026",
      "locTx": "003.2",
      "panelRef": "Main Panel",
      "room": "Bedroom 3",
      "start": "2023-01-11 12:40:26.534765Z",
      "startFullDate": "12:40:26",
      "txCode": "003.2",
      "unitId": "003.2",
      "zone": "Second Floor"
    },
    {
      "AccessoryType": "",
      "aqRef": "MainPanel12402700164027",
      "beaconId": "Ground Floor",
      "callType": "Accessory",
      "carer": "Room Unit",
      "clearType": "CareCall",
      "clearedbyPanel": "Main Panel",
      "duration": "2",
      "end": "12:40:29",
      "journeyRef": "jrny_MainPanel14374100123741",
      "locTx": "001.6",
      "panelRef": "Main Panel",
      "room": "Bedroom 1",
      "start": "2023-01-11 12:40:27.569485Z",
      "startFullDate": "12:40:27",
      "txCode": "001.6",
      "unitId": "001.6",
      "zone": "Ground Floor"
    },
    {
      "AccessoryType": "",
      "aqRef": "MainPanel12402900184029",
      "beaconId": "Ground Floor",
      "callType": "CareCall",
      "carer": "Room Unit",
      "clearType": "Clear",
      "clearedbyPanel": "Main Panel",
      "duration": "2",
      "end": "12:40:31",
      "journeyRef": "jrny_MainPanel14374100123741",
      "locTx": "001.8",
      "panelRef": "Main Panel",
      "room": "Bedroom 1",
      "start": "2023-01-11 12:40:29.573367Z",
      "startFullDate": "12:40:29",
      "txCode": "001.8",
      "unitId": "001.8",
      "zone": "Ground Floor"
    },
    {
      "AccessoryType": "",
      "aqRef": "MainPanel12403600124036",
      "beaconId": "Ground Floor",
      "callType": "Call",
      "carer": "Room Unit",
      "clearType": "Attendance",
      "clearedbyPanel": "Main Panel",
      "duration": "2",
      "end": "12:40:38",
      "journeyRef": "jrny_MainPanel12403600124036",
      "locTx": "001.2",
      "panelRef": "Main Panel",
      "room": "Bedroom 1",
      "start": "2023-01-11 12:40:36.536151Z",
      "startFullDate": "12:40:36",
      "txCode": "001.2",
      "unitId": "001.2",
      "zone": "Ground Floor"
    },
    {
      "AccessoryType": "",
      "aqRef": "MainPanel12403800154038",
      "beaconId": "Ground Floor",
      "callType": "Attendance",
      "carer": "Room Unit",
      "clearType": "Assistance",
      "clearedbyPanel": "Main Panel",
      "duration": "2",
      "end": "12:40:40",
      "journeyRef": "jrny_MainPanel12403600124036",
      "locTx": "001.5",
      "panelRef": "Main Panel",
      "room": "Bedroom 1",
      "start": "2023-01-11 12:40:38.543140Z",
      "startFullDate": "12:40:38",
      "txCode": "001.5",
      "unitId": "001.5",
      "zone": "Ground Floor"
    },
    {
      "AccessoryType": "",
      "aqRef": "MainPanel12404000144040",
      "beaconId": "Ground Floor",
      "callType": "Assistance",
      "carer": "Room Unit",
      "clearType": "Accessory",
      "clearedbyPanel": "Main Panel",
      "duration": "2",
      "end": "12:40:42",
      "journeyRef": "jrny_MainPanel12403600124036",
      "locTx": "001.4",
      "panelRef": "Main Panel",
      "room": "Bedroom 1",
      "start": "2023-01-11 12:40:40.547280Z",
      "startFullDate": "12:40:40",
      "txCode": "001.4",
      "unitId": "001.4",
      "zone": "Ground Floor"
    },
    {
      "AccessoryType": "",
      "aqRef": "MainPanel12404200164042",
      "beaconId": "Ground Floor",
      "callType": "Accessory",
      "carer": "Room Unit",
      "clearType": "CareCall",
      "clearedbyPanel": "Main Panel",
      "duration": "2",
      "end": "12:40:44",
      "journeyRef": "jrny_MainPanel12403600124036",
      "locTx": "001.6",
      "panelRef": "Main Panel",
      "room": "Bedroom 1",
      "start": "2023-01-11 12:40:42.562997Z",
      "startFullDate": "12:40:42",
      "txCode": "001.6",
      "unitId": "001.6",
      "zone": "Ground Floor"
    },
    {
      "AccessoryType": "",
      "aqRef": "MainPanel12404400184044",
      "beaconId": "Ground Floor",
      "callType": "CareCall",
      "carer": "Room Unit",
      "clearType": "Clear",
      "clearedbyPanel": "Main Panel",
      "duration": "2",
      "end": "12:40:46",
      "journeyRef": "jrny_MainPanel12403600124036",
      "locTx": "001.8",
      "panelRef": "Main Panel",
      "room": "Bedroom 1",
      "start": "2023-01-11 12:40:44.576440Z",
      "startFullDate": "12:40:44",
      "txCode": "001.8",
      "unitId": "001.8",
      "zone": "Ground Floor"
    },
    {
      "AccessoryType": "",
      "aqRef": "MainPanel12405100124051",
      "beaconId": "Ground Floor",
      "callType": "Call",
      "carer": "Room Unit",
      "clearType": "Attendance",
      "clearedbyPanel": "Main Panel",
      "duration": "2",
      "end": "12:40:53",
      "journeyRef": "jrny_MainPanel12405100124051",
      "locTx": "001.2",
      "panelRef": "Main Panel",
      "room": "Bedroom 1",
      "start": "2023-01-11 12:40:51.535435Z",
      "startFullDate": "12:40:51",
      "txCode": "001.2",
      "unitId": "001.2",
      "zone": "Ground Floor"
    },
    {
      "AccessoryType": "",
      "aqRef": "MainPanel12405300154053",
      "beaconId": "Ground Floor",
      "callType": "Attendance",
      "carer": "Room Unit",
      "clearType": "Assistance",
      "clearedbyPanel": "Main Panel",
      "duration": "2",
      "end": "12:40:55",
      "journeyRef": "jrny_MainPanel12405100124051",
      "locTx": "001.5",
      "panelRef": "Main Panel",
      "room": "Bedroom 1",
      "start": "2023-01-11 12:40:53.538375Z",
      "startFullDate": "12:40:53",
      "txCode": "001.5",
      "unitId": "001.5",
      "zone": "Ground Floor"
    },
    {
      "AccessoryType": "",
      "aqRef": "MainPanel12405500144055",
      "beaconId": "Ground Floor",
      "callType": "Assistance",
      "carer": "Room Unit",
      "clearType": "Accessory",
      "clearedbyPanel": "Main Panel",
      "duration": "2",
      "end": "12:40:57",
      "journeyRef": "jrny_MainPanel12405100124051",
      "locTx": "001.4",
      "panelRef": "Main Panel",
      "room": "Bedroom 1",
      "start": "2023-01-11 12:40:55.540831Z",
      "startFullDate": "12:40:55",
      "txCode": "001.4",
      "unitId": "001.4",
      "zone": "Ground Floor"
    },
    {
      "AccessoryType": "",
      "aqRef": "MainPanel12405700164057",
      "beaconId": "Ground Floor",
      "callType": "Accessory",
      "carer": "Room Unit",
      "clearType": "CareCall",
      "clearedbyPanel": "Main Panel",
      "duration": "2",
      "end": "12:40:59",
      "journeyRef": "jrny_MainPanel12405100124051",
      "locTx": "001.6",
      "panelRef": "Main Panel",
      "room": "Bedroom 1",
      "start": "2023-01-11 12:40:57.544584Z",
      "startFullDate": "12:40:57",
      "txCode": "001.6",
      "unitId": "001.6",
      "zone": "Ground Floor"
    },
    {
      "AccessoryType": "",
      "aqRef": "MainPanel12405900184059",
      "beaconId": "Ground Floor",
      "callType": "CareCall",
      "carer": "Room Unit",
      "clearType": "Clear",
      "clearedbyPanel": "Main Panel",
      "duration": "2",
      "end": "12:41:01",
      "journeyRef": "jrny_MainPanel12405100124051",
      "locTx": "001.8",
      "panelRef": "Main Panel",
      "room": "Bedroom 1",
      "start": "2023-01-11 12:40:59.547910Z",
      "startFullDate": "12:40:59",
      "txCode": "001.8",
      "unitId": "001.8",
      "zone": "Ground Floor"
    },
    {
      "AccessoryType": "",
      "aqRef": "MainPanel1241060012416",
      "beaconId": "Ground Floor",
      "callType": "Call",
      "carer": "Room Unit",
      "clearType": "Attendance",
      "clearedbyPanel": "Main Panel",
      "duration": "2",
      "end": "12:41:08",
      "journeyRef": "jrny_MainPanel1241060012416",
      "locTx": "001.2",
      "panelRef": "Main Panel",
      "room": "Bedroom 1",
      "start": "2023-01-11 12:41:06.535462Z",
      "startFullDate": "12:41:06",
      "txCode": "001.2",
      "unitId": "001.2",
      "zone": "Ground Floor"
    },
    {
      "AccessoryType": "",
      "aqRef": "MainPanel1241080015418",
      "beaconId": "Ground Floor",
      "callType": "Attendance",
      "carer": "Room Unit",
      "clearType": "Assistance",
      "clearedbyPanel": "Main Panel",
      "duration": "2",
      "end": "12:41:10",
      "journeyRef": "jrny_MainPanel1241060012416",
      "locTx": "001.5",
      "panelRef": "Main Panel",
      "room": "Bedroom 1",
      "start": "2023-01-11 12:41:08.539920Z",
      "startFullDate": "12:41:08",
      "txCode": "001.5",
      "unitId": "001.5",
      "zone": "Ground Floor"
    },
    {
      "AccessoryType": "",
      "aqRef": "MainPanel12411000144110",
      "beaconId": "Ground Floor",
      "callType": "Assistance",
      "carer": "Room Unit",
      "clearType": "Accessory",
      "clearedbyPanel": "Main Panel",
      "duration": "2",
      "end": "12:41:12",
      "journeyRef": "jrny_MainPanel1241060012416",
      "locTx": "001.4",
      "panelRef": "Main Panel",
      "room": "Bedroom 1",
      "start": "2023-01-11 12:41:10.543339Z",
      "startFullDate": "12:41:10",
      "txCode": "001.4",
      "unitId": "001.4",
      "zone": "Ground Floor"
    },
    {
      "AccessoryType": "",
      "aqRef": "MainPanel12411200164112",
      "beaconId": "Ground Floor",
      "callType": "Accessory",
      "carer": "Room Unit",
      "clearType": "CareCall",
      "clearedbyPanel": "Main Panel",
      "duration": "2",
      "end": "12:41:14",
      "journeyRef": "jrny_MainPanel1241060012416",
      "locTx": "001.6",
      "panelRef": "Main Panel",
      "room": "Bedroom 1",
      "start": "2023-01-11 12:41:12.548243Z",
      "startFullDate": "12:41:12",
      "txCode": "001.6",
      "unitId": "001.6",
      "zone": "Ground Floor"
    },
    {
      "AccessoryType": "",
      "aqRef": "MainPanel12411400184114",
      "beaconId": "Ground Floor",
      "callType": "CareCall",
      "carer": "Room Unit",
      "clearType": "Clear",
      "clearedbyPanel": "Main Panel",
      "duration": "2",
      "end": "12:41:16",
      "journeyRef": "jrny_MainPanel1241060012416",
      "locTx": "001.8",
      "panelRef": "Main Panel",
      "room": "Bedroom 1",
      "start": "2023-01-11 12:41:14.551220Z",
      "startFullDate": "12:41:14",
      "txCode": "001.8",
      "unitId": "001.8",
      "zone": "Ground Floor"
    },
    {
      "AccessoryType": "",
      "aqRef": "MainPanel12412100124121",
      "beaconId": "Ground Floor",
      "callType": "Call",
      "carer": "Room Unit",
      "clearType": "Attendance",
      "clearedbyPanel": "Main Panel",
      "duration": "2",
      "end": "12:41:23",
      "journeyRef": "jrny_MainPanel12412100124121",
      "locTx": "001.2",
      "panelRef": "Main Panel",
      "room": "Bedroom 1",
      "start": "2023-01-11 12:41:21.535743Z",
      "startFullDate": "12:41:21",
      "txCode": "001.2",
      "unitId": "001.2",
      "zone": "Ground Floor"
    },
    {
      "AccessoryType": "",
      "aqRef": "MainPanel12412300154123",
      "beaconId": "Ground Floor",
      "callType": "Attendance",
      "carer": "Room Unit",
      "clearType": "Assistance",
      "clearedbyPanel": "Main Panel",
      "duration": "2",
      "end": "12:41:25",
      "journeyRef": "jrny_MainPanel12412100124121",
      "locTx": "001.5",
      "panelRef": "Main Panel",
      "room": "Bedroom 1",
      "start": "2023-01-11 12:41:23.539296Z",
      "startFullDate": "12:41:23",
      "txCode": "001.5",
      "unitId": "001.5",
      "zone": "Ground Floor"
    },
    {
      "AccessoryType": "",
      "aqRef": "MainPanel12412500144125",
      "beaconId": "Ground Floor",
      "callType": "Assistance",
      "carer": "Room Unit",
      "clearType": "Accessory",
      "clearedbyPanel": "Main Panel",
      "duration": "2",
      "end": "12:41:27",
      "journeyRef": "jrny_MainPanel12412100124121",
      "locTx": "001.4",
      "panelRef": "Main Panel",
      "room": "Bedroom 1",
      "start": "2023-01-11 12:41:25.541812Z",
      "startFullDate": "12:41:25",
      "txCode": "001.4",
      "unitId": "001.4",
      "zone": "Ground Floor"
    },
    {
      "AccessoryType": "",
      "aqRef": "MainPanel12412700164127",
      "beaconId": "Ground Floor",
      "callType": "Accessory",
      "carer": "Room Unit",
      "clearType": "CareCall",
      "clearedbyPanel": "Main Panel",
      "duration": "2",
      "end": "12:41:29",
      "journeyRef": "jrny_MainPanel12412100124121",
      "locTx": "001.6",
      "panelRef": "Main Panel",
      "room": "Bedroom 1",
      "start": "2023-01-11 12:41:27.545529Z",
      "startFullDate": "12:41:27",
      "txCode": "001.6",
      "unitId": "001.6",
      "zone": "Ground Floor"
    },
    {
      "AccessoryType": "",
      "aqRef": "MainPanel12412900184129",
      "beaconId": "Ground Floor",
      "callType": "CareCall",
      "carer": "Room Unit",
      "clearType": "Clear",
      "clearedbyPanel": "Main Panel",
      "duration": "2",
      "end": "12:41:31",
      "journeyRef": "jrny_MainPanel12412100124121",
      "locTx": "001.8",
      "panelRef": "Main Panel",
      "room": "Bedroom 1",
      "start": "2023-01-11 12:41:29.548962Z",
      "startFullDate": "12:41:29",
      "txCode": "001.8",
      "unitId": "001.8",
      "zone": "Ground Floor"
    },
    {
      "AccessoryType": "",
      "aqRef": "MainPanel12413600124136",
      "beaconId": "Ground Floor",
      "callType": "Call",
      "carer": "Room Unit",
      "clearType": "Attendance",
      "clearedbyPanel": "Main Panel",
      "duration": "2",
      "end": "12:41:38",
      "journeyRef": "jrny_MainPanel12413600124136",
      "locTx": "001.2",
      "panelRef": "Main Panel",
      "room": "Bedroom 1",
      "start": "2023-01-11 12:41:36.535358Z",
      "startFullDate": "12:41:36",
      "txCode": "001.2",
      "unitId": "001.2",
      "zone": "Ground Floor"
    },
    {
      "AccessoryType": "",
      "aqRef": "MainPanel12413800154138",
      "beaconId": "Ground Floor",
      "callType": "Attendance",
      "carer": "Room Unit",
      "clearType": "Assistance",
      "clearedbyPanel": "Main Panel",
      "duration": "2",
      "end": "12:41:40",
      "journeyRef": "jrny_MainPanel12413600124136",
      "locTx": "001.5",
      "panelRef": "Main Panel",
      "room": "Bedroom 1",
      "start": "2023-01-11 12:41:38.538193Z",
      "startFullDate": "12:41:38",
      "txCode": "001.5",
      "unitId": "001.5",
      "zone": "Ground Floor"
    },
    {
      "AccessoryType": "",
      "aqRef": "MainPanel12414000144140",
      "beaconId": "Ground Floor",
      "callType": "Assistance",
      "carer": "Room Unit",
      "clearType": "Accessory",
      "clearedbyPanel": "Main Panel",
      "duration": "2",
      "end": "12:41:42",
      "journeyRef": "jrny_MainPanel12413600124136",
      "locTx": "001.4",
      "panelRef": "Main Panel",
      "room": "Bedroom 1",
      "start": "2023-01-11 12:41:40.542341Z",
      "startFullDate": "12:41:40",
      "txCode": "001.4",
      "unitId": "001.4",
      "zone": "Ground Floor"
    },
    {
      "AccessoryType": "",
      "aqRef": "MainPanel12414200164142",
      "beaconId": "Ground Floor",
      "callType": "Accessory",
      "carer": "Room Unit",
      "clearType": "CareCall",
      "clearedbyPanel": "Main Panel",
      "duration": "2",
      "end": "12:41:44",
      "journeyRef": "jrny_MainPanel12413600124136",
      "locTx": "001.6",
      "panelRef": "Main Panel",
      "room": "Bedroom 1",
      "start": "2023-01-11 12:41:42.545484Z",
      "startFullDate": "12:41:42",
      "txCode": "001.6",
      "unitId": "001.6",
      "zone": "Ground Floor"
    },
    {
      "AccessoryType": "",
      "aqRef": "MainPanel12414400184144",
      "beaconId": "Ground Floor",
      "callType": "CareCall",
      "carer": "Room Unit",
      "clearType": "Clear",
      "clearedbyPanel": "Main Panel",
      "duration": "2",
      "end": "12:41:46",
      "journeyRef": "jrny_MainPanel12413600124136",
      "locTx": "001.8",
      "panelRef": "Main Panel",
      "room": "Bedroom 1",
      "start": "2023-01-11 12:41:44.549384Z",
      "startFullDate": "12:41:44",
      "txCode": "001.8",
      "unitId": "001.8",
      "zone": "Ground Floor"
    },
    {
      "AccessoryType": "",
      "aqRef": "MainPanel12415100124151",
      "beaconId": "Ground Floor",
      "callType": "Call",
      "carer": "Room Unit",
      "clearType": "Attendance",
      "clearedbyPanel": "Main Panel",
      "duration": "2",
      "end": "12:41:53",
      "journeyRef": "jrny_MainPanel12415100124151",
      "locTx": "001.2",
      "panelRef": "Main Panel",
      "room": "Bedroom 1",
      "start": "2023-01-11 12:41:51.535786Z",
      "startFullDate": "12:41:51",
      "txCode": "001.2",
      "unitId": "001.2",
      "zone": "Ground Floor"
    },
    {
      "AccessoryType": "",
      "aqRef": "MainPanel12415300154153",
      "beaconId": "Ground Floor",
      "callType": "Attendance",
      "carer": "Room Unit",
      "clearType": "Assistance",
      "clearedbyPanel": "Main Panel",
      "duration": "2",
      "end": "12:41:55",
      "journeyRef": "jrny_MainPanel12415100124151",
      "locTx": "001.5",
      "panelRef": "Main Panel",
      "room": "Bedroom 1",
      "start": "2023-01-11 12:41:53.539665Z",
      "startFullDate": "12:41:53",
      "txCode": "001.5",
      "unitId": "001.5",
      "zone": "Ground Floor"
    },
    {
      "AccessoryType": "",
      "aqRef": "MainPanel12415500144155",
      "beaconId": "Ground Floor",
      "callType": "Assistance",
      "carer": "Room Unit",
      "clearType": "Accessory",
      "clearedbyPanel": "Main Panel",
      "duration": "2",
      "end": "12:41:57",
      "journeyRef": "jrny_MainPanel12415100124151",
      "locTx": "001.4",
      "panelRef": "Main Panel",
      "room": "Bedroom 1",
      "start": "2023-01-11 12:41:55.542533Z",
      "startFullDate": "12:41:55",
      "txCode": "001.4",
      "unitId": "001.4",
      "zone": "Ground Floor"
    },
    {
      "AccessoryType": "",
      "aqRef": "MainPanel12415700164157",
      "beaconId": "Ground Floor",
      "callType": "Accessory",
      "carer": "Room Unit",
      "clearType": "CareCall",
      "clearedbyPanel": "Main Panel",
      "duration": "2",
      "end": "12:41:59",
      "journeyRef": "jrny_MainPanel12415100124151",
      "locTx": "001.6",
      "panelRef": "Main Panel",
      "room": "Bedroom 1",
      "start": "2023-01-11 12:41:57.546681Z",
      "startFullDate": "12:41:57",
      "txCode": "001.6",
      "unitId": "001.6",
      "zone": "Ground Floor"
    },
    {
      "AccessoryType": "",
      "aqRef": "MainPanel12415900184159",
      "beaconId": "Ground Floor",
      "callType": "CareCall",
      "carer": "Room Unit",
      "clearType": "Clear",
      "clearedbyPanel": "Main Panel",
      "duration": "2",
      "end": "12:42:01",
      "journeyRef": "jrny_MainPanel12415100124151",
      "locTx": "001.8",
      "panelRef": "Main Panel",
      "room": "Bedroom 1",
      "start": "2023-01-11 12:41:59.551486Z",
      "startFullDate": "12:41:59",
      "txCode": "001.8",
      "unitId": "001.8",
      "zone": "Ground Floor"
    },
    {
      "AccessoryType": "",
      "aqRef": "MainPanel1242060012426",
      "beaconId": "Ground Floor",
      "callType": "Call",
      "carer": "Room Unit",
      "clearType": "Attendance",
      "clearedbyPanel": "Main Panel",
      "duration": "2",
      "end": "12:42:08",
      "journeyRef": "jrny_MainPanel1242060012426",
      "locTx": "001.2",
      "panelRef": "Main Panel",
      "room": "Bedroom 1",
      "start": "2023-01-11 12:42:06.535878Z",
      "startFullDate": "12:42:06",
      "txCode": "001.2",
      "unitId": "001.2",
      "zone": "Ground Floor"
    },
    {
      "AccessoryType": "",
      "aqRef": "MainPanel1242080015428",
      "beaconId": "Ground Floor",
      "callType": "Attendance",
      "carer": "Room Unit",
      "clearType": "Assistance",
      "clearedbyPanel": "Main Panel",
      "duration": "2",
      "end": "12:42:10",
      "journeyRef": "jrny_MainPanel1242060012426",
      "locTx": "001.5",
      "panelRef": "Main Panel",
      "room": "Bedroom 1",
      "start": "2023-01-11 12:42:08.540361Z",
      "startFullDate": "12:42:08",
      "txCode": "001.5",
      "unitId": "001.5",
      "zone": "Ground Floor"
    },
    {
      "AccessoryType": "",
      "aqRef": "MainPanel12421000144210",
      "beaconId": "Ground Floor",
      "callType": "Assistance",
      "carer": "Room Unit",
      "clearType": "Accessory",
      "clearedbyPanel": "Main Panel",
      "duration": "2",
      "end": "12:42:12",
      "journeyRef": "jrny_MainPanel1242060012426",
      "locTx": "001.4",
      "panelRef": "Main Panel",
      "room": "Bedroom 1",
      "start": "2023-01-11 12:42:10.542807Z",
      "startFullDate": "12:42:10",
      "txCode": "001.4",
      "unitId": "001.4",
      "zone": "Ground Floor"
    },
    {
      "AccessoryType": "",
      "aqRef": "MainPanel12421200164212",
      "beaconId": "Ground Floor",
      "callType": "Accessory",
      "carer": "Room Unit",
      "clearType": "CareCall",
      "clearedbyPanel": "Main Panel",
      "duration": "2",
      "end": "12:42:14",
      "journeyRef": "jrny_MainPanel1242060012426",
      "locTx": "001.6",
      "panelRef": "Main Panel",
      "room": "Bedroom 1",
      "start": "2023-01-11 12:42:12.546166Z",
      "startFullDate": "12:42:12",
      "txCode": "001.6",
      "unitId": "001.6",
      "zone": "Ground Floor"
    },
    {
      "AccessoryType": "",
      "aqRef": "MainPanel12421400184214",
      "beaconId": "Ground Floor",
      "callType": "CareCall",
      "carer": "Room Unit",
      "clearType": "Clear",
      "clearedbyPanel": "Main Panel",
      "duration": "2",
      "end": "12:42:16",
      "journeyRef": "jrny_MainPanel1242060012426",
      "locTx": "001.8",
      "panelRef": "Main Panel",
      "room": "Bedroom 1",
      "start": "2023-01-11 12:42:14.551936Z",
      "startFullDate": "12:42:14",
      "txCode": "001.8",
      "unitId": "001.8",
      "zone": "Ground Floor"
    },
    {
      "AccessoryType": "",
      "aqRef": "MainPanel12422100124221",
      "beaconId": "Ground Floor",
      "callType": "Call",
      "carer": "Room Unit",
      "clearType": "Attendance",
      "clearedbyPanel": "Main Panel",
      "duration": "2",
      "end": "12:42:23",
      "journeyRef": "jrny_MainPanel12422100124221",
      "locTx": "001.2",
      "panelRef": "Main Panel",
      "room": "Bedroom 1",
      "start": "2023-01-11 12:42:21.537590Z",
      "startFullDate": "12:42:21",
      "txCode": "001.2",
      "unitId": "001.2",
      "zone": "Ground Floor"
    },
    {
      "AccessoryType": "",
      "aqRef": "MainPanel12422300154223",
      "beaconId": "Ground Floor",
      "callType": "Attendance",
      "carer": "Room Unit",
      "clearType": "Call",
      "clearedbyPanel": "Main Panel",
      "duration": "594",
      "end": "12:52:17",
      "journeyRef": "jrny_MainPanel12422100124221",
      "locTx": "001.5",
      "panelRef": "Main Panel",
      "room": "Bedroom 1",
      "start": "2023-01-11 12:42:23.540353Z",
      "startFullDate": "12:42:23",
      "txCode": "001.5",
      "unitId": "001.5",
      "zone": "Ground Floor"
    },
    {
      "AccessoryType": "",
      "aqRef": "MainPanel12521700125217",
      "beaconId": "Ground Floor",
      "callType": "Call",
      "carer": "Room Unit",
      "clearType": "Attendance",
      "clearedbyPanel": "Main Panel",
      "duration": "1",
      "end": "12:52:19",
      "journeyRef": "jrny_MainPanel12422100124221",
      "locTx": "001.2",
      "panelRef": "Main Panel",
      "room": "Bedroom 1",
      "start": "2023-01-11 12:52:17.666607Z",
      "startFullDate": "12:52:17",
      "txCode": "001.2",
      "unitId": "001.2",
      "zone": "Ground Floor"
    },
    {
      "AccessoryType": "",
      "aqRef": "MainPanel12521900155219",
      "beaconId": "Ground Floor",
      "callType": "Attendance",
      "carer": "Room Unit",
      "clearType": "Assistance",
      "clearedbyPanel": "Main Panel",
      "duration": "2",
      "end": "12:52:21",
      "journeyRef": "jrny_MainPanel12422100124221",
      "locTx": "001.5",
      "panelRef": "Main Panel",
      "room": "Bedroom 1",
      "start": "2023-01-11 12:52:19.669099Z",
      "startFullDate": "12:52:19",
      "txCode": "001.5",
      "unitId": "001.5",
      "zone": "Ground Floor"
    },
    {
      "AccessoryType": "",
      "aqRef": "MainPanel12522100145221",
      "beaconId": "Ground Floor",
      "callType": "Assistance",
      "carer": "Room Unit",
      "clearType": "Accessory",
      "clearedbyPanel": "Main Panel",
      "duration": "2",
      "end": "12:52:23",
      "journeyRef": "jrny_MainPanel12422100124221",
      "locTx": "001.4",
      "panelRef": "Main Panel",
      "room": "Bedroom 1",
      "start": "2023-01-11 12:52:21.674239Z",
      "startFullDate": "12:52:21",
      "txCode": "001.4",
      "unitId": "001.4",
      "zone": "Ground Floor"
    },
    {
      "AccessoryType": "",
      "aqRef": "MainPanel12522200325222",
      "beaconId": "Second Floor",
      "callType": "Call",
      "carer": "Room Unit",
      "clearType": "Clear",
      "clearedbyPanel": "Main Panel",
      "duration": "2",
      "end": "12:52:24",
      "journeyRef": "jrny_MainPanel12522200325222",
      "locTx": "003.2",
      "panelRef": "Main Panel",
      "room": "Bedroom 3",
      "start": "2023-01-11 12:52:22.668287Z",
      "startFullDate": "12:52:22",
      "txCode": "003.2",
      "unitId": "003.2",
      "zone": "Second Floor"
    },
    {
      "AccessoryType": "",
      "aqRef": "MainPanel12522300165223",
      "beaconId": "Ground Floor",
      "callType": "Accessory",
      "carer": "Room Unit",
      "clearType": "CareCall",
      "clearedbyPanel": "Main Panel",
      "duration": "2",
      "end": "12:52:25",
      "journeyRef": "jrny_MainPanel12422100124221",
      "locTx": "001.6",
      "panelRef": "Main Panel",
      "room": "Bedroom 1",
      "start": "2023-01-11 12:52:23.678320Z",
      "startFullDate": "12:52:23",
      "txCode": "001.6",
      "unitId": "001.6",
      "zone": "Ground Floor"
    },
    {
      "AccessoryType": "",
      "aqRef": "MainPanel12522500185225",
      "beaconId": "Ground Floor",
      "callType": "CareCall",
      "carer": "Room Unit",
      "clearType": "Clear",
      "clearedbyPanel": "Main Panel",
      "duration": "2",
      "end": "12:52:27",
      "journeyRef": "jrny_MainPanel12422100124221",
      "locTx": "001.8",
      "panelRef": "Main Panel",
      "room": "Bedroom 1",
      "start": "2023-01-11 12:52:25.681876Z",
      "startFullDate": "12:52:25",
      "txCode": "001.8",
      "unitId": "001.8",
      "zone": "Ground Floor"
    },
    {
      "AccessoryType": "",
      "aqRef": "MainPanel12523200125232",
      "beaconId": "Ground Floor",
      "callType": "Call",
      "carer": "Room Unit",
      "clearType": "Attendance",
      "clearedbyPanel": "Main Panel",
      "duration": "2",
      "end": "12:52:34",
      "journeyRef": "jrny_MainPanel12523200125232",
      "locTx": "001.2",
      "panelRef": "Main Panel",
      "room": "Bedroom 1",
      "start": "2023-01-11 12:52:32.669910Z",
      "startFullDate": "12:52:32",
      "txCode": "001.2",
      "unitId": "001.2",
      "zone": "Ground Floor"
    },
    {
      "AccessoryType": "",
      "aqRef": "MainPanel12523400155234",
      "beaconId": "Ground Floor",
      "callType": "Attendance",
      "carer": "Room Unit",
      "clearType": "Assistance",
      "clearedbyPanel": "Main Panel",
      "duration": "2",
      "end": "12:52:36",
      "journeyRef": "jrny_MainPanel12523200125232",
      "locTx": "001.5",
      "panelRef": "Main Panel",
      "room": "Bedroom 1",
      "start": "2023-01-11 12:52:34.678102Z",
      "startFullDate": "12:52:34",
      "txCode": "001.5",
      "unitId": "001.5",
      "zone": "Ground Floor"
    },
    {
      "AccessoryType": "",
      "aqRef": "MainPanel12523600145236",
      "beaconId": "Ground Floor",
      "callType": "Assistance",
      "carer": "Room Unit",
      "clearType": "Accessory",
      "clearedbyPanel": "Main Panel",
      "duration": "3",
      "end": "12:52:39",
      "journeyRef": "jrny_MainPanel12523200125232",
      "locTx": "001.4",
      "panelRef": "Main Panel",
      "room": "Bedroom 1",
      "start": "2023-01-11 12:52:36.682608Z",
      "startFullDate": "12:52:36",
      "txCode": "001.4",
      "unitId": "001.4",
      "zone": "Ground Floor"
    },
    {
      "AccessoryType": "",
      "aqRef": "MainPanel12523800165239",
      "beaconId": "Ground Floor",
      "callType": "Accessory",
      "carer": "Room Unit",
      "clearType": "CareCall",
      "clearedbyPanel": "Main Panel",
      "duration": "2",
      "end": "12:52:40",
      "journeyRef": "jrny_MainPanel12523200125232",
      "locTx": "001.6",
      "panelRef": "Main Panel",
      "room": "Bedroom 1",
      "start": "2023-01-11 12:52:38.705270Z",
      "startFullDate": "12:52:38",
      "txCode": "001.6",
      "unitId": "001.6",
      "zone": "Ground Floor"
    },
    {
      "AccessoryType": "",
      "aqRef": "MainPanel12524000185240",
      "beaconId": "Ground Floor",
      "callType": "CareCall",
      "carer": "Room Unit",
      "clearType": "Clear",
      "clearedbyPanel": "Main Panel",
      "duration": "2",
      "end": "12:52:42",
      "journeyRef": "jrny_MainPanel12523200125232",
      "locTx": "001.8",
      "panelRef": "Main Panel",
      "room": "Bedroom 1",
      "start": "2023-01-11 12:52:40.708578Z",
      "startFullDate": "12:52:40",
      "txCode": "001.8",
      "unitId": "001.8",
      "zone": "Ground Floor"
    },
    {
      "AccessoryType": "",
      "aqRef": "MainPanel12524700125247",
      "beaconId": "Ground Floor",
      "callType": "Call",
      "carer": "Room Unit",
      "clearType": "Attendance",
      "clearedbyPanel": "Main Panel",
      "duration": "2",
      "end": "12:52:49",
      "journeyRef": "jrny_MainPanel12524700125247",
      "locTx": "001.2",
      "panelRef": "Main Panel",
      "room": "Bedroom 1",
      "start": "2023-01-11 12:52:47.670422Z",
      "startFullDate": "12:52:47",
      "txCode": "001.2",
      "unitId": "001.2",
      "zone": "Ground Floor"
    },
    {
      "AccessoryType": "",
      "aqRef": "MainPanel12524900155249",
      "beaconId": "Ground Floor",
      "callType": "Attendance",
      "carer": "Room Unit",
      "clearType": "Assistance",
      "clearedbyPanel": "Main Panel",
      "duration": "2",
      "end": "12:52:51",
      "journeyRef": "jrny_MainPanel12524700125247",
      "locTx": "001.5",
      "panelRef": "Main Panel",
      "room": "Bedroom 1",
      "start": "2023-01-11 12:52:49.675294Z",
      "startFullDate": "12:52:49",
      "txCode": "001.5",
      "unitId": "001.5",
      "zone": "Ground Floor"
    },
    {
      "AccessoryType": "",
      "aqRef": "MainPanel12525100145251",
      "beaconId": "Ground Floor",
      "callType": "Assistance",
      "carer": "Room Unit",
      "clearType": "Accessory",
      "clearedbyPanel": "Main Panel",
      "duration": "2",
      "end": "12:52:53",
      "journeyRef": "jrny_MainPanel12524700125247",
      "locTx": "001.4",
      "panelRef": "Main Panel",
      "room": "Bedroom 1",
      "start": "2023-01-11 12:52:51.677770Z",
      "startFullDate": "12:52:51",
      "txCode": "001.4",
      "unitId": "001.4",
      "zone": "Ground Floor"
    },
    {
      "AccessoryType": "",
      "aqRef": "MainPanel12525300165253",
      "beaconId": "Ground Floor",
      "callType": "Accessory",
      "carer": "Room Unit",
      "clearType": "CareCall",
      "clearedbyPanel": "Main Panel",
      "duration": "2",
      "end": "12:52:55",
      "journeyRef": "jrny_MainPanel12524700125247",
      "locTx": "001.6",
      "panelRef": "Main Panel",
      "room": "Bedroom 1",
      "start": "2023-01-11 12:52:53.681370Z",
      "startFullDate": "12:52:53",
      "txCode": "001.6",
      "unitId": "001.6",
      "zone": "Ground Floor"
    },
    {
      "AccessoryType": "",
      "aqRef": "MainPanel12525500185255",
      "beaconId": "Ground Floor",
      "callType": "CareCall",
      "carer": "Room Unit",
      "clearType": "Clear",
      "clearedbyPanel": "Main Panel",
      "duration": "2",
      "end": "12:52:57",
      "journeyRef": "jrny_MainPanel12524700125247",
      "locTx": "001.8",
      "panelRef": "Main Panel",
      "room": "Bedroom 1",
      "start": "2023-01-11 12:52:55.684816Z",
      "startFullDate": "12:52:55",
      "txCode": "001.8",
      "unitId": "001.8",
      "zone": "Ground Floor"
    },
    {
      "AccessoryType": "",
      "aqRef": "MainPanel1253020012532",
      "beaconId": "Ground Floor",
      "callType": "Call",
      "carer": "Room Unit",
      "clearType": "Attendance",
      "clearedbyPanel": "Main Panel",
      "duration": "2",
      "end": "12:53:04",
      "journeyRef": "jrny_MainPanel1253020012532",
      "locTx": "001.2",
      "panelRef": "Main Panel",
      "room": "Bedroom 1",
      "start": "2023-01-11 12:53:02.668789Z",
      "startFullDate": "12:53:02",
      "txCode": "001.2",
      "unitId": "001.2",
      "zone": "Ground Floor"
    },
    {
      "AccessoryType": "",
      "aqRef": "MainPanel1253040015534",
      "beaconId": "Ground Floor",
      "callType": "Attendance",
      "carer": "Room Unit",
      "clearType": "Assistance",
      "clearedbyPanel": "Main Panel",
      "duration": "2",
      "end": "12:53:06",
      "journeyRef": "jrny_MainPanel1253020012532",
      "locTx": "001.5",
      "panelRef": "Main Panel",
      "room": "Bedroom 1",
      "start": "2023-01-11 12:53:04.672121Z",
      "startFullDate": "12:53:04",
      "txCode": "001.5",
      "unitId": "001.5",
      "zone": "Ground Floor"
    },
    {
      "AccessoryType": "",
      "aqRef": "MainPanel1253060014536",
      "beaconId": "Ground Floor",
      "callType": "Assistance",
      "carer": "Room Unit",
      "clearType": "Accessory",
      "clearedbyPanel": "Main Panel",
      "duration": "2",
      "end": "12:53:08",
      "journeyRef": "jrny_MainPanel1253020012532",
      "locTx": "001.4",
      "panelRef": "Main Panel",
      "room": "Bedroom 1",
      "start": "2023-01-11 12:53:06.674829Z",
      "startFullDate": "12:53:06",
      "txCode": "001.4",
      "unitId": "001.4",
      "zone": "Ground Floor"
    },
    {
      "AccessoryType": "",
      "aqRef": "MainPanel1253080016538",
      "beaconId": "Ground Floor",
      "callType": "Accessory",
      "carer": "Room Unit",
      "clearType": "CareCall",
      "clearedbyPanel": "Main Panel",
      "duration": "2",
      "end": "12:53:10",
      "journeyRef": "jrny_MainPanel1253020012532",
      "locTx": "001.6",
      "panelRef": "Main Panel",
      "room": "Bedroom 1",
      "start": "2023-01-11 12:53:08.678174Z",
      "startFullDate": "12:53:08",
      "txCode": "001.6",
      "unitId": "001.6",
      "zone": "Ground Floor"
    },
    {
      "AccessoryType": "",
      "aqRef": "MainPanel12531000185310",
      "beaconId": "Ground Floor",
      "callType": "CareCall",
      "carer": "Room Unit",
      "clearType": "Clear",
      "clearedbyPanel": "Main Panel",
      "duration": "2",
      "end": "12:53:12",
      "journeyRef": "jrny_MainPanel1253020012532",
      "locTx": "001.8",
      "panelRef": "Main Panel",
      "room": "Bedroom 1",
      "start": "2023-01-11 12:53:10.680853Z",
      "startFullDate": "12:53:10",
      "txCode": "001.8",
      "unitId": "001.8",
      "zone": "Ground Floor"
    },
    {
      "AccessoryType": "",
      "aqRef": "MainPanel12531700125317",
      "beaconId": "Ground Floor",
      "callType": "Call",
      "carer": "Room Unit",
      "clearType": "Attendance",
      "clearedbyPanel": "Main Panel",
      "duration": "2",
      "end": "12:53:19",
      "journeyRef": "jrny_MainPanel12531700125317",
      "locTx": "001.2",
      "panelRef": "Main Panel",
      "room": "Bedroom 1",
      "start": "2023-01-11 12:53:17.671272Z",
      "startFullDate": "12:53:17",
      "txCode": "001.2",
      "unitId": "001.2",
      "zone": "Ground Floor"
    },
    {
      "AccessoryType": "",
      "aqRef": "MainPanel12531900155319",
      "beaconId": "Ground Floor",
      "callType": "Attendance",
      "carer": "Room Unit",
      "clearType": "Assistance",
      "clearedbyPanel": "Main Panel",
      "duration": "2",
      "end": "12:53:21",
      "journeyRef": "jrny_MainPanel12531700125317",
      "locTx": "001.5",
      "panelRef": "Main Panel",
      "room": "Bedroom 1",
      "start": "2023-01-11 12:53:19.675650Z",
      "startFullDate": "12:53:19",
      "txCode": "001.5",
      "unitId": "001.5",
      "zone": "Ground Floor"
    },
    {
      "AccessoryType": "",
      "aqRef": "MainPanel12532100145321",
      "beaconId": "Ground Floor",
      "callType": "Assistance",
      "carer": "Room Unit",
      "clearType": "Accessory",
      "clearedbyPanel": "Main Panel",
      "duration": "2",
      "end": "12:53:23",
      "journeyRef": "jrny_MainPanel12531700125317",
      "locTx": "001.4",
      "panelRef": "Main Panel",
      "room": "Bedroom 1",
      "start": "2023-01-11 12:53:21.679439Z",
      "startFullDate": "12:53:21",
      "txCode": "001.4",
      "unitId": "001.4",
      "zone": "Ground Floor"
    },
    {
      "AccessoryType": "",
      "aqRef": "MainPanel12532300165323",
      "beaconId": "Ground Floor",
      "callType": "Accessory",
      "carer": "Room Unit",
      "clearType": "CareCall",
      "clearedbyPanel": "Main Panel",
      "duration": "2",
      "end": "12:53:25",
      "journeyRef": "jrny_MainPanel12531700125317",
      "locTx": "001.6",
      "panelRef": "Main Panel",
      "room": "Bedroom 1",
      "start": "2023-01-11 12:53:23.680944Z",
      "startFullDate": "12:53:23",
      "txCode": "001.6",
      "unitId": "001.6",
      "zone": "Ground Floor"
    },
    {
      "AccessoryType": "",
      "aqRef": "MainPanel12532500185325",
      "beaconId": "Ground Floor",
      "callType": "CareCall",
      "carer": "Room Unit",
      "clearType": "Clear",
      "clearedbyPanel": "Main Panel",
      "duration": "2",
      "end": "12:53:27",
      "journeyRef": "jrny_MainPanel12531700125317",
      "locTx": "001.8",
      "panelRef": "Main Panel",
      "room": "Bedroom 1",
      "start": "2023-01-11 12:53:25.684406Z",
      "startFullDate": "12:53:25",
      "txCode": "001.8",
      "unitId": "001.8",
      "zone": "Ground Floor"
    },
    {
      "AccessoryType": "",
      "aqRef": "MainPanel12533200125332",
      "beaconId": "Ground Floor",
      "callType": "Call",
      "carer": "Room Unit",
      "clearType": "Attendance",
      "clearedbyPanel": "Main Panel",
      "duration": "2",
      "end": "12:53:34",
      "journeyRef": "jrny_MainPanel12533200125332",
      "locTx": "001.2",
      "panelRef": "Main Panel",
      "room": "Bedroom 1",
      "start": "2023-01-11 12:53:32.669081Z",
      "startFullDate": "12:53:32",
      "txCode": "001.2",
      "unitId": "001.2",
      "zone": "Ground Floor"
    },
    {
      "AccessoryType": "",
      "aqRef": "MainPanel12533400155334",
      "beaconId": "Ground Floor",
      "callType": "Attendance",
      "carer": "Room Unit",
      "clearType": "Assistance",
      "clearedbyPanel": "Main Panel",
      "duration": "2",
      "end": "12:53:36",
      "journeyRef": "jrny_MainPanel12533200125332",
      "locTx": "001.5",
      "panelRef": "Main Panel",
      "room": "Bedroom 1",
      "start": "2023-01-11 12:53:34.672701Z",
      "startFullDate": "12:53:34",
      "txCode": "001.5",
      "unitId": "001.5",
      "zone": "Ground Floor"
    },
    {
      "AccessoryType": "",
      "aqRef": "MainPanel12533600145336",
      "beaconId": "Ground Floor",
      "callType": "Assistance",
      "carer": "Room Unit",
      "clearType": "Accessory",
      "clearedbyPanel": "Main Panel",
      "duration": "2",
      "end": "12:53:38",
      "journeyRef": "jrny_MainPanel12533200125332",
      "locTx": "001.4",
      "panelRef": "Main Panel",
      "room": "Bedroom 1",
      "start": "2023-01-11 12:53:36.677232Z",
      "startFullDate": "12:53:36",
      "txCode": "001.4",
      "unitId": "001.4",
      "zone": "Ground Floor"
    },
    {
      "AccessoryType": "",
      "aqRef": "MainPanel12533800165338",
      "beaconId": "Ground Floor",
      "callType": "Accessory",
      "carer": "Room Unit",
      "clearType": "CareCall",
      "clearedbyPanel": "Main Panel",
      "duration": "2",
      "end": "12:53:40",
      "journeyRef": "jrny_MainPanel12533200125332",
      "locTx": "001.6",
      "panelRef": "Main Panel",
      "room": "Bedroom 1",
      "start": "2023-01-11 12:53:38.683570Z",
      "startFullDate": "12:53:38",
      "txCode": "001.6",
      "unitId": "001.6",
      "zone": "Ground Floor"
    },
    {
      "AccessoryType": "",
      "aqRef": "MainPanel12534000185340",
      "beaconId": "Ground Floor",
      "callType": "CareCall",
      "carer": "Room Unit",
      "clearType": "Clear",
      "clearedbyPanel": "Main Panel",
      "duration": "2",
      "end": "12:53:42",
      "journeyRef": "jrny_MainPanel12533200125332",
      "locTx": "001.8",
      "panelRef": "Main Panel",
      "room": "Bedroom 1",
      "start": "2023-01-11 12:53:40.687607Z",
      "startFullDate": "12:53:40",
      "txCode": "001.8",
      "unitId": "001.8",
      "zone": "Ground Floor"
    },
    {
      "AccessoryType": "",
      "aqRef": "MainPanel12534700125347",
      "beaconId": "Ground Floor",
      "callType": "Call",
      "carer": "Room Unit",
      "clearType": "Attendance",
      "clearedbyPanel": "Main Panel",
      "duration": "2",
      "end": "12:53:49",
      "journeyRef": "jrny_MainPanel12534700125347",
      "locTx": "001.2",
      "panelRef": "Main Panel",
      "room": "Bedroom 1",
      "start": "2023-01-11 12:53:47.669468Z",
      "startFullDate": "12:53:47",
      "txCode": "001.2",
      "unitId": "001.2",
      "zone": "Ground Floor"
    },
    {
      "AccessoryType": "",
      "aqRef": "MainPanel12534900155349",
      "beaconId": "Ground Floor",
      "callType": "Attendance",
      "carer": "Room Unit",
      "clearType": "Assistance",
      "clearedbyPanel": "Main Panel",
      "duration": "2",
      "end": "12:53:51",
      "journeyRef": "jrny_MainPanel12534700125347",
      "locTx": "001.5",
      "panelRef": "Main Panel",
      "room": "Bedroom 1",
      "start": "2023-01-11 12:53:49.673391Z",
      "startFullDate": "12:53:49",
      "txCode": "001.5",
      "unitId": "001.5",
      "zone": "Ground Floor"
    },
    {
      "AccessoryType": "",
      "aqRef": "MainPanel12535100145351",
      "beaconId": "Ground Floor",
      "callType": "Assistance",
      "carer": "Room Unit",
      "clearType": "Accessory",
      "clearedbyPanel": "Main Panel",
      "duration": "2",
      "end": "12:53:53",
      "journeyRef": "jrny_MainPanel12534700125347",
      "locTx": "001.4",
      "panelRef": "Main Panel",
      "room": "Bedroom 1",
      "start": "2023-01-11 12:53:51.678451Z",
      "startFullDate": "12:53:51",
      "txCode": "001.4",
      "unitId": "001.4",
      "zone": "Ground Floor"
    },
    {
      "AccessoryType": "",
      "aqRef": "MainPanel12535300165353",
      "beaconId": "Ground Floor",
      "callType": "Accessory",
      "carer": "Room Unit",
      "clearType": "CareCall",
      "clearedbyPanel": "Main Panel",
      "duration": "2",
      "end": "12:53:55",
      "journeyRef": "jrny_MainPanel12534700125347",
      "locTx": "001.6",
      "panelRef": "Main Panel",
      "room": "Bedroom 1",
      "start": "2023-01-11 12:53:53.685444Z",
      "startFullDate": "12:53:53",
      "txCode": "001.6",
      "unitId": "001.6",
      "zone": "Ground Floor"
    },
    {
      "AccessoryType": "",
      "aqRef": "MainPanel12535500185355",
      "beaconId": "Ground Floor",
      "callType": "CareCall",
      "carer": "Room Unit",
      "clearType": "Clear",
      "clearedbyPanel": "Main Panel",
      "duration": "2",
      "end": "12:53:57",
      "journeyRef": "jrny_MainPanel12534700125347",
      "locTx": "001.8",
      "panelRef": "Main Panel",
      "room": "Bedroom 1",
      "start": "2023-01-11 12:53:55.690398Z",
      "startFullDate": "12:53:55",
      "txCode": "001.8",
      "unitId": "001.8",
      "zone": "Ground Floor"
    },
    {
      "AccessoryType": "",
      "aqRef": "MainPanel1254020012542",
      "beaconId": "Ground Floor",
      "callType": "Call",
      "carer": "Room Unit",
      "clearType": "Attendance",
      "clearedbyPanel": "Main Panel",
      "duration": "2",
      "end": "12:54:05",
      "journeyRef": "jrny_MainPanel1254020012542",
      "locTx": "001.2",
      "panelRef": "Main Panel",
      "room": "Bedroom 1",
      "start": "2023-01-11 12:54:02.670233Z",
      "startFullDate": "12:54:02",
      "txCode": "001.2",
      "unitId": "001.2",
      "zone": "Ground Floor"
    },
    {
      "AccessoryType": "",
      "aqRef": "MainPanel1254040015545",
      "beaconId": "Ground Floor",
      "callType": "Attendance",
      "carer": "Room Unit",
      "clearType": "Assistance",
      "clearedbyPanel": "Main Panel",
      "duration": "2",
      "end": "12:54:06",
      "journeyRef": "jrny_MainPanel1254020012542",
      "locTx": "001.5",
      "panelRef": "Main Panel",
      "room": "Bedroom 1",
      "start": "2023-01-11 12:54:04.671943Z",
      "startFullDate": "12:54:04",
      "txCode": "001.5",
      "unitId": "001.5",
      "zone": "Ground Floor"
    },
    {
      "AccessoryType": "",
      "aqRef": "MainPanel1254060014546",
      "beaconId": "Ground Floor",
      "callType": "Assistance",
      "carer": "Room Unit",
      "clearType": "Accessory",
      "clearedbyPanel": "Main Panel",
      "duration": "2",
      "end": "12:54:08",
      "journeyRef": "jrny_MainPanel1254020012542",
      "locTx": "001.4",
      "panelRef": "Main Panel",
      "room": "Bedroom 1",
      "start": "2023-01-11 12:54:06.675759Z",
      "startFullDate": "12:54:06",
      "txCode": "001.4",
      "unitId": "001.4",
      "zone": "Ground Floor"
    },
    {
      "AccessoryType": "",
      "aqRef": "MainPanel1254080016548",
      "beaconId": "Ground Floor",
      "callType": "Accessory",
      "carer": "Room Unit",
      "clearType": "CareCall",
      "clearedbyPanel": "Main Panel",
      "duration": "2",
      "end": "12:54:10",
      "journeyRef": "jrny_MainPanel1254020012542",
      "locTx": "001.6",
      "panelRef": "Main Panel",
      "room": "Bedroom 1",
      "start": "2023-01-11 12:54:08.682466Z",
      "startFullDate": "12:54:08",
      "txCode": "001.6",
      "unitId": "001.6",
      "zone": "Ground Floor"
    },
    {
      "AccessoryType": "",
      "aqRef": "MainPanel12541000185410",
      "beaconId": "Ground Floor",
      "callType": "CareCall",
      "carer": "Room Unit",
      "clearType": "Clear",
      "clearedbyPanel": "Main Panel",
      "duration": "2",
      "end": "12:54:12",
      "journeyRef": "jrny_MainPanel1254020012542",
      "locTx": "001.8",
      "panelRef": "Main Panel",
      "room": "Bedroom 1",
      "start": "2023-01-11 12:54:10.685914Z",
      "startFullDate": "12:54:10",
      "txCode": "001.8",
      "unitId": "001.8",
      "zone": "Ground Floor"
    },
    {
      "AccessoryType": "",
      "aqRef": "MainPanel12541700125417",
      "beaconId": "Ground Floor",
      "callType": "Call",
      "carer": "Room Unit",
      "clearType": "Attendance",
      "clearedbyPanel": "Main Panel",
      "duration": "2",
      "end": "12:54:19",
      "journeyRef": "jrny_MainPanel12541700125417",
      "locTx": "001.2",
      "panelRef": "Main Panel",
      "room": "Bedroom 1",
      "start": "2023-01-11 12:54:17.670772Z",
      "startFullDate": "12:54:17",
      "txCode": "001.2",
      "unitId": "001.2",
      "zone": "Ground Floor"
    },
    {
      "AccessoryType": "",
      "aqRef": "MainPanel12541900155419",
      "beaconId": "Ground Floor",
      "callType": "Attendance",
      "carer": "Room Unit",
      "clearType": "Assistance",
      "clearedbyPanel": "Main Panel",
      "duration": "2",
      "end": "12:54:21",
      "journeyRef": "jrny_MainPanel12541700125417",
      "locTx": "001.5",
      "panelRef": "Main Panel",
      "room": "Bedroom 1",
      "start": "2023-01-11 12:54:19.678059Z",
      "startFullDate": "12:54:19",
      "txCode": "001.5",
      "unitId": "001.5",
      "zone": "Ground Floor"
    },
    {
      "AccessoryType": "",
      "aqRef": "MainPanel12542100145421",
      "beaconId": "Ground Floor",
      "callType": "Assistance",
      "carer": "Room Unit",
      "clearType": "Accessory",
      "clearedbyPanel": "Main Panel",
      "duration": "2",
      "end": "12:54:23",
      "journeyRef": "jrny_MainPanel12541700125417",
      "locTx": "001.4",
      "panelRef": "Main Panel",
      "room": "Bedroom 1",
      "start": "2023-01-11 12:54:21.682713Z",
      "startFullDate": "12:54:21",
      "txCode": "001.4",
      "unitId": "001.4",
      "zone": "Ground Floor"
    },
    {
      "AccessoryType": "",
      "aqRef": "MainPanel12542300165423",
      "beaconId": "Ground Floor",
      "callType": "Accessory",
      "carer": "Room Unit",
      "clearType": "CareCall",
      "clearedbyPanel": "Main Panel",
      "duration": "2",
      "end": "12:54:25",
      "journeyRef": "jrny_MainPanel12541700125417",
      "locTx": "001.6",
      "panelRef": "Main Panel",
      "room": "Bedroom 1",
      "start": "2023-01-11 12:54:23.686933Z",
      "startFullDate": "12:54:23",
      "txCode": "001.6",
      "unitId": "001.6",
      "zone": "Ground Floor"
    },
    {
      "AccessoryType": "",
      "aqRef": "MainPanel12542500185425",
      "beaconId": "Ground Floor",
      "callType": "CareCall",
      "carer": "Room Unit",
      "clearType": "Clear",
      "clearedbyPanel": "Main Panel",
      "duration": "2",
      "end": "12:54:27",
      "journeyRef": "jrny_MainPanel12541700125417",
      "locTx": "001.8",
      "panelRef": "Main Panel",
      "room": "Bedroom 1",
      "start": "2023-01-11 12:54:25.691702Z",
      "startFullDate": "12:54:25",
      "txCode": "001.8",
      "unitId": "001.8",
      "zone": "Ground Floor"
    },
    {
      "AccessoryType": "",
      "aqRef": "MainPanel12543200125432",
      "beaconId": "Ground Floor",
      "callType": "Call",
      "carer": "Room Unit",
      "clearType": "Attendance",
      "clearedbyPanel": "Main Panel",
      "duration": "2",
      "end": "12:54:34",
      "journeyRef": "jrny_MainPanel12543200125432",
      "locTx": "001.2",
      "panelRef": "Main Panel",
      "room": "Bedroom 1",
      "start": "2023-01-11 12:54:32.672055Z",
      "startFullDate": "12:54:32",
      "txCode": "001.2",
      "unitId": "001.2",
      "zone": "Ground Floor"
    },
    {
      "AccessoryType": "",
      "aqRef": "MainPanel12543400155434",
      "beaconId": "Ground Floor",
      "callType": "Attendance",
      "carer": "Room Unit",
      "clearType": "Assistance",
      "clearedbyPanel": "Main Panel",
      "duration": "2",
      "end": "12:54:36",
      "journeyRef": "jrny_MainPanel12543200125432",
      "locTx": "001.5",
      "panelRef": "Main Panel",
      "room": "Bedroom 1",
      "start": "2023-01-11 12:54:34.675837Z",
      "startFullDate": "12:54:34",
      "txCode": "001.5",
      "unitId": "001.5",
      "zone": "Ground Floor"
    },
    {
      "AccessoryType": "",
      "aqRef": "MainPanel12543600145436",
      "beaconId": "Ground Floor",
      "callType": "Assistance",
      "carer": "Room Unit",
      "clearType": "Accessory",
      "clearedbyPanel": "Main Panel",
      "duration": "2",
      "end": "12:54:38",
      "journeyRef": "jrny_MainPanel12543200125432",
      "locTx": "001.4",
      "panelRef": "Main Panel",
      "room": "Bedroom 1",
      "start": "2023-01-11 12:54:36.678386Z",
      "startFullDate": "12:54:36",
      "txCode": "001.4",
      "unitId": "001.4",
      "zone": "Ground Floor"
    },
    {
      "AccessoryType": "",
      "aqRef": "MainPanel12543800165438",
      "beaconId": "Ground Floor",
      "callType": "Accessory",
      "carer": "Room Unit",
      "clearType": "CareCall",
      "clearedbyPanel": "Main Panel",
      "duration": "2",
      "end": "12:54:40",
      "journeyRef": "jrny_MainPanel12543200125432",
      "locTx": "001.6",
      "panelRef": "Main Panel",
      "room": "Bedroom 1",
      "start": "2023-01-11 12:54:38.683046Z",
      "startFullDate": "12:54:38",
      "txCode": "001.6",
      "unitId": "001.6",
      "zone": "Ground Floor"
    },
    {
      "AccessoryType": "",
      "aqRef": "MainPanel12544000185440",
      "beaconId": "Ground Floor",
      "callType": "CareCall",
      "carer": "Room Unit",
      "clearType": "Clear",
      "clearedbyPanel": "Main Panel",
      "duration": "2",
      "end": "12:54:42",
      "journeyRef": "jrny_MainPanel12543200125432",
      "locTx": "001.8",
      "panelRef": "Main Panel",
      "room": "Bedroom 1",
      "start": "2023-01-11 12:54:40.686203Z",
      "startFullDate": "12:54:40",
      "txCode": "001.8",
      "unitId": "001.8",
      "zone": "Ground Floor"
    },
    {
      "AccessoryType": "",
      "aqRef": "MainPanel12544700125447",
      "beaconId": "Ground Floor",
      "callType": "Call",
      "carer": "Room Unit",
      "clearType": "Attendance",
      "clearedbyPanel": "Main Panel",
      "duration": "2",
      "end": "12:54:49",
      "journeyRef": "jrny_MainPanel12544700125447",
      "locTx": "001.2",
      "panelRef": "Main Panel",
      "room": "Bedroom 1",
      "start": "2023-01-11 12:54:47.673651Z",
      "startFullDate": "12:54:47",
      "txCode": "001.2",
      "unitId": "001.2",
      "zone": "Ground Floor"
    },
    {
      "AccessoryType": "",
      "aqRef": "MainPanel12544900155449",
      "beaconId": "Ground Floor",
      "callType": "Attendance",
      "carer": "Room Unit",
      "clearType": "Assistance",
      "clearedbyPanel": "Main Panel",
      "duration": "2",
      "end": "12:54:51",
      "journeyRef": "jrny_MainPanel12544700125447",
      "locTx": "001.5",
      "panelRef": "Main Panel",
      "room": "Bedroom 1",
      "start": "2023-01-11 12:54:49.677808Z",
      "startFullDate": "12:54:49",
      "txCode": "001.5",
      "unitId": "001.5",
      "zone": "Ground Floor"
    },
    {
      "AccessoryType": "",
      "aqRef": "MainPanel12545100145451",
      "beaconId": "Ground Floor",
      "callType": "Assistance",
      "carer": "Room Unit",
      "clearType": "Accessory",
      "clearedbyPanel": "Main Panel",
      "duration": "2",
      "end": "12:54:54",
      "journeyRef": "jrny_MainPanel12544700125447",
      "locTx": "001.4",
      "panelRef": "Main Panel",
      "room": "Bedroom 1",
      "start": "2023-01-11 12:54:51.680599Z",
      "startFullDate": "12:54:51",
      "txCode": "001.4",
      "unitId": "001.4",
      "zone": "Ground Floor"
    },
    {
      "AccessoryType": "",
      "aqRef": "MainPanel12545300165454",
      "beaconId": "Ground Floor",
      "callType": "Accessory",
      "carer": "Room Unit",
      "clearType": "CareCall",
      "clearedbyPanel": "Main Panel",
      "duration": "2",
      "end": "12:54:55",
      "journeyRef": "jrny_MainPanel12544700125447",
      "locTx": "001.6",
      "panelRef": "Main Panel",
      "room": "Bedroom 1",
      "start": "2023-01-11 12:54:53.685420Z",
      "startFullDate": "12:54:53",
      "txCode": "001.6",
      "unitId": "001.6",
      "zone": "Ground Floor"
    },
    {
      "AccessoryType": "",
      "aqRef": "MainPanel12545500185455",
      "beaconId": "Ground Floor",
      "callType": "CareCall",
      "carer": "Room Unit",
      "clearType": "Clear",
      "clearedbyPanel": "Main Panel",
      "duration": "2",
      "end": "12:54:57",
      "journeyRef": "jrny_MainPanel12544700125447",
      "locTx": "001.8",
      "panelRef": "Main Panel",
      "room": "Bedroom 1",
      "start": "2023-01-11 12:54:55.691482Z",
      "startFullDate": "12:54:55",
      "txCode": "001.8",
      "unitId": "001.8",
      "zone": "Ground Floor"
    },
    {
      "AccessoryType": "",
      "aqRef": "MainPanel1255020012552",
      "beaconId": "Ground Floor",
      "callType": "Call",
      "carer": "Room Unit",
      "clearType": "Attendance",
      "clearedbyPanel": "Main Panel",
      "duration": "2",
      "end": "12:55:04",
      "journeyRef": "jrny_MainPanel1255020012552",
      "locTx": "001.2",
      "panelRef": "Main Panel",
      "room": "Bedroom 1",
      "start": "2023-01-11 12:55:02.670987Z",
      "startFullDate": "12:55:02",
      "txCode": "001.2",
      "unitId": "001.2",
      "zone": "Ground Floor"
    },
    {
      "AccessoryType": "",
      "aqRef": "MainPanel1255040015554",
      "beaconId": "Ground Floor",
      "callType": "Attendance",
      "carer": "Room Unit",
      "clearType": "Assistance",
      "clearedbyPanel": "Main Panel",
      "duration": "2",
      "end": "12:55:06",
      "journeyRef": "jrny_MainPanel1255020012552",
      "locTx": "001.5",
      "panelRef": "Main Panel",
      "room": "Bedroom 1",
      "start": "2023-01-11 12:55:04.675652Z",
      "startFullDate": "12:55:04",
      "txCode": "001.5",
      "unitId": "001.5",
      "zone": "Ground Floor"
    },
    {
      "AccessoryType": "",
      "aqRef": "MainPanel1255060014556",
      "beaconId": "Ground Floor",
      "callType": "Assistance",
      "carer": "Room Unit",
      "clearType": "Accessory",
      "clearedbyPanel": "Main Panel",
      "duration": "2",
      "end": "12:55:08",
      "journeyRef": "jrny_MainPanel1255020012552",
      "locTx": "001.4",
      "panelRef": "Main Panel",
      "room": "Bedroom 1",
      "start": "2023-01-11 12:55:06.685247Z",
      "startFullDate": "12:55:06",
      "txCode": "001.4",
      "unitId": "001.4",
      "zone": "Ground Floor"
    },
    {
      "AccessoryType": "",
      "aqRef": "MainPanel1255080016558",
      "beaconId": "Ground Floor",
      "callType": "Accessory",
      "carer": "Room Unit",
      "clearType": "CareCall",
      "clearedbyPanel": "Main Panel",
      "duration": "2",
      "end": "12:55:10",
      "journeyRef": "jrny_MainPanel1255020012552",
      "locTx": "001.6",
      "panelRef": "Main Panel",
      "room": "Bedroom 1",
      "start": "2023-01-11 12:55:08.691085Z",
      "startFullDate": "12:55:08",
      "txCode": "001.6",
      "unitId": "001.6",
      "zone": "Ground Floor"
    },
    {
      "AccessoryType": "",
      "aqRef": "MainPanel12551000185510",
      "beaconId": "Ground Floor",
      "callType": "CareCall",
      "carer": "Room Unit",
      "clearType": "Clear",
      "clearedbyPanel": "Main Panel",
      "duration": "2",
      "end": "12:55:12",
      "journeyRef": "jrny_MainPanel1255020012552",
      "locTx": "001.8",
      "panelRef": "Main Panel",
      "room": "Bedroom 1",
      "start": "2023-01-11 12:55:10.694792Z",
      "startFullDate": "12:55:10",
      "txCode": "001.8",
      "unitId": "001.8",
      "zone": "Ground Floor"
    },
    {
      "AccessoryType": "",
      "aqRef": "MainPanel12551700125517",
      "beaconId": "Ground Floor",
      "callType": "Call",
      "carer": "Room Unit",
      "clearType": "Attendance",
      "clearedbyPanel": "Main Panel",
      "duration": "2",
      "end": "12:55:19",
      "journeyRef": "jrny_MainPanel12551700125517",
      "locTx": "001.2",
      "panelRef": "Main Panel",
      "room": "Bedroom 1",
      "start": "2023-01-11 12:55:17.670810Z",
      "startFullDate": "12:55:17",
      "txCode": "001.2",
      "unitId": "001.2",
      "zone": "Ground Floor"
    },
    {
      "AccessoryType": "",
      "aqRef": "MainPanel12551900155519",
      "beaconId": "Ground Floor",
      "callType": "Attendance",
      "carer": "Room Unit",
      "clearType": "Assistance",
      "clearedbyPanel": "Main Panel",
      "duration": "2",
      "end": "12:55:21",
      "journeyRef": "jrny_MainPanel12551700125517",
      "locTx": "001.5",
      "panelRef": "Main Panel",
      "room": "Bedroom 1",
      "start": "2023-01-11 12:55:19.673715Z",
      "startFullDate": "12:55:19",
      "txCode": "001.5",
      "unitId": "001.5",
      "zone": "Ground Floor"
    },
    {
      "AccessoryType": "",
      "aqRef": "MainPanel12552100145521",
      "beaconId": "Ground Floor",
      "callType": "Assistance",
      "carer": "Room Unit",
      "clearType": "Accessory",
      "clearedbyPanel": "Main Panel",
      "duration": "2",
      "end": "12:55:23",
      "journeyRef": "jrny_MainPanel12551700125517",
      "locTx": "001.4",
      "panelRef": "Main Panel",
      "room": "Bedroom 1",
      "start": "2023-01-11 12:55:21.678267Z",
      "startFullDate": "12:55:21",
      "txCode": "001.4",
      "unitId": "001.4",
      "zone": "Ground Floor"
    },
    {
      "AccessoryType": "",
      "aqRef": "MainPanel12552300165523",
      "beaconId": "Ground Floor",
      "callType": "Accessory",
      "carer": "Room Unit",
      "clearType": "CareCall",
      "clearedbyPanel": "Main Panel",
      "duration": "2",
      "end": "12:55:25",
      "journeyRef": "jrny_MainPanel12551700125517",
      "locTx": "001.6",
      "panelRef": "Main Panel",
      "room": "Bedroom 1",
      "start": "2023-01-11 12:55:23.683902Z",
      "startFullDate": "12:55:23",
      "txCode": "001.6",
      "unitId": "001.6",
      "zone": "Ground Floor"
    },
    {
      "AccessoryType": "",
      "aqRef": "MainPanel12552500185525",
      "beaconId": "Ground Floor",
      "callType": "CareCall",
      "carer": "Room Unit",
      "clearType": "Clear",
      "clearedbyPanel": "Main Panel",
      "duration": "2",
      "end": "12:55:27",
      "journeyRef": "jrny_MainPanel12551700125517",
      "locTx": "001.8",
      "panelRef": "Main Panel",
      "room": "Bedroom 1",
      "start": "2023-01-11 12:55:25.687660Z",
      "startFullDate": "12:55:25",
      "txCode": "001.8",
      "unitId": "001.8",
      "zone": "Ground Floor"
    },
    {
      "AccessoryType": "",
      "aqRef": "MainPanel12553200125532",
      "beaconId": "Ground Floor",
      "callType": "Call",
      "carer": "Room Unit",
      "clearType": "Attendance",
      "clearedbyPanel": "Main Panel",
      "duration": "2",
      "end": "12:55:34",
      "journeyRef": "jrny_MainPanel12553200125532",
      "locTx": "001.2",
      "panelRef": "Main Panel",
      "room": "Bedroom 1",
      "start": "2023-01-11 12:55:32.669880Z",
      "startFullDate": "12:55:32",
      "txCode": "001.2",
      "unitId": "001.2",
      "zone": "Ground Floor"
    },
    {
      "AccessoryType": "",
      "aqRef": "MainPanel12553400155534",
      "beaconId": "Ground Floor",
      "callType": "Attendance",
      "carer": "Room Unit",
      "clearType": "Assistance",
      "clearedbyPanel": "Main Panel",
      "duration": "2",
      "end": "12:55:36",
      "journeyRef": "jrny_MainPanel12553200125532",
      "locTx": "001.5",
      "panelRef": "Main Panel",
      "room": "Bedroom 1",
      "start": "2023-01-11 12:55:34.675757Z",
      "startFullDate": "12:55:34",
      "txCode": "001.5",
      "unitId": "001.5",
      "zone": "Ground Floor"
    },
    {
      "AccessoryType": "",
      "aqRef": "MainPanel12553600145536",
      "beaconId": "Ground Floor",
      "callType": "Assistance",
      "carer": "Room Unit",
      "clearType": "Accessory",
      "clearedbyPanel": "Main Panel",
      "duration": "2",
      "end": "12:55:38",
      "journeyRef": "jrny_MainPanel12553200125532",
      "locTx": "001.4",
      "panelRef": "Main Panel",
      "room": "Bedroom 1",
      "start": "2023-01-11 12:55:36.678850Z",
      "startFullDate": "12:55:36",
      "txCode": "001.4",
      "unitId": "001.4",
      "zone": "Ground Floor"
    },
    {
      "AccessoryType": "",
      "aqRef": "MainPanel12553800165538",
      "beaconId": "Ground Floor",
      "callType": "Accessory",
      "carer": "Room Unit",
      "clearType": "CareCall",
      "clearedbyPanel": "Main Panel",
      "duration": "2",
      "end": "12:55:40",
      "journeyRef": "jrny_MainPanel12553200125532",
      "locTx": "001.6",
      "panelRef": "Main Panel",
      "room": "Bedroom 1",
      "start": "2023-01-11 12:55:38.682509Z",
      "startFullDate": "12:55:38",
      "txCode": "001.6",
      "unitId": "001.6",
      "zone": "Ground Floor"
    },
    {
      "AccessoryType": "",
      "aqRef": "MainPanel12554000185540",
      "beaconId": "Ground Floor",
      "callType": "CareCall",
      "carer": "Room Unit",
      "clearType": "Clear",
      "clearedbyPanel": "Main Panel",
      "duration": "2",
      "end": "12:55:42",
      "journeyRef": "jrny_MainPanel12553200125532",
      "locTx": "001.8",
      "panelRef": "Main Panel",
      "room": "Bedroom 1",
      "start": "2023-01-11 12:55:40.685673Z",
      "startFullDate": "12:55:40",
      "txCode": "001.8",
      "unitId": "001.8",
      "zone": "Ground Floor"
    },
    {
      "AccessoryType": "",
      "aqRef": "MainPanel12554700125547",
      "beaconId": "Ground Floor",
      "callType": "Call",
      "carer": "Room Unit",
      "clearType": "Attendance",
      "clearedbyPanel": "Main Panel",
      "duration": "2",
      "end": "12:55:49",
      "journeyRef": "jrny_MainPanel12554700125547",
      "locTx": "001.2",
      "panelRef": "Main Panel",
      "room": "Bedroom 1",
      "start": "2023-01-11 12:55:47.669841Z",
      "startFullDate": "12:55:47",
      "txCode": "001.2",
      "unitId": "001.2",
      "zone": "Ground Floor"
    },
    {
      "AccessoryType": "",
      "aqRef": "MainPanel12554900155549",
      "beaconId": "Ground Floor",
      "callType": "Attendance",
      "carer": "Room Unit",
      "clearType": "Assistance",
      "clearedbyPanel": "Main Panel",
      "duration": "2",
      "end": "12:55:51",
      "journeyRef": "jrny_MainPanel12554700125547",
      "locTx": "001.5",
      "panelRef": "Main Panel",
      "room": "Bedroom 1",
      "start": "2023-01-11 12:55:49.674574Z",
      "startFullDate": "12:55:49",
      "txCode": "001.5",
      "unitId": "001.5",
      "zone": "Ground Floor"
    },
    {
      "AccessoryType": "",
      "aqRef": "MainPanel12555100145551",
      "beaconId": "Ground Floor",
      "callType": "Assistance",
      "carer": "Room Unit",
      "clearType": "Accessory",
      "clearedbyPanel": "Main Panel",
      "duration": "2",
      "end": "12:55:53",
      "journeyRef": "jrny_MainPanel12554700125547",
      "locTx": "001.4",
      "panelRef": "Main Panel",
      "room": "Bedroom 1",
      "start": "2023-01-11 12:55:51.678799Z",
      "startFullDate": "12:55:51",
      "txCode": "001.4",
      "unitId": "001.4",
      "zone": "Ground Floor"
    },
    {
      "AccessoryType": "",
      "aqRef": "MainPanel12555300165553",
      "beaconId": "Ground Floor",
      "callType": "Accessory",
      "carer": "Room Unit",
      "clearType": "CareCall",
      "clearedbyPanel": "Main Panel",
      "duration": "2",
      "end": "12:55:55",
      "journeyRef": "jrny_MainPanel12554700125547",
      "locTx": "001.6",
      "panelRef": "Main Panel",
      "room": "Bedroom 1",
      "start": "2023-01-11 12:55:53.683642Z",
      "startFullDate": "12:55:53",
      "txCode": "001.6",
      "unitId": "001.6",
      "zone": "Ground Floor"
    },
    {
      "AccessoryType": "",
      "aqRef": "MainPanel12555500185555",
      "beaconId": "Ground Floor",
      "callType": "CareCall",
      "carer": "Room Unit",
      "clearType": "Clear",
      "clearedbyPanel": "Main Panel",
      "duration": "2",
      "end": "12:55:57",
      "journeyRef": "jrny_MainPanel12554700125547",
      "locTx": "001.8",
      "panelRef": "Main Panel",
      "room": "Bedroom 1",
      "start": "2023-01-11 12:55:55.687549Z",
      "startFullDate": "12:55:55",
      "txCode": "001.8",
      "unitId": "001.8",
      "zone": "Ground Floor"
    },
    {
      "AccessoryType": "",
      "aqRef": "MainPanel1256020012562",
      "beaconId": "Ground Floor",
      "callType": "Call",
      "carer": "Room Unit",
      "clearType": "Attendance",
      "clearedbyPanel": "Main Panel",
      "duration": "2",
      "end": "12:56:04",
      "journeyRef": "jrny_MainPanel1256020012562",
      "locTx": "001.2",
      "panelRef": "Main Panel",
      "room": "Bedroom 1",
      "start": "2023-01-11 12:56:02.671337Z",
      "startFullDate": "12:56:02",
      "txCode": "001.2",
      "unitId": "001.2",
      "zone": "Ground Floor"
    },
    {
      "AccessoryType": "",
      "aqRef": "MainPanel1256040015564",
      "beaconId": "Ground Floor",
      "callType": "Attendance",
      "carer": "Room Unit",
      "clearType": "Assistance",
      "clearedbyPanel": "Main Panel",
      "duration": "2",
      "end": "12:56:06",
      "journeyRef": "jrny_MainPanel1256020012562",
      "locTx": "001.5",
      "panelRef": "Main Panel",
      "room": "Bedroom 1",
      "start": "2023-01-11 12:56:04.676423Z",
      "startFullDate": "12:56:04",
      "txCode": "001.5",
      "unitId": "001.5",
      "zone": "Ground Floor"
    },
    {
      "AccessoryType": "",
      "aqRef": "MainPanel1256060014566",
      "beaconId": "Ground Floor",
      "callType": "Assistance",
      "carer": "Room Unit",
      "clearType": "Accessory",
      "clearedbyPanel": "Main Panel",
      "duration": "2",
      "end": "12:56:08",
      "journeyRef": "jrny_MainPanel1256020012562",
      "locTx": "001.4",
      "panelRef": "Main Panel",
      "room": "Bedroom 1",
      "start": "2023-01-11 12:56:06.679010Z",
      "startFullDate": "12:56:06",
      "txCode": "001.4",
      "unitId": "001.4",
      "zone": "Ground Floor"
    },
    {
      "AccessoryType": "",
      "aqRef": "MainPanel1256080016568",
      "beaconId": "Ground Floor",
      "callType": "Accessory",
      "carer": "Room Unit",
      "clearType": "CareCall",
      "clearedbyPanel": "Main Panel",
      "duration": "2",
      "end": "12:56:10",
      "journeyRef": "jrny_MainPanel1256020012562",
      "locTx": "001.6",
      "panelRef": "Main Panel",
      "room": "Bedroom 1",
      "start": "2023-01-11 12:56:08.682596Z",
      "startFullDate": "12:56:08",
      "txCode": "001.6",
      "unitId": "001.6",
      "zone": "Ground Floor"
    },
    {
      "AccessoryType": "",
      "aqRef": "MainPanel12561000185610",
      "beaconId": "Ground Floor",
      "callType": "CareCall",
      "carer": "Room Unit",
      "clearType": "Clear",
      "clearedbyPanel": "Main Panel",
      "duration": "2",
      "end": "12:56:12",
      "journeyRef": "jrny_MainPanel1256020012562",
      "locTx": "001.8",
      "panelRef": "Main Panel",
      "room": "Bedroom 1",
      "start": "2023-01-11 12:56:10.687453Z",
      "startFullDate": "12:56:10",
      "txCode": "001.8",
      "unitId": "001.8",
      "zone": "Ground Floor"
    },
    {
      "AccessoryType": "",
      "aqRef": "MainPanel1307320012732",
      "beaconId": "Ground Floor",
      "callType": "Call",
      "carer": "Room Unit",
      "clearType": "Attendance",
      "clearedbyPanel": "Main Panel",
      "duration": "1",
      "end": "13:07:34",
      "journeyRef": "jrny_MainPanel1307320012732",
      "locTx": "001.2",
      "panelRef": "Main Panel",
      "room": "Bedroom 1",
      "start": "2023-01-11 13:07:32.384943Z",
      "startFullDate": "13:07:32",
      "txCode": "001.2",
      "unitId": "001.2",
      "zone": "Ground Floor"
    },
    {
      "AccessoryType": "",
      "aqRef": "MainPanel1307340015734",
      "beaconId": "Ground Floor",
      "callType": "Attendance",
      "carer": "Room Unit",
      "clearType": "Assistance",
      "clearedbyPanel": "Main Panel",
      "duration": "2",
      "end": "13:07:36",
      "journeyRef": "jrny_MainPanel1307320012732",
      "locTx": "001.5",
      "panelRef": "Main Panel",
      "room": "Bedroom 1",
      "start": "2023-01-11 13:07:34.387380Z",
      "startFullDate": "13:07:34",
      "txCode": "001.5",
      "unitId": "001.5",
      "zone": "Ground Floor"
    },
    {
      "AccessoryType": "",
      "aqRef": "MainPanel1307360014736",
      "beaconId": "Ground Floor",
      "callType": "Assistance",
      "carer": "Room Unit",
      "clearType": "Accessory",
      "clearedbyPanel": "Main Panel",
      "duration": "2",
      "end": "13:07:38",
      "journeyRef": "jrny_MainPanel1307320012732",
      "locTx": "001.4",
      "panelRef": "Main Panel",
      "room": "Bedroom 1",
      "start": "2023-01-11 13:07:36.391950Z",
      "startFullDate": "13:07:36",
      "txCode": "001.4",
      "unitId": "001.4",
      "zone": "Ground Floor"
    },
    {
      "AccessoryType": "",
      "aqRef": "MainPanel1307370032737",
      "beaconId": "Second Floor",
      "callType": "Call",
      "carer": "Room Unit",
      "clearType": "Clear",
      "clearedbyPanel": "Main Panel",
      "duration": "2",
      "end": "13:07:39",
      "journeyRef": "jrny_MainPanel1307370032737",
      "locTx": "003.2",
      "panelRef": "Main Panel",
      "room": "Bedroom 3",
      "start": "2023-01-11 13:07:37.389036Z",
      "startFullDate": "13:07:37",
      "txCode": "003.2",
      "unitId": "003.2",
      "zone": "Second Floor"
    },
    {
      "AccessoryType": "",
      "aqRef": "MainPanel1307380016738",
      "beaconId": "Ground Floor",
      "callType": "Accessory",
      "carer": "Room Unit",
      "clearType": "CareCall",
      "clearedbyPanel": "Main Panel",
      "duration": "2",
      "end": "13:07:40",
      "journeyRef": "jrny_MainPanel1307320012732",
      "locTx": "001.6",
      "panelRef": "Main Panel",
      "room": "Bedroom 1",
      "start": "2023-01-11 13:07:38.396108Z",
      "startFullDate": "13:07:38",
      "txCode": "001.6",
      "unitId": "001.6",
      "zone": "Ground Floor"
    },
    {
      "AccessoryType": "",
      "aqRef": "MainPanel1307400018740",
      "beaconId": "Ground Floor",
      "callType": "CareCall",
      "carer": "Room Unit",
      "clearType": "Clear",
      "clearedbyPanel": "Main Panel",
      "duration": "2",
      "end": "13:07:42",
      "journeyRef": "jrny_MainPanel1307320012732",
      "locTx": "001.8",
      "panelRef": "Main Panel",
      "room": "Bedroom 1",
      "start": "2023-01-11 13:07:40.402535Z",
      "startFullDate": "13:07:40",
      "txCode": "001.8",
      "unitId": "001.8",
      "zone": "Ground Floor"
    },
    {
      "AccessoryType": "",
      "aqRef": "MainPanel1307470012747",
      "beaconId": "Ground Floor",
      "callType": "Call",
      "carer": "Room Unit",
      "clearType": "Attendance",
      "clearedbyPanel": "Main Panel",
      "duration": "2",
      "end": "13:07:49",
      "journeyRef": "jrny_MainPanel1307470012747",
      "locTx": "001.2",
      "panelRef": "Main Panel",
      "room": "Bedroom 1",
      "start": "2023-01-11 13:07:47.389159Z",
      "startFullDate": "13:07:47",
      "txCode": "001.2",
      "unitId": "001.2",
      "zone": "Ground Floor"
    },
    {
      "AccessoryType": "",
      "aqRef": "MainPanel1307490015749",
      "beaconId": "Ground Floor",
      "callType": "Attendance",
      "carer": "Room Unit",
      "clearType": "Assistance",
      "clearedbyPanel": "Main Panel",
      "duration": "2",
      "end": "13:07:51",
      "journeyRef": "jrny_MainPanel1307470012747",
      "locTx": "001.5",
      "panelRef": "Main Panel",
      "room": "Bedroom 1",
      "start": "2023-01-11 13:07:49.394234Z",
      "startFullDate": "13:07:49",
      "txCode": "001.5",
      "unitId": "001.5",
      "zone": "Ground Floor"
    },
    {
      "AccessoryType": "",
      "aqRef": "MainPanel1307510014751",
      "beaconId": "Ground Floor",
      "callType": "Assistance",
      "carer": "Room Unit",
      "clearType": "Accessory",
      "clearedbyPanel": "Main Panel",
      "duration": "2",
      "end": "13:07:53",
      "journeyRef": "jrny_MainPanel1307470012747",
      "locTx": "001.4",
      "panelRef": "Main Panel",
      "room": "Bedroom 1",
      "start": "2023-01-11 13:07:51.396456Z",
      "startFullDate": "13:07:51",
      "txCode": "001.4",
      "unitId": "001.4",
      "zone": "Ground Floor"
    },
    {
      "AccessoryType": "",
      "aqRef": "MainPanel1307530016753",
      "beaconId": "Ground Floor",
      "callType": "Accessory",
      "carer": "Room Unit",
      "clearType": "CareCall",
      "clearedbyPanel": "Main Panel",
      "duration": "2",
      "end": "13:07:55",
      "journeyRef": "jrny_MainPanel1307470012747",
      "locTx": "001.6",
      "panelRef": "Main Panel",
      "room": "Bedroom 1",
      "start": "2023-01-11 13:07:53.399385Z",
      "startFullDate": "13:07:53",
      "txCode": "001.6",
      "unitId": "001.6",
      "zone": "Ground Floor"
    },
    {
      "AccessoryType": "",
      "aqRef": "MainPanel1307550018755",
      "beaconId": "Ground Floor",
      "callType": "CareCall",
      "carer": "Room Unit",
      "clearType": "Clear",
      "clearedbyPanel": "Main Panel",
      "duration": "2",
      "end": "13:07:57",
      "journeyRef": "jrny_MainPanel1307470012747",
      "locTx": "001.8",
      "panelRef": "Main Panel",
      "room": "Bedroom 1",
      "start": "2023-01-11 13:07:55.402210Z",
      "startFullDate": "13:07:55",
      "txCode": "001.8",
      "unitId": "001.8",
      "zone": "Ground Floor"
    },
    {
      "AccessoryType": "",
      "aqRef": "MainPanel130802001282",
      "beaconId": "Ground Floor",
      "callType": "Call",
      "carer": "Room Unit",
      "clearType": "Attendance",
      "clearedbyPanel": "Main Panel",
      "duration": "2",
      "end": "13:08:04",
      "journeyRef": "jrny_MainPanel130802001282",
      "locTx": "001.2",
      "panelRef": "Main Panel",
      "room": "Bedroom 1",
      "start": "2023-01-11 13:08:02.389216Z",
      "startFullDate": "13:08:02",
      "txCode": "001.2",
      "unitId": "001.2",
      "zone": "Ground Floor"
    },
    {
      "AccessoryType": "",
      "aqRef": "MainPanel130804001584",
      "beaconId": "Ground Floor",
      "callType": "Attendance",
      "carer": "Room Unit",
      "clearType": "Assistance",
      "clearedbyPanel": "Main Panel",
      "duration": "2",
      "end": "13:08:06",
      "journeyRef": "jrny_MainPanel130802001282",
      "locTx": "001.5",
      "panelRef": "Main Panel",
      "room": "Bedroom 1",
      "start": "2023-01-11 13:08:04.396158Z",
      "startFullDate": "13:08:04",
      "txCode": "001.5",
      "unitId": "001.5",
      "zone": "Ground Floor"
    },
    {
      "AccessoryType": "",
      "aqRef": "MainPanel130806001486",
      "beaconId": "Ground Floor",
      "callType": "Assistance",
      "carer": "Room Unit",
      "clearType": "Accessory",
      "clearedbyPanel": "Main Panel",
      "duration": "2",
      "end": "13:08:08",
      "journeyRef": "jrny_MainPanel130802001282",
      "locTx": "001.4",
      "panelRef": "Main Panel",
      "room": "Bedroom 1",
      "start": "2023-01-11 13:08:06.400667Z",
      "startFullDate": "13:08:06",
      "txCode": "001.4",
      "unitId": "001.4",
      "zone": "Ground Floor"
    },
    {
      "AccessoryType": "",
      "aqRef": "MainPanel130808001688",
      "beaconId": "Ground Floor",
      "callType": "Accessory",
      "carer": "Room Unit",
      "clearType": "CareCall",
      "clearedbyPanel": "Main Panel",
      "duration": "2",
      "end": "13:08:10",
      "journeyRef": "jrny_MainPanel130802001282",
      "locTx": "001.6",
      "panelRef": "Main Panel",
      "room": "Bedroom 1",
      "start": "2023-01-11 13:08:08.403788Z",
      "startFullDate": "13:08:08",
      "txCode": "001.6",
      "unitId": "001.6",
      "zone": "Ground Floor"
    },
    {
      "AccessoryType": "",
      "aqRef": "MainPanel1308100018810",
      "beaconId": "Ground Floor",
      "callType": "CareCall",
      "carer": "Room Unit",
      "clearType": "Clear",
      "clearedbyPanel": "Main Panel",
      "duration": "2",
      "end": "13:08:12",
      "journeyRef": "jrny_MainPanel130802001282",
      "locTx": "001.8",
      "panelRef": "Main Panel",
      "room": "Bedroom 1",
      "start": "2023-01-11 13:08:10.408370Z",
      "startFullDate": "13:08:10",
      "txCode": "001.8",
      "unitId": "001.8",
      "zone": "Ground Floor"
    },
    {
      "AccessoryType": "",
      "aqRef": "MainPanel1308170012817",
      "beaconId": "Ground Floor",
      "callType": "Call",
      "carer": "Room Unit",
      "clearType": "Attendance",
      "clearedbyPanel": "Main Panel",
      "duration": "2",
      "end": "13:08:19",
      "journeyRef": "jrny_MainPanel1308170012817",
      "locTx": "001.2",
      "panelRef": "Main Panel",
      "room": "Bedroom 1",
      "start": "2023-01-11 13:08:17.389307Z",
      "startFullDate": "13:08:17",
      "txCode": "001.2",
      "unitId": "001.2",
      "zone": "Ground Floor"
    },
    {
      "AccessoryType": "",
      "aqRef": "MainPanel1308190015819",
      "beaconId": "Ground Floor",
      "callType": "Attendance",
      "carer": "Room Unit",
      "clearType": "Assistance",
      "clearedbyPanel": "Main Panel",
      "duration": "2",
      "end": "13:08:21",
      "journeyRef": "jrny_MainPanel1308170012817",
      "locTx": "001.5",
      "panelRef": "Main Panel",
      "room": "Bedroom 1",
      "start": "2023-01-11 13:08:19.392444Z",
      "startFullDate": "13:08:19",
      "txCode": "001.5",
      "unitId": "001.5",
      "zone": "Ground Floor"
    },
    {
      "AccessoryType": "",
      "aqRef": "MainPanel1308210014821",
      "beaconId": "Ground Floor",
      "callType": "Assistance",
      "carer": "Room Unit",
      "clearType": "Accessory",
      "clearedbyPanel": "Main Panel",
      "duration": "2",
      "end": "13:08:23",
      "journeyRef": "jrny_MainPanel1308170012817",
      "locTx": "001.4",
      "panelRef": "Main Panel",
      "room": "Bedroom 1",
      "start": "2023-01-11 13:08:21.395489Z",
      "startFullDate": "13:08:21",
      "txCode": "001.4",
      "unitId": "001.4",
      "zone": "Ground Floor"
    },
    {
      "AccessoryType": "",
      "aqRef": "MainPanel1308230016823",
      "beaconId": "Ground Floor",
      "callType": "Accessory",
      "carer": "Room Unit",
      "clearType": "CareCall",
      "clearedbyPanel": "Main Panel",
      "duration": "2",
      "end": "13:08:25",
      "journeyRef": "jrny_MainPanel1308170012817",
      "locTx": "001.6",
      "panelRef": "Main Panel",
      "room": "Bedroom 1",
      "start": "2023-01-11 13:08:23.399789Z",
      "startFullDate": "13:08:23",
      "txCode": "001.6",
      "unitId": "001.6",
      "zone": "Ground Floor"
    },
    {
      "AccessoryType": "",
      "aqRef": "MainPanel1308250018825",
      "beaconId": "Ground Floor",
      "callType": "CareCall",
      "carer": "Room Unit",
      "clearType": "Clear",
      "clearedbyPanel": "Main Panel",
      "duration": "2",
      "end": "13:08:27",
      "journeyRef": "jrny_MainPanel1308170012817",
      "locTx": "001.8",
      "panelRef": "Main Panel",
      "room": "Bedroom 1",
      "start": "2023-01-11 13:08:25.403351Z",
      "startFullDate": "13:08:25",
      "txCode": "001.8",
      "unitId": "001.8",
      "zone": "Ground Floor"
    },
    {
      "AccessoryType": "",
      "aqRef": "MainPanel1308320012832",
      "beaconId": "Ground Floor",
      "callType": "Call",
      "carer": "Room Unit",
      "clearType": "Attendance",
      "clearedbyPanel": "Main Panel",
      "duration": "2",
      "end": "13:08:34",
      "journeyRef": "jrny_MainPanel1308320012832",
      "locTx": "001.2",
      "panelRef": "Main Panel",
      "room": "Bedroom 1",
      "start": "2023-01-11 13:08:32.390335Z",
      "startFullDate": "13:08:32",
      "txCode": "001.2",
      "unitId": "001.2",
      "zone": "Ground Floor"
    },
    {
      "AccessoryType": "",
      "aqRef": "MainPanel1308340015834",
      "beaconId": "Ground Floor",
      "callType": "Attendance",
      "carer": "Room Unit",
      "clearType": "Assistance",
      "clearedbyPanel": "Main Panel",
      "duration": "2",
      "end": "13:08:36",
      "journeyRef": "jrny_MainPanel1308320012832",
      "locTx": "001.5",
      "panelRef": "Main Panel",
      "room": "Bedroom 1",
      "start": "2023-01-11 13:08:34.393618Z",
      "startFullDate": "13:08:34",
      "txCode": "001.5",
      "unitId": "001.5",
      "zone": "Ground Floor"
    },
    {
      "AccessoryType": "",
      "aqRef": "MainPanel1308360014836",
      "beaconId": "Ground Floor",
      "callType": "Assistance",
      "carer": "Room Unit",
      "clearType": "Accessory",
      "clearedbyPanel": "Main Panel",
      "duration": "2",
      "end": "13:08:38",
      "journeyRef": "jrny_MainPanel1308320012832",
      "locTx": "001.4",
      "panelRef": "Main Panel",
      "room": "Bedroom 1",
      "start": "2023-01-11 13:08:36.397538Z",
      "startFullDate": "13:08:36",
      "txCode": "001.4",
      "unitId": "001.4",
      "zone": "Ground Floor"
    },
    {
      "AccessoryType": "",
      "aqRef": "MainPanel1308380016838",
      "beaconId": "Ground Floor",
      "callType": "Accessory",
      "carer": "Room Unit",
      "clearType": "CareCall",
      "clearedbyPanel": "Main Panel",
      "duration": "2",
      "end": "13:08:40",
      "journeyRef": "jrny_MainPanel1308320012832",
      "locTx": "001.6",
      "panelRef": "Main Panel",
      "room": "Bedroom 1",
      "start": "2023-01-11 13:08:38.403205Z",
      "startFullDate": "13:08:38",
      "txCode": "001.6",
      "unitId": "001.6",
      "zone": "Ground Floor"
    },
    {
      "AccessoryType": "",
      "aqRef": "MainPanel1308400018840",
      "beaconId": "Ground Floor",
      "callType": "CareCall",
      "carer": "Room Unit",
      "clearType": "Clear",
      "clearedbyPanel": "Main Panel",
      "duration": "2",
      "end": "13:08:42",
      "journeyRef": "jrny_MainPanel1308320012832",
      "locTx": "001.8",
      "panelRef": "Main Panel",
      "room": "Bedroom 1",
      "start": "2023-01-11 13:08:40.406465Z",
      "startFullDate": "13:08:40",
      "txCode": "001.8",
      "unitId": "001.8",
      "zone": "Ground Floor"
    },
    {
      "AccessoryType": "",
      "aqRef": "MainPanel1308470012847",
      "beaconId": "Ground Floor",
      "callType": "Call",
      "carer": "Room Unit",
      "clearType": "Attendance",
      "clearedbyPanel": "Main Panel",
      "duration": "2",
      "end": "13:08:49",
      "journeyRef": "jrny_MainPanel1308470012847",
      "locTx": "001.2",
      "panelRef": "Main Panel",
      "room": "Bedroom 1",
      "start": "2023-01-11 13:08:47.389967Z",
      "startFullDate": "13:08:47",
      "txCode": "001.2",
      "unitId": "001.2",
      "zone": "Ground Floor"
    },
    {
      "AccessoryType": "",
      "aqRef": "MainPanel1308490015849",
      "beaconId": "Ground Floor",
      "callType": "Attendance",
      "carer": "Room Unit",
      "clearType": "Assistance",
      "clearedbyPanel": "Main Panel",
      "duration": "2",
      "end": "13:08:51",
      "journeyRef": "jrny_MainPanel1308470012847",
      "locTx": "001.5",
      "panelRef": "Main Panel",
      "room": "Bedroom 1",
      "start": "2023-01-11 13:08:49.394023Z",
      "startFullDate": "13:08:49",
      "txCode": "001.5",
      "unitId": "001.5",
      "zone": "Ground Floor"
    },
    {
      "AccessoryType": "",
      "aqRef": "MainPanel1308510014851",
      "beaconId": "Ground Floor",
      "callType": "Assistance",
      "carer": "Room Unit",
      "clearType": "Accessory",
      "clearedbyPanel": "Main Panel",
      "duration": "2",
      "end": "13:08:53",
      "journeyRef": "jrny_MainPanel1308470012847",
      "locTx": "001.4",
      "panelRef": "Main Panel",
      "room": "Bedroom 1",
      "start": "2023-01-11 13:08:51.397994Z",
      "startFullDate": "13:08:51",
      "txCode": "001.4",
      "unitId": "001.4",
      "zone": "Ground Floor"
    },
    {
      "AccessoryType": "",
      "aqRef": "MainPanel1308530016853",
      "beaconId": "Ground Floor",
      "callType": "Accessory",
      "carer": "Room Unit",
      "clearType": "CareCall",
      "clearedbyPanel": "Main Panel",
      "duration": "2",
      "end": "13:08:55",
      "journeyRef": "jrny_MainPanel1308470012847",
      "locTx": "001.6",
      "panelRef": "Main Panel",
      "room": "Bedroom 1",
      "start": "2023-01-11 13:08:53.401170Z",
      "startFullDate": "13:08:53",
      "txCode": "001.6",
      "unitId": "001.6",
      "zone": "Ground Floor"
    },
    {
      "AccessoryType": "",
      "aqRef": "MainPanel1308550018855",
      "beaconId": "Ground Floor",
      "callType": "CareCall",
      "carer": "Room Unit",
      "clearType": "Clear",
      "clearedbyPanel": "Main Panel",
      "duration": "2",
      "end": "13:08:57",
      "journeyRef": "jrny_MainPanel1308470012847",
      "locTx": "001.8",
      "panelRef": "Main Panel",
      "room": "Bedroom 1",
      "start": "2023-01-11 13:08:55.405531Z",
      "startFullDate": "13:08:55",
      "txCode": "001.8",
      "unitId": "001.8",
      "zone": "Ground Floor"
    },
    {
      "AccessoryType": "",
      "aqRef": "MainPanel130902001292",
      "beaconId": "Ground Floor",
      "callType": "Call",
      "carer": "Room Unit",
      "clearType": "Attendance",
      "clearedbyPanel": "Main Panel",
      "duration": "2",
      "end": "13:09:04",
      "journeyRef": "jrny_MainPanel130902001292",
      "locTx": "001.2",
      "panelRef": "Main Panel",
      "room": "Bedroom 1",
      "start": "2023-01-11 13:09:02.390004Z",
      "startFullDate": "13:09:02",
      "txCode": "001.2",
      "unitId": "001.2",
      "zone": "Ground Floor"
    },
    {
      "AccessoryType": "",
      "aqRef": "MainPanel130904001594",
      "beaconId": "Ground Floor",
      "callType": "Attendance",
      "carer": "Room Unit",
      "clearType": "Assistance",
      "clearedbyPanel": "Main Panel",
      "duration": "2",
      "end": "13:09:06",
      "journeyRef": "jrny_MainPanel130902001292",
      "locTx": "001.5",
      "panelRef": "Main Panel",
      "room": "Bedroom 1",
      "start": "2023-01-11 13:09:04.394047Z",
      "startFullDate": "13:09:04",
      "txCode": "001.5",
      "unitId": "001.5",
      "zone": "Ground Floor"
    },
    {
      "AccessoryType": "",
      "aqRef": "MainPanel130906001496",
      "beaconId": "Ground Floor",
      "callType": "Assistance",
      "carer": "Room Unit",
      "clearType": "Accessory",
      "clearedbyPanel": "Main Panel",
      "duration": "2",
      "end": "13:09:08",
      "journeyRef": "jrny_MainPanel130902001292",
      "locTx": "001.4",
      "panelRef": "Main Panel",
      "room": "Bedroom 1",
      "start": "2023-01-11 13:09:06.399011Z",
      "startFullDate": "13:09:06",
      "txCode": "001.4",
      "unitId": "001.4",
      "zone": "Ground Floor"
    },
    {
      "AccessoryType": "",
      "aqRef": "MainPanel130908001698",
      "beaconId": "Ground Floor",
      "callType": "Accessory",
      "carer": "Room Unit",
      "clearType": "CareCall",
      "clearedbyPanel": "Main Panel",
      "duration": "2",
      "end": "13:09:10",
      "journeyRef": "jrny_MainPanel130902001292",
      "locTx": "001.6",
      "panelRef": "Main Panel",
      "room": "Bedroom 1",
      "start": "2023-01-11 13:09:08.404256Z",
      "startFullDate": "13:09:08",
      "txCode": "001.6",
      "unitId": "001.6",
      "zone": "Ground Floor"
    },
    {
      "AccessoryType": "",
      "aqRef": "MainPanel1309100018910",
      "beaconId": "Ground Floor",
      "callType": "CareCall",
      "carer": "Room Unit",
      "clearType": "Clear",
      "clearedbyPanel": "Main Panel",
      "duration": "2",
      "end": "13:09:12",
      "journeyRef": "jrny_MainPanel130902001292",
      "locTx": "001.8",
      "panelRef": "Main Panel",
      "room": "Bedroom 1",
      "start": "2023-01-11 13:09:10.408395Z",
      "startFullDate": "13:09:10",
      "txCode": "001.8",
      "unitId": "001.8",
      "zone": "Ground Floor"
    },
    {
      "AccessoryType": "",
      "aqRef": "MainPanel1309170012917",
      "beaconId": "Ground Floor",
      "callType": "Call",
      "carer": "Room Unit",
      "clearType": "Attendance",
      "clearedbyPanel": "Main Panel",
      "duration": "2",
      "end": "13:09:19",
      "journeyRef": "jrny_MainPanel1309170012917",
      "locTx": "001.2",
      "panelRef": "Main Panel",
      "room": "Bedroom 1",
      "start": "2023-01-11 13:09:17.391640Z",
      "startFullDate": "13:09:17",
      "txCode": "001.2",
      "unitId": "001.2",
      "zone": "Ground Floor"
    },
    {
      "AccessoryType": "",
      "aqRef": "MainPanel1309190015919",
      "beaconId": "Ground Floor",
      "callType": "Attendance",
      "carer": "Room Unit",
      "clearType": "Assistance",
      "clearedbyPanel": "Main Panel",
      "duration": "2",
      "end": "13:09:21",
      "journeyRef": "jrny_MainPanel1309170012917",
      "locTx": "001.5",
      "panelRef": "Main Panel",
      "room": "Bedroom 1",
      "start": "2023-01-11 13:09:19.393711Z",
      "startFullDate": "13:09:19",
      "txCode": "001.5",
      "unitId": "001.5",
      "zone": "Ground Floor"
    },
    {
      "AccessoryType": "",
      "aqRef": "MainPanel1309210014921",
      "beaconId": "Ground Floor",
      "callType": "Assistance",
      "carer": "Room Unit",
      "clearType": "Accessory",
      "clearedbyPanel": "Main Panel",
      "duration": "2",
      "end": "13:09:23",
      "journeyRef": "jrny_MainPanel1309170012917",
      "locTx": "001.4",
      "panelRef": "Main Panel",
      "room": "Bedroom 1",
      "start": "2023-01-11 13:09:21.397392Z",
      "startFullDate": "13:09:21",
      "txCode": "001.4",
      "unitId": "001.4",
      "zone": "Ground Floor"
    },
    {
      "AccessoryType": "",
      "aqRef": "MainPanel1309230016923",
      "beaconId": "Ground Floor",
      "callType": "Accessory",
      "carer": "Room Unit",
      "clearType": "CareCall",
      "clearedbyPanel": "Main Panel",
      "duration": "2",
      "end": "13:09:25",
      "journeyRef": "jrny_MainPanel1309170012917",
      "locTx": "001.6",
      "panelRef": "Main Panel",
      "room": "Bedroom 1",
      "start": "2023-01-11 13:09:23.400390Z",
      "startFullDate": "13:09:23",
      "txCode": "001.6",
      "unitId": "001.6",
      "zone": "Ground Floor"
    },
    {
      "AccessoryType": "",
      "aqRef": "MainPanel1309250018925",
      "beaconId": "Ground Floor",
      "callType": "CareCall",
      "carer": "Room Unit",
      "clearType": "Clear",
      "clearedbyPanel": "Main Panel",
      "duration": "2",
      "end": "13:09:27",
      "journeyRef": "jrny_MainPanel1309170012917",
      "locTx": "001.8",
      "panelRef": "Main Panel",
      "room": "Bedroom 1",
      "start": "2023-01-11 13:09:25.406476Z",
      "startFullDate": "13:09:25",
      "txCode": "001.8",
      "unitId": "001.8",
      "zone": "Ground Floor"
    },
    {
      "AccessoryType": "",
      "aqRef": "MainPanel1309320012932",
      "beaconId": "Ground Floor",
      "callType": "Call",
      "carer": "Room Unit",
      "clearType": "Attendance",
      "clearedbyPanel": "Main Panel",
      "duration": "2",
      "end": "13:09:34",
      "journeyRef": "jrny_MainPanel1309320012932",
      "locTx": "001.2",
      "panelRef": "Main Panel",
      "room": "Bedroom 1",
      "start": "2023-01-11 13:09:32.388960Z",
      "startFullDate": "13:09:32",
      "txCode": "001.2",
      "unitId": "001.2",
      "zone": "Ground Floor"
    },
    {
      "AccessoryType": "",
      "aqRef": "MainPanel1309340015934",
      "beaconId": "Ground Floor",
      "callType": "Attendance",
      "carer": "Room Unit",
      "clearType": "Assistance",
      "clearedbyPanel": "Main Panel",
      "duration": "2",
      "end": "13:09:36",
      "journeyRef": "jrny_MainPanel1309320012932",
      "locTx": "001.5",
      "panelRef": "Main Panel",
      "room": "Bedroom 1",
      "start": "2023-01-11 13:09:34.392171Z",
      "startFullDate": "13:09:34",
      "txCode": "001.5",
      "unitId": "001.5",
      "zone": "Ground Floor"
    },
    {
      "AccessoryType": "",
      "aqRef": "MainPanel1309360014936",
      "beaconId": "Ground Floor",
      "callType": "Assistance",
      "carer": "Room Unit",
      "clearType": "Accessory",
      "clearedbyPanel": "Main Panel",
      "duration": "2",
      "end": "13:09:38",
      "journeyRef": "jrny_MainPanel1309320012932",
      "locTx": "001.4",
      "panelRef": "Main Panel",
      "room": "Bedroom 1",
      "start": "2023-01-11 13:09:36.395577Z",
      "startFullDate": "13:09:36",
      "txCode": "001.4",
      "unitId": "001.4",
      "zone": "Ground Floor"
    },
    {
      "AccessoryType": "",
      "aqRef": "MainPanel1309380016938",
      "beaconId": "Ground Floor",
      "callType": "Accessory",
      "carer": "Room Unit",
      "clearType": "CareCall",
      "clearedbyPanel": "Main Panel",
      "duration": "2",
      "end": "13:09:40",
      "journeyRef": "jrny_MainPanel1309320012932",
      "locTx": "001.6",
      "panelRef": "Main Panel",
      "room": "Bedroom 1",
      "start": "2023-01-11 13:09:38.398797Z",
      "startFullDate": "13:09:38",
      "txCode": "001.6",
      "unitId": "001.6",
      "zone": "Ground Floor"
    },
    {
      "AccessoryType": "",
      "aqRef": "MainPanel1309400018940",
      "beaconId": "Ground Floor",
      "callType": "CareCall",
      "carer": "Room Unit",
      "clearType": "Clear",
      "clearedbyPanel": "Main Panel",
      "duration": "2",
      "end": "13:09:42",
      "journeyRef": "jrny_MainPanel1309320012932",
      "locTx": "001.8",
      "panelRef": "Main Panel",
      "room": "Bedroom 1",
      "start": "2023-01-11 13:09:40.402237Z",
      "startFullDate": "13:09:40",
      "txCode": "001.8",
      "unitId": "001.8",
      "zone": "Ground Floor"
    },
    {
      "AccessoryType": "",
      "aqRef": "MainPanel1309470012947",
      "beaconId": "Ground Floor",
      "callType": "Call",
      "carer": "Room Unit",
      "clearType": "Attendance",
      "clearedbyPanel": "Main Panel",
      "duration": "2",
      "end": "13:09:49",
      "journeyRef": "jrny_MainPanel1309470012947",
      "locTx": "001.2",
      "panelRef": "Main Panel",
      "room": "Bedroom 1",
      "start": "2023-01-11 13:09:47.388823Z",
      "startFullDate": "13:09:47",
      "txCode": "001.2",
      "unitId": "001.2",
      "zone": "Ground Floor"
    },
    {
      "AccessoryType": "",
      "aqRef": "MainPanel1309490015949",
      "beaconId": "Ground Floor",
      "callType": "Attendance",
      "carer": "Room Unit",
      "clearType": "Assistance",
      "clearedbyPanel": "Main Panel",
      "duration": "2",
      "end": "13:09:51",
      "journeyRef": "jrny_MainPanel1309470012947",
      "locTx": "001.5",
      "panelRef": "Main Panel",
      "room": "Bedroom 1",
      "start": "2023-01-11 13:09:49.392970Z",
      "startFullDate": "13:09:49",
      "txCode": "001.5",
      "unitId": "001.5",
      "zone": "Ground Floor"
    },
    {
      "AccessoryType": "",
      "aqRef": "MainPanel1309510014951",
      "beaconId": "Ground Floor",
      "callType": "Assistance",
      "carer": "Room Unit",
      "clearType": "Accessory",
      "clearedbyPanel": "Main Panel",
      "duration": "2",
      "end": "13:09:53",
      "journeyRef": "jrny_MainPanel1309470012947",
      "locTx": "001.4",
      "panelRef": "Main Panel",
      "room": "Bedroom 1",
      "start": "2023-01-11 13:09:51.397230Z",
      "startFullDate": "13:09:51",
      "txCode": "001.4",
      "unitId": "001.4",
      "zone": "Ground Floor"
    },
    {
      "AccessoryType": "",
      "aqRef": "MainPanel1309530016953",
      "beaconId": "Ground Floor",
      "callType": "Accessory",
      "carer": "Room Unit",
      "clearType": "CareCall",
      "clearedbyPanel": "Main Panel",
      "duration": "2",
      "end": "13:09:55",
      "journeyRef": "jrny_MainPanel1309470012947",
      "locTx": "001.6",
      "panelRef": "Main Panel",
      "room": "Bedroom 1",
      "start": "2023-01-11 13:09:53.400643Z",
      "startFullDate": "13:09:53",
      "txCode": "001.6",
      "unitId": "001.6",
      "zone": "Ground Floor"
    },
    {
      "AccessoryType": "",
      "aqRef": "MainPanel1309550018955",
      "beaconId": "Ground Floor",
      "callType": "CareCall",
      "carer": "Room Unit",
      "clearType": "Clear",
      "clearedbyPanel": "Main Panel",
      "duration": "2",
      "end": "13:09:57",
      "journeyRef": "jrny_MainPanel1309470012947",
      "locTx": "001.8",
      "panelRef": "Main Panel",
      "room": "Bedroom 1",
      "start": "2023-01-11 13:09:55.403980Z",
      "startFullDate": "13:09:55",
      "txCode": "001.8",
      "unitId": "001.8",
      "zone": "Ground Floor"
    },
    {
      "AccessoryType": "",
      "aqRef": "MainPanel1310020012102",
      "beaconId": "Ground Floor",
      "callType": "Call",
      "carer": "Room Unit",
      "clearType": "Attendance",
      "clearedbyPanel": "Main Panel",
      "duration": "2",
      "end": "13:10:04",
      "journeyRef": "jrny_MainPanel1310020012102",
      "locTx": "001.2",
      "panelRef": "Main Panel",
      "room": "Bedroom 1",
      "start": "2023-01-11 13:10:02.390267Z",
      "startFullDate": "13:10:02",
      "txCode": "001.2",
      "unitId": "001.2",
      "zone": "Ground Floor"
    },
    {
      "AccessoryType": "",
      "aqRef": "MainPanel1310040015104",
      "beaconId": "Ground Floor",
      "callType": "Attendance",
      "carer": "Room Unit",
      "clearType": "Assistance",
      "clearedbyPanel": "Main Panel",
      "duration": "2",
      "end": "13:10:06",
      "journeyRef": "jrny_MainPanel1310020012102",
      "locTx": "001.5",
      "panelRef": "Main Panel",
      "room": "Bedroom 1",
      "start": "2023-01-11 13:10:04.394319Z",
      "startFullDate": "13:10:04",
      "txCode": "001.5",
      "unitId": "001.5",
      "zone": "Ground Floor"
    },
    {
      "AccessoryType": "",
      "aqRef": "MainPanel1310060014106",
      "beaconId": "Ground Floor",
      "callType": "Assistance",
      "carer": "Room Unit",
      "clearType": "Accessory",
      "clearedbyPanel": "Main Panel",
      "duration": "2",
      "end": "13:10:08",
      "journeyRef": "jrny_MainPanel1310020012102",
      "locTx": "001.4",
      "panelRef": "Main Panel",
      "room": "Bedroom 1",
      "start": "2023-01-11 13:10:06.399957Z",
      "startFullDate": "13:10:06",
      "txCode": "001.4",
      "unitId": "001.4",
      "zone": "Ground Floor"
    },
    {
      "AccessoryType": "",
      "aqRef": "MainPanel1310080016108",
      "beaconId": "Ground Floor",
      "callType": "Accessory",
      "carer": "Room Unit",
      "clearType": "CareCall",
      "clearedbyPanel": "Main Panel",
      "duration": "2",
      "end": "13:10:10",
      "journeyRef": "jrny_MainPanel1310020012102",
      "locTx": "001.6",
      "panelRef": "Main Panel",
      "room": "Bedroom 1",
      "start": "2023-01-11 13:10:08.404223Z",
      "startFullDate": "13:10:08",
      "txCode": "001.6",
      "unitId": "001.6",
      "zone": "Ground Floor"
    },
    {
      "AccessoryType": "",
      "aqRef": "MainPanel13101000181010",
      "beaconId": "Ground Floor",
      "callType": "CareCall",
      "carer": "Room Unit",
      "clearType": "Clear",
      "clearedbyPanel": "Main Panel",
      "duration": "2",
      "end": "13:10:12",
      "journeyRef": "jrny_MainPanel1310020012102",
      "locTx": "001.8",
      "panelRef": "Main Panel",
      "room": "Bedroom 1",
      "start": "2023-01-11 13:10:10.407960Z",
      "startFullDate": "13:10:10",
      "txCode": "001.8",
      "unitId": "001.8",
      "zone": "Ground Floor"
    },
    {
      "AccessoryType": "",
      "aqRef": "MainPanel13101700121017",
      "beaconId": "Ground Floor",
      "callType": "Call",
      "carer": "Room Unit",
      "clearType": "Attendance",
      "clearedbyPanel": "Main Panel",
      "duration": "2",
      "end": "13:10:19",
      "journeyRef": "jrny_MainPanel13101700121017",
      "locTx": "001.2",
      "panelRef": "Main Panel",
      "room": "Bedroom 1",
      "start": "2023-01-11 13:10:17.390164Z",
      "startFullDate": "13:10:17",
      "txCode": "001.2",
      "unitId": "001.2",
      "zone": "Ground Floor"
    },
    {
      "AccessoryType": "",
      "aqRef": "MainPanel13101900151019",
      "beaconId": "Ground Floor",
      "callType": "Attendance",
      "carer": "Room Unit",
      "clearType": "Assistance",
      "clearedbyPanel": "Main Panel",
      "duration": "2",
      "end": "13:10:21",
      "journeyRef": "jrny_MainPanel13101700121017",
      "locTx": "001.5",
      "panelRef": "Main Panel",
      "room": "Bedroom 1",
      "start": "2023-01-11 13:10:19.395194Z",
      "startFullDate": "13:10:19",
      "txCode": "001.5",
      "unitId": "001.5",
      "zone": "Ground Floor"
    },
    {
      "AccessoryType": "",
      "aqRef": "MainPanel13102100141021",
      "beaconId": "Ground Floor",
      "callType": "Assistance",
      "carer": "Room Unit",
      "clearType": "Accessory",
      "clearedbyPanel": "Main Panel",
      "duration": "2",
      "end": "13:10:23",
      "journeyRef": "jrny_MainPanel13101700121017",
      "locTx": "001.4",
      "panelRef": "Main Panel",
      "room": "Bedroom 1",
      "start": "2023-01-11 13:10:21.400932Z",
      "startFullDate": "13:10:21",
      "txCode": "001.4",
      "unitId": "001.4",
      "zone": "Ground Floor"
    },
    {
      "AccessoryType": "",
      "aqRef": "MainPanel13102300161023",
      "beaconId": "Ground Floor",
      "callType": "Accessory",
      "carer": "Room Unit",
      "clearType": "CareCall",
      "clearedbyPanel": "Main Panel",
      "duration": "2",
      "end": "13:10:25",
      "journeyRef": "jrny_MainPanel13101700121017",
      "locTx": "001.6",
      "panelRef": "Main Panel",
      "room": "Bedroom 1",
      "start": "2023-01-11 13:10:23.404191Z",
      "startFullDate": "13:10:23",
      "txCode": "001.6",
      "unitId": "001.6",
      "zone": "Ground Floor"
    },
    {
      "AccessoryType": "",
      "aqRef": "MainPanel13102500181025",
      "beaconId": "Ground Floor",
      "callType": "CareCall",
      "carer": "Room Unit",
      "clearType": "Clear",
      "clearedbyPanel": "Main Panel",
      "duration": "2",
      "end": "13:10:27",
      "journeyRef": "jrny_MainPanel13101700121017",
      "locTx": "001.8",
      "panelRef": "Main Panel",
      "room": "Bedroom 1",
      "start": "2023-01-11 13:10:25.406829Z",
      "startFullDate": "13:10:25",
      "txCode": "001.8",
      "unitId": "001.8",
      "zone": "Ground Floor"
    },
    {
      "AccessoryType": "",
      "aqRef": "MainPanel13103200121032",
      "beaconId": "Ground Floor",
      "callType": "Call",
      "carer": "Room Unit",
      "clearType": "Attendance",
      "clearedbyPanel": "Main Panel",
      "duration": "2",
      "end": "13:10:34",
      "journeyRef": "jrny_MainPanel13103200121032",
      "locTx": "001.2",
      "panelRef": "Main Panel",
      "room": "Bedroom 1",
      "start": "2023-01-11 13:10:32.391417Z",
      "startFullDate": "13:10:32",
      "txCode": "001.2",
      "unitId": "001.2",
      "zone": "Ground Floor"
    },
    {
      "AccessoryType": "",
      "aqRef": "MainPanel13103400151034",
      "beaconId": "Ground Floor",
      "callType": "Attendance",
      "carer": "Room Unit",
      "clearType": "Assistance",
      "clearedbyPanel": "Main Panel",
      "duration": "2",
      "end": "13:10:36",
      "journeyRef": "jrny_MainPanel13103200121032",
      "locTx": "001.5",
      "panelRef": "Main Panel",
      "room": "Bedroom 1",
      "start": "2023-01-11 13:10:34.395084Z",
      "startFullDate": "13:10:34",
      "txCode": "001.5",
      "unitId": "001.5",
      "zone": "Ground Floor"
    },
    {
      "AccessoryType": "",
      "aqRef": "MainPanel13103600141036",
      "beaconId": "Ground Floor",
      "callType": "Assistance",
      "carer": "Room Unit",
      "clearType": "Accessory",
      "clearedbyPanel": "Main Panel",
      "duration": "2",
      "end": "13:10:38",
      "journeyRef": "jrny_MainPanel13103200121032",
      "locTx": "001.4",
      "panelRef": "Main Panel",
      "room": "Bedroom 1",
      "start": "2023-01-11 13:10:36.398900Z",
      "startFullDate": "13:10:36",
      "txCode": "001.4",
      "unitId": "001.4",
      "zone": "Ground Floor"
    },
    {
      "AccessoryType": "",
      "aqRef": "MainPanel13103800161038",
      "beaconId": "Ground Floor",
      "callType": "Accessory",
      "carer": "Room Unit",
      "clearType": "CareCall",
      "clearedbyPanel": "Main Panel",
      "duration": "2",
      "end": "13:10:40",
      "journeyRef": "jrny_MainPanel13103200121032",
      "locTx": "001.6",
      "panelRef": "Main Panel",
      "room": "Bedroom 1",
      "start": "2023-01-11 13:10:38.402171Z",
      "startFullDate": "13:10:38",
      "txCode": "001.6",
      "unitId": "001.6",
      "zone": "Ground Floor"
    },
    {
      "AccessoryType": "",
      "aqRef": "MainPanel13104000181040",
      "beaconId": "Ground Floor",
      "callType": "CareCall",
      "carer": "Room Unit",
      "clearType": "Clear",
      "clearedbyPanel": "Main Panel",
      "duration": "2",
      "end": "13:10:42",
      "journeyRef": "jrny_MainPanel13103200121032",
      "locTx": "001.8",
      "panelRef": "Main Panel",
      "room": "Bedroom 1",
      "start": "2023-01-11 13:10:40.406154Z",
      "startFullDate": "13:10:40",
      "txCode": "001.8",
      "unitId": "001.8",
      "zone": "Ground Floor"
    },
    {
      "AccessoryType": "",
      "aqRef": "MainPanel13104700121047",
      "beaconId": "Ground Floor",
      "callType": "Call",
      "carer": "Room Unit",
      "clearType": "Attendance",
      "clearedbyPanel": "Main Panel",
      "duration": "2",
      "end": "13:10:49",
      "journeyRef": "jrny_MainPanel13104700121047",
      "locTx": "001.2",
      "panelRef": "Main Panel",
      "room": "Bedroom 1",
      "start": "2023-01-11 13:10:47.389974Z",
      "startFullDate": "13:10:47",
      "txCode": "001.2",
      "unitId": "001.2",
      "zone": "Ground Floor"
    },
    {
      "AccessoryType": "",
      "aqRef": "MainPanel13104900151049",
      "beaconId": "Ground Floor",
      "callType": "Attendance",
      "carer": "Room Unit",
      "clearType": "Assistance",
      "clearedbyPanel": "Main Panel",
      "duration": "2",
      "end": "13:10:51",
      "journeyRef": "jrny_MainPanel13104700121047",
      "locTx": "001.5",
      "panelRef": "Main Panel",
      "room": "Bedroom 1",
      "start": "2023-01-11 13:10:49.393074Z",
      "startFullDate": "13:10:49",
      "txCode": "001.5",
      "unitId": "001.5",
      "zone": "Ground Floor"
    },
    {
      "AccessoryType": "",
      "aqRef": "MainPanel13105100141051",
      "beaconId": "Ground Floor",
      "callType": "Assistance",
      "carer": "Room Unit",
      "clearType": "Accessory",
      "clearedbyPanel": "Main Panel",
      "duration": "2",
      "end": "13:10:53",
      "journeyRef": "jrny_MainPanel13104700121047",
      "locTx": "001.4",
      "panelRef": "Main Panel",
      "room": "Bedroom 1",
      "start": "2023-01-11 13:10:51.397948Z",
      "startFullDate": "13:10:51",
      "txCode": "001.4",
      "unitId": "001.4",
      "zone": "Ground Floor"
    },
    {
      "AccessoryType": "",
      "aqRef": "MainPanel13105300161053",
      "beaconId": "Ground Floor",
      "callType": "Accessory",
      "carer": "Room Unit",
      "clearType": "CareCall",
      "clearedbyPanel": "Main Panel",
      "duration": "2",
      "end": "13:10:55",
      "journeyRef": "jrny_MainPanel13104700121047",
      "locTx": "001.6",
      "panelRef": "Main Panel",
      "room": "Bedroom 1",
      "start": "2023-01-11 13:10:53.401599Z",
      "startFullDate": "13:10:53",
      "txCode": "001.6",
      "unitId": "001.6",
      "zone": "Ground Floor"
    },
    {
      "AccessoryType": "",
      "aqRef": "MainPanel13105500181055",
      "beaconId": "Ground Floor",
      "callType": "CareCall",
      "carer": "Room Unit",
      "clearType": "Clear",
      "clearedbyPanel": "Main Panel",
      "duration": "2",
      "end": "13:10:57",
      "journeyRef": "jrny_MainPanel13104700121047",
      "locTx": "001.8",
      "panelRef": "Main Panel",
      "room": "Bedroom 1",
      "start": "2023-01-11 13:10:55.404136Z",
      "startFullDate": "13:10:55",
      "txCode": "001.8",
      "unitId": "001.8",
      "zone": "Ground Floor"
    },
    {
      "AccessoryType": "",
      "aqRef": "MainPanel1311020012112",
      "beaconId": "Ground Floor",
      "callType": "Call",
      "carer": "Room Unit",
      "clearType": "Attendance",
      "clearedbyPanel": "Main Panel",
      "duration": "2",
      "end": "13:11:04",
      "journeyRef": "jrny_MainPanel1311020012112",
      "locTx": "001.2",
      "panelRef": "Main Panel",
      "room": "Bedroom 1",
      "start": "2023-01-11 13:11:02.392133Z",
      "startFullDate": "13:11:02",
      "txCode": "001.2",
      "unitId": "001.2",
      "zone": "Ground Floor"
    },
    {
      "AccessoryType": "",
      "aqRef": "MainPanel1311040015114",
      "beaconId": "Ground Floor",
      "callType": "Attendance",
      "carer": "Room Unit",
      "clearType": "Assistance",
      "clearedbyPanel": "Main Panel",
      "duration": "2",
      "end": "13:11:06",
      "journeyRef": "jrny_MainPanel1311020012112",
      "locTx": "001.5",
      "panelRef": "Main Panel",
      "room": "Bedroom 1",
      "start": "2023-01-11 13:11:04.395909Z",
      "startFullDate": "13:11:04",
      "txCode": "001.5",
      "unitId": "001.5",
      "zone": "Ground Floor"
    },
    {
      "AccessoryType": "",
      "aqRef": "MainPanel1311060014116",
      "beaconId": "Ground Floor",
      "callType": "Assistance",
      "carer": "Room Unit",
      "clearType": "Accessory",
      "clearedbyPanel": "Main Panel",
      "duration": "2",
      "end": "13:11:08",
      "journeyRef": "jrny_MainPanel1311020012112",
      "locTx": "001.4",
      "panelRef": "Main Panel",
      "room": "Bedroom 1",
      "start": "2023-01-11 13:11:06.401747Z",
      "startFullDate": "13:11:06",
      "txCode": "001.4",
      "unitId": "001.4",
      "zone": "Ground Floor"
    },
    {
      "AccessoryType": "",
      "aqRef": "MainPanel1311080016118",
      "beaconId": "Ground Floor",
      "callType": "Accessory",
      "carer": "Room Unit",
      "clearType": "CareCall",
      "clearedbyPanel": "Main Panel",
      "duration": "2",
      "end": "13:11:10",
      "journeyRef": "jrny_MainPanel1311020012112",
      "locTx": "001.6",
      "panelRef": "Main Panel",
      "room": "Bedroom 1",
      "start": "2023-01-11 13:11:08.405670Z",
      "startFullDate": "13:11:08",
      "txCode": "001.6",
      "unitId": "001.6",
      "zone": "Ground Floor"
    },
    {
      "AccessoryType": "",
      "aqRef": "MainPanel13111000181110",
      "beaconId": "Ground Floor",
      "callType": "CareCall",
      "carer": "Room Unit",
      "clearType": "Clear",
      "clearedbyPanel": "Main Panel",
      "duration": "2",
      "end": "13:11:12",
      "journeyRef": "jrny_MainPanel1311020012112",
      "locTx": "001.8",
      "panelRef": "Main Panel",
      "room": "Bedroom 1",
      "start": "2023-01-11 13:11:10.410011Z",
      "startFullDate": "13:11:10",
      "txCode": "001.8",
      "unitId": "001.8",
      "zone": "Ground Floor"
    },
    {
      "AccessoryType": "",
      "aqRef": "MainPanel13111700121117",
      "beaconId": "Ground Floor",
      "callType": "Call",
      "carer": "Room Unit",
      "clearType": "Attendance",
      "clearedbyPanel": "Main Panel",
      "duration": "2",
      "end": "13:11:19",
      "journeyRef": "jrny_MainPanel13111700121117",
      "locTx": "001.2",
      "panelRef": "Main Panel",
      "room": "Bedroom 1",
      "start": "2023-01-11 13:11:17.391103Z",
      "startFullDate": "13:11:17",
      "txCode": "001.2",
      "unitId": "001.2",
      "zone": "Ground Floor"
    },
    {
      "AccessoryType": "",
      "aqRef": "MainPanel13111900151119",
      "beaconId": "Ground Floor",
      "callType": "Attendance",
      "carer": "Room Unit",
      "clearType": "Assistance",
      "clearedbyPanel": "Main Panel",
      "duration": "2",
      "end": "13:11:21",
      "journeyRef": "jrny_MainPanel13111700121117",
      "locTx": "001.5",
      "panelRef": "Main Panel",
      "room": "Bedroom 1",
      "start": "2023-01-11 13:11:19.394022Z",
      "startFullDate": "13:11:19",
      "txCode": "001.5",
      "unitId": "001.5",
      "zone": "Ground Floor"
    },
    {
      "AccessoryType": "",
      "aqRef": "MainPanel13112100141121",
      "beaconId": "Ground Floor",
      "callType": "Assistance",
      "carer": "Room Unit",
      "clearType": "Accessory",
      "clearedbyPanel": "Main Panel",
      "duration": "2",
      "end": "13:11:23",
      "journeyRef": "jrny_MainPanel13111700121117",
      "locTx": "001.4",
      "panelRef": "Main Panel",
      "room": "Bedroom 1",
      "start": "2023-01-11 13:11:21.398009Z",
      "startFullDate": "13:11:21",
      "txCode": "001.4",
      "unitId": "001.4",
      "zone": "Ground Floor"
    },
    {
      "AccessoryType": "",
      "aqRef": "MainPanel13112300161123",
      "beaconId": "Ground Floor",
      "callType": "Accessory",
      "carer": "Room Unit",
      "clearType": "CareCall",
      "clearedbyPanel": "Main Panel",
      "duration": "2",
      "end": "13:11:25",
      "journeyRef": "jrny_MainPanel13111700121117",
      "locTx": "001.6",
      "panelRef": "Main Panel",
      "room": "Bedroom 1",
      "start": "2023-01-11 13:11:23.402371Z",
      "startFullDate": "13:11:23",
      "txCode": "001.6",
      "unitId": "001.6",
      "zone": "Ground Floor"
    },
    {
      "AccessoryType": "",
      "aqRef": "MainPanel13112500181125",
      "beaconId": "Ground Floor",
      "callType": "CareCall",
      "carer": "Room Unit",
      "clearType": "Clear",
      "clearedbyPanel": "Main Panel",
      "duration": "2",
      "end": "13:11:27",
      "journeyRef": "jrny_MainPanel13111700121117",
      "locTx": "001.8",
      "panelRef": "Main Panel",
      "room": "Bedroom 1",
      "start": "2023-01-11 13:11:25.405077Z",
      "startFullDate": "13:11:25",
      "txCode": "001.8",
      "unitId": "001.8",
      "zone": "Ground Floor"
    },
    {
      "AccessoryType": "",
      "aqRef": "MainPanel14374100123741",
      "beaconId": "Ground Floor",
      "callType": "Call",
      "carer": "Room Unit",
      "clearType": "Attendance",
      "clearedbyPanel": "Main Panel",
      "duration": "350",
      "end": "12:40:23",
      "journeyRef": "jrny_MainPanel14374100123741",
      "locTx": "001.2",
      "panelRef": "001",
      "room": "Bedroom 1",
      "start": "2023-01-10 14:37:41.712124Z",
      "startFullDate": "14:37:41",
      "txCode": "001.2",
      "unitId": "001.2",
      "zone": "Ground Floor"
    },
    {
      "callType": "Visit",
      "carer": "Room Unit",
      "duration": "10",
      "end": "12:40:21",
      "panelRef": "Main Panel",
      "room": "Bedroom 3",
      "start": "12:40:21",
      "unitId": "003.3",
      "zone": "Second Floor"
    },
    {
      "callType": "Visit",
      "carer": "Room Unit",
      "duration": "10",
      "end": "12:52:18",
      "panelRef": "Main Panel",
      "room": "Bedroom 3",
      "start": "12:52:18",
      "unitId": "003.3",
      "zone": "Second Floor"
    },
    {
      "callType": "Visit",
      "carer": "Room Unit",
      "duration": "10",
      "end": "13:07:33",
      "panelRef": "Main Panel",
      "room": "Bedroom 3",
      "start": "13:07:33",
      "unitId": "003.3",
      "zone": "Second Floor"
    }
];  