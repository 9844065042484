
const Move = () =>{

   
    //const site_guid = "018290c0-4a7b-9f03-18bb-64cba6e030a5";     //arquella office
    const site_guid = "018586db-51a1-61b1-9b05-2ba58ffac7a3";   //weir
    
    return (
        <iframe
                id='iiwariframe' 
                src={"https://app.iiwari.cloud/arquella?site=" + site_guid + "&theme=iframe"}
                style={{
                    marginLeft: "2.5%",
                    marginTop: "2.5%",
                    alignSelf: "center",
                    height: "80vh",
                    //margin: "3vh",
                    width: "95%",
//                    minHeight: "",
                    marginRight:"10px", 
                    marginTop:"10px",
                    //border:"none",
                }}
                >
            </iframe>
    )

}

export default Move;