import React, { useRef, useEffect, useState } from 'react';
import { API_POST } from '../../../../components/API/API_POST';
import * as d3 from "d3";

/*
ChartJS.register(
    CategoryScale,
    LinearScale,
    PointElement,
    LineElement,
    Title,
    Tooltip,
    Legend
  );
*/
const Widget=({data})=>{
    
    const [value, setValue] = useState(null);

    function gotData(data){
      console.log("gotData", data);
      console.log(data[0].Value);
      if (data.length>0) { setValue(data[0].Value.toString());}
    }

    function gotError(err){
      console.log({err});

    }

    useEffect(()=>{
      const postData = {
        firstName: 'andrewchapman@arquella.co.uk',
        lastName: 'Flintstone',
        repType: data.data,
        dataID: "",
        startDate: "",
        endDate: ""
      };
      
      API_POST('/trends/data', postData, gotData, gotError);

    },[])

    return (
        <div className="dashboard_widget">
            <div>
                <div className="title">{data.title}</div>
                <div className="value">{(data.value===null) ? <div>loading</div> : value}</div>
                <div className="subtitle">{data.subTitle}</div>
                <div className="caption">{data.caption}</div>
            </div>
            <div>{/*<LineChart />*/}</div>
        </div>
    );
}

export const data = [2,6,5,3,7,5,1];

export const options = {
    responsive: true,
    plugins: {
      legend: {
        //position: 'top' as const,
      },
      title: {
        display: true,
        text: 'Chart.js Line Chart',
    },
  },
};
  

const LineChart=()=> {
  const gRef = useRef(null);

  useEffect(()=>{

    const width = "500";
    const height = "300";
    const margins = {top: 20, right: 20, bottom: 20, left: 20};

    const svgDiv = d3.select(gRef.current);
    const datasets = data;
    console.log({svgDiv});  
    const h = height-40;
    
    function appendToParent(parent, type, className, transformation){return parent.append(type).attr("class", className).attr("transform", transformation);}

    const svg = appendToParent(svgDiv, 'svg', "svgchart", ``);
    
  //      svg.attr("width", width);
    //    svg.attr("height", height);

/*        
        const g = appendToParent(svg, 'g', "gwrapper", `translate(${margins.left}, ${margins.top})`);
    
        var yScale = createScaleLinear([0, 50], [(height-(margins.bottom + margins.top)), (margins.top)]);
        var xScale = createScaleLinear([24, 0], [(width-(margins.left + margins.right)), (margins.left)]);
        let yAxisGenerator = getLeftAxis(yScale);
        let yAxis = appendToParent(g, 'g', "yAxis", null).call(yAxisGenerator);
        let xAxisGenerator = getBottomAxis(xScale);
        let xAxis = appendToParent(g, 'g', "xAxis", `translate(-20, ${h})`).call(xAxisGenerator);
        //let xAxis = appendToParent(g, 'g', "xAxis", "translate(0," + (dimensions.height-(margins.bottom + margins.top)) + ")").call(xAxisGenerator);
    
    
        // Add dots
        svg.append('g')
        .selectAll("dot")
        .data(data)
        .enter()
        .append("circle")
        .attr("cx", function (d) { 
            return 20;
            //return x(d.GrLivArea); 
        } )
        .attr("cy", function (d) { 
            return 20;
            //return y(d.SalePrice); 
        } )
        .attr("r", 1.5)
        .style("fill", "#69b3a2");
*/


  },[]);

  return <div ref={gRef}></div>;
//    return <Line options={options} data={data} />;
}


export default Widget;

export function appendToParent(parent, type, className, transformation){return parent.append(type).attr("class", className).attr("transform", transformation);}
export function createScaleLinear(domain, range){return d3.scaleLinear().domain(domain).rangeRound(range);}
export function createScaleBand(domain, range, paddingInner){return d3.scaleBand().domain(domain).rangeRound(range).paddingInner(paddingInner);}
export function getLeftAxis(scale){return d3.axisLeft(scale);}
export function getBottomAxis(scale){return d3.axisBottom(scale);}
