import {useState, useEffect} from 'react';
import { VictoryChart, VictoryTheme, VictoryScatter, VictoryAxis } from 'victory';
import { API_POST } from '../../../components/API/API_POST';


export const ScatterChart=({options})=>{

    const [data, setData] = useState([0,10,24,85,32,76,13]);
    const [tickValues, setTickValues] = useState([]);

    function gotData(postdata){
        console.log({postdata});
        setData(postdata);  
    }

    useEffect(()=>{
        const ticks = data.map(d=>d["CallData"]);
        setTickValues(ticks);
    },[data])

    function gotError(err){
      console.log({err});

    }

    useEffect(()=>{
      return;
      const postData = {
        firstName: 'andrewchapman@arquella.co.uk',
        lastName: 'Flintstone',
        repType: options.data,
        dataID: "",
        startDate: "",
        endDate: ""
      };
      
      API_POST('/trends/data', postData, gotData, gotError);
      console.log("posting data");  
    },[])

    const myDataset = [
      [
          { x: "a", y: 1 },
          { x: "b", y: 2 },
          { x: "c", y: 3 },
          { x: "d", y: 2 },
          { x: "e", y: 1 }
      ],
      [
          { x: "a", y: 2 },
          { x: "b", y: 3 },
          { x: "c", y: 4 },
          { x: "d", y: 5 },
          { x: "e", y: 5 }
      ],
      [
          { x: "a", y: 1 },
          { x: "b", y: 2 },
          { x: "c", y: 3 },
          { x: "d", y: 4 },
          { x: "e", y: 4 }
      ]
    ];

    function transformData(dataset) {
      const totals = dataset[0].map((data, i) => {
        return dataset.reduce((memo, curr) => {
          return memo + curr[i].y;
        }, 0);
      });
      return dataset.map((data) => {
        return data.map((datum, i) => {
          return { x: datum.x, y: (datum.y / totals[i]) * 100 };
        });
      });
    }
    const dataset = transformData(myDataset);
   

    return (

      <div>
<VictoryChart
  theme={VictoryTheme.material}
  domain={{ x: [0, 5], y: [0, 7] }}
>
  <VictoryScatter
    style={{ data: { fill: "#c43a31" } }}
    size={7}
    data={[
      { x: 1, y: 2 },
      { x: 2, y: 3 },
      { x: 3, y: 5 },
      { x: 4, y: 4 },
      { x: 5, y: 7 }
    ]}
  />
</VictoryChart>
    </div>


      )
    
}