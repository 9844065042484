import './Care.css';
import { ResidentCard } from './ResidentCard/ResidentCard';
import {Resident} from '../../components/Resident/Resident';
import { useState } from 'react';

const Care = ()=>{

    const [resident, setResident] = useState(null);



    const residentList = [
        {firstName: "David", surname: "Chapman", room: "Room 2", dob: "30/12/1956"},
        {firstName: "Cephas", surname: "Chapman", room: "Room 2", dob: "15/02/1920"},
        {firstName: "Constance", surname: "Calvert", room: "Room 2", dob: "07/04/1923"},
        {firstName: "Thomas", surname: "Hand", room: "Room 2", dob: "26/07/1923"},
    ];


    function setResidentValue(residentID){
        setResident(residentList[residentID]);
    }

    if (resident!=null) {
        return(
            <div style={{"padding-left":"100px"}}>
                <Resident firstName={resident.firstName} surname={resident.surname} roomName={resident.room} dob={resident.dob} />
            </div>
        );
    }
    return(
        
        <div>
            <div className="care_dashboard">
                {residentList.map((resident, index)=>{
                    return <ResidentCard key={"rescard"+index} id={index} name={resident.firstName+" "+resident.surname} room={resident.room} key={index} onClickEvent={setResidentValue} />
                })}
            </div>
        </div>

    );

}

export default Care;