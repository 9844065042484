import React from 'react';
import './Resident.css';

/**
 * Primary UI component for user interaction
 */
export const ResidentDetails = ({firstName, surname, dob, roomName}) => {
  return (
    <div className='resident_details_container'>
      <div>Name:</div>
      <div>{firstName} {surname}</div>
      <div>D.O.B:</div>
      <div>{dob}</div>
      <div>Room:</div>
      <div>{roomName}</div>
    </div>
  );
};
