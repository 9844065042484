import CareClicksField from "../DataTable/CareClicksField/careclicksfield";

export const DataTable = ({data, showFields}) => {
    //hiddenfields is an array of field names that should not be displayed in the table
    if (data == null) return null;
    if (data.length <= 0) return null;

    function showField(field){
        if (showFields == null) return true;
        if (showFields.length <= 0) return true;
        if (showFields.indexOf(field) >= 0) return false;
        return true;
    }


    return (
        <table className="charter_datatable">
            <thead>
                <tr>
                    {showFields != null && showFields.length > 0 ? showFields.map((field, index) => <th key={"th" + index}>{field.display}</th>) : null}
                </tr>
            </thead>
            <tbody>
                {data.map((row, index) => {
                    return(
                    <tr key={"tbodytr" + index}>
                        {showFields != null && showFields.length > 0 ? showFields.map((field, index) => {
                        
                            if (field.field == "Care Delivered") {
                                if (row[field.field])  {
//                                    return(<td key={"td" + index}>Has Care clicks</td>);
console.log("sasarow", row[field.field]);


                                    return(
                                        <td key={"td" + index}>
                                            <CareClicksField careClicks={row[field.field]} />
                                        </td>         
                                        );
                                }
                            }
                            
                            return(<td key={"td" + index}>{row[field.field]}</td>);
                        
                        }) : null}
                    </tr>
                    );
                })}
            </tbody>
        </table>
    );

}