import React from "react";
import Dashboard from "../Dashboard/Dashboard"; 
import IMG_LOGO from '../../images/logo.jpg';
import HeaderOptions from './header_options';
import {MainNavOptions, HamburgerMenu} from "./NavMenu/main_nav_options";
import getDashboardConfig from './get_dashboard_config';
import { useState } from "react";
import Move from '../Move/Move';
import Care from "../Care/Care";
import Live from "../Live/Live";
import './Main.css';
import { HeaderMain } from "./HeaderMain/HeaderMain";
import {Switch, Route, Redirect} from "react-router-dom";


const Main=()=>{
    
    const [dashboardjson, setDashboardJson] = useState(null);
    const [routeName, setRouteName] = useState("Call");

    if (dashboardjson==null) {
        getDashboardConfig().then((val)=>{
            setDashboardJson(val);
        });
    }

    return(
        <>
            <HeaderMain></HeaderMain>
            <div 
            //className="main_content_organiser"
            >
                <React.Fragment>
                <Switch>
                    <Route exact path='/'>
                        <Dashboard configjson={dashboardjson} />
                    </Route>    
                    <Route exact path='/care' component={Care} />    
                    <Route exact path='/call'>
                        <Dashboard configjson={dashboardjson} />
                    </Route>    
                    <Route exact path='/move' component={Move} />    
                    <Route exact path='/live' component={Live} />    
                    <Route exact path='/sense' component={Sense} />    
                </Switch>
                </React.Fragment>

                {/* 
                {((routeName=="Call")&&(dashboardjson!=null)) && <Dashboard configjson={dashboardjson} />}
                {(routeName=="Move") && <Move></Move>}
                {(routeName=="Care") && <Care />}
                {(routeName=="Sense") && <div>Sense</div>}
                {(routeName=="Live") && <Live />}
                */}
            </div>
            {/* 
            <div className="footer">
                <MainNavOptions classString="main_content_menu"></MainNavOptions>
            </div>
            */}
    </>
    );
}


const Sense=()=>{
    return(            <div>Sense</div>
        );
}

export default Main;
