import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import App from './App';
import reportWebVitals from './reportWebVitals';
import axios from 'axios';
import {encrypt, decrypt} from './encrypt/encryptionHandler';
import {BrowserRouter} from 'react-router-dom';

  axios.defaults.headers.common['Access-Control-Allow-Headers'] = 'Origin, X-Requested-With, Content-Type, Accept';
  axios.defaults.headers.post['Access-Control-Allow-Origin'] = "*";
  //axios.defaults.headers.post['Access-Control-Allow-Origin'] = "http://localhost:3000/";
  axios.defaults.headers.post['Access-Control-Allow-Origin'] = "https://otsukare.arquella.care/";
  axios.defaults.headers.post['Accepts'] = 'application/json; charset=UTF-8; text/plain; */*';
  axios.defaults.baseURL = "https://addatabridge.co.uk/adamartwotwo";
  //axios.defaults.baseURL = "http://localhost:3000";
  
  axios.interceptors.request.use(request => {
    let data_to_encrypt = JSON.stringify(request.data);
    let encrypted_data = encrypt(data_to_encrypt);
    request.data = encrypted_data;
    return request;
  }, 
  error => {
    return Promise.reject(error);
  });

  axios.interceptors.response.use(response => {
    return response;
  }, error => { 
    return Promise.reject(error);
  });


const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
  <React.StrictMode>
    <BrowserRouter>
    <App />
    </BrowserRouter>
  </React.StrictMode>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
