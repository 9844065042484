import {React, useRef, useEffect, useLayoutEffect } from 'react';
import * as d3 from "d3";
import {buildHorizontalBarChart} from './charts/HorizontalBarChart';
import {buildVerticalBarChart } from './charts/VerticalBarChart';
import { useState } from 'react';
import * as chartFunctions from './charts/Charter';
import './Charts.css';
import { ModalProvider } from '../../pages/Main/GlobalModal';

const primaryColour = "#4cc1bd";
const secondaryColour = "#dcf5f3";
const pieData = {a: 9, b: 20, c:30, d:8, e:12}


const defaultMargins = {
    top: 7,
    bottom: 20,
    left: 30,
    right: 10
}


const ChartSelector=({data, chartType, config})=>{
    
    const gRef = useRef(null);
    const [breakdownData, setBreakdownData] = useState(null);
    const [height, setHeight] = useState(100);
    const [width, setWidth] = useState(100);
    const [showGraph, setShowGraph] = useState(false);
    
    function canShowGraph(){
        if (isFalse(data)) return;
        if (height<=0) return;
        if (width<=0) return;
        
        setShowGraph(true);
    }

    function checkResize(){
        if ((gRef===null)||(gRef.current===null)) return;
        setHeight(gRef.current.clientHeight);
        setWidth(gRef.current.clientWidth);
    }

    function getSize(){ 
        if ((gRef===null)||(gRef.current===null)) return [0, 0];
        return [gRef.current.clientWidth, gRef.current.clientHeight]; 
    }

    const isFalse=(d)=>!d;

    function chartDataBreakdown(data){ setBreakdownData(data); }
    function clearChartDataBreakdown(){ setBreakdownData(null); }


   // useEffect(()=>{ canShowGraph(); },[height, width]);

    useLayoutEffect(()=>{
        //checkResize();
    }, []);

    useEffect(()=>{
        const resizeObserver = new ResizeObserver((entries) => {
            setShowGraph(false); 
            const {w, h} = getSize();
            setTimeout(()=>{
                const {w1, h1} = getSize();
                if ((w1==w)&&(h1==h)) checkResize();
            }, 100);
        });
        resizeObserver.observe(gRef.current);
    },[]);
    
    
    return(
            <>
            {(breakdownData!=null)&&<DataDialogue data={breakdownData} closeEvent={clearChartDataBreakdown}></DataDialogue>}
              <div className='chartTitle'>{config.title}
              </div>
              <div className='chartSubTitle'>{config.subTitle}</div>
                
              <div 
              className='dashboard_chart'
              onResize={()=>console.log("div resixer")} 
              ref={gRef}>
{
                (showGraph) 
                && 
                
                <BuildSVG 
                    data={data} 
                    chartType={chartType} 
                    config={config} 
                    height={height-10} 
                    width={width-10}
                />
            }

              </div>
            </>
    );
}


const BuildSVG=({data, chartType, config, height, width})=>{

    const gRef = useRef(null);
    const [breakdownData, setBreakdownData] = useState(null);

    const isFalse=(d)=>!d;

    useEffect(()=>{
        gRef.current.innerHTML = "";
        
        const dimensions = {
            height: height,
            width: width
        };

        if (isFalse(data)) return;
    
        if (chartType==="HorizontalBarChart") buildHorizontalBarChart(gRef.current, data, width, height, defaultMargins, chartDataBreakdown, dimensions);
        if (chartType==="VerticalBarChart") buildVerticalBarChart(gRef.current, data, defaultMargins, chartDataBreakdown, dimensions);
        if (chartType==="ScatterChart") buildScatterChart(gRef.current, data, height, width);
        if (chartType==="LineChart") buildLineChart(gRef.current, data, height, width);
        if (chartType==="LinkChart") buildLinkChart(gRef.current, linkData, height, width);
        if (chartType==="PieChart") buildPieChart(gRef.current, pieData, height, width);
        if (chartType==="GuageChart") buildGuageChart(gRef.current, pieData, height, width);
        if (chartType==="widget") chartFunctions.addWidget(gRef.current, data, height, width, config);

/*
svg.append("path")
      .datum(data)
      .attr("fill", "none")
      .attr("stroke", "steelblue")
      .attr("stroke-width", 1.5)
      .attr("d", d3.line()
        .x(function(d) { return x(d.date) })
        .y(function(d) { return y(d.value) })
        )
*/

    },[]);

    function chartDataBreakdown(data){ 
        console.log("chartDataBreakdown", data);
        setBreakdownData(data); 
    }
    function clearChartDataBreakdown(){ setBreakdownData(null); }

    return(
            <>
              {(breakdownData!=null)&&<DataDialogue data={breakdownData} closeEvent={clearChartDataBreakdown}></DataDialogue>}      
              <div 
              height={height}
              width={width}
              ref={gRef}></div>
            </>
    );

}











const ChartSelectors=({data, chartType, config})=>{

    const gRef = useRef(null);
    const [breakdownData, setBreakdownData] = useState(null);
    //const width = config.colspan * 200;
    //const height = config.rowspan * 200;

    const [height, setHeight] = useState(0);
    const [width, setWidth] = useState(0);

    useLayoutEffect(() => {
        setHeight(gRef.current.clientHeight);
        setWidth(gRef.current.clientWidth);
    }, []);

    const isFalse=(d)=>!d;

    useEffect(()=>{

        gRef.current.innerHTML = "";
        
        const dimensions = {
            height: height,
            width: width
        };


        if (isFalse(data)) return;
    
        if (chartType==="HorizontalBarChart") buildHorizontalBarChart(gRef.current, data, defaultMargins, chartDataBreakdown, dimensions);
        if (chartType==="VerticalBarChart") buildVerticalBarChart(gRef.current, data, defaultMargins, chartDataBreakdown, dimensions);
        if (chartType==="ScatterChart") buildScatterChart(gRef.current, data, dimensions.height, dimensions.width);
        if (chartType==="LineChart") buildLineChart(gRef.current, data, dimensions.height, dimensions.width);
        if (chartType==="LinkChart") buildLinkChart(gRef.current, linkData, dimensions.height, dimensions.width);
        if (chartType==="PieChart") buildPieChart(gRef.current, pieData, dimensions.height, dimensions.width);
        if (chartType==="GuageChart") buildGuageChart(gRef.current, pieData, dimensions.height, dimensions.width);
        if (chartType==="widget") chartFunctions.addWidget(gRef.current, data, defaultMargins, config);

        //  <Widget data={props.config} />
        //  EmergencyCallsWidget         
    },[]);

    function chartDataBreakdown(data){ setBreakdownData(data); }
    function clearChartDataBreakdown(){ setBreakdownData(null); }
    

    return(
            <>
            {(breakdownData!=null)&&<DataDialogue data={breakdownData} closeEvent={clearChartDataBreakdown}></DataDialogue>}
              <div className='chartTitle'>{config.title}</div>
              <div className='chartSubTitle'>{config.subTitle}</div>
              
              <div 
              //height="200"
              //width="200"
              //className='dashboard_chart' 
              ref={gRef}></div>
            </>
    );
}








/*

const [sized, {width, height}] = useSize(
        ({width}) => <div style={{}}></div>,
        { width: 100, height: 100 }
    );
    
      return (
        <div >
          {sized}
          <div>width: {width}</div>
          <div>height: {height}</div>
        </div>
      );

const ChartSelector=({data, chartType, config})=>{ }
*/

const DataDialogue=({data, closeEvent})=>{
/*    
    const content =()=><p>data</p>;
    useEffect(()=>{

        document.querySelector("#modal-root") = content;

    },[]);

    return(<></>);
    */
    return(
        <ModalProvider>
            <p>test</p>
            <p>test</p>
        </ModalProvider>
    );

    return(
        <dialog open={true} onClose={closeEvent} className="modal-content">
            <p>Data</p>
            <button onClick={()=>closeEvent()}>Close</button>
        </dialog>
    )
}


function buildScatterChart(ref, data, height, width) {
  // The SVG Element
var barchart_width     =   width;
var barchart_height    =   height;
var barchart_padding   =   40;
var svg = d3.select(ref)
    .append( 'svg' )
    .attr( 'width', barchart_width )
    .attr( 'height', barchart_height );

// Creating scales
// Width
var scale_x = d3.scaleLinear()
				.domain([0, d3.max(data, function(d){
					return d[0];
				})])
				.range([barchart_padding, barchart_width - barchart_padding * 2]);

// Height
var scale_y = d3.scaleLinear()
    			.domain([ 0, d3.max(data, function(d){
        			return d[1];
    			})])
    			.range([ barchart_height - barchart_padding, barchart_padding ]);

            svg.append("clipPath")
                .attr("id", "clipPath-area")
                .append("rect")
                .attr("x", barchart_padding)
                .attr("y", barchart_padding)
                .attr("width", barchart_width - barchart_padding * 3)
                .attr("height", barchart_height - barchart_padding * 2);
                
// Creating Axis
var axis_x = d3.axisBottom( scale_x );

svg.append( 'g' )
    .attr( 'class', 'x-axis' )
    .attr(
        'transform',
        'translate(0,' + (barchart_height - barchart_padding ) + ')'
    )
    .call( axis_x );

var axis_y = d3.axisLeft( scale_y )
    .ticks( 5 );

svg.append( 'g' )
    .attr( 'class', 'y-axis' )
    .attr(
        'transform',
        'translate( ' + barchart_padding + ', 0 )'
    )
    .call( axis_y );


    // Creating Circles
    svg.selectAll( 'circle' )
    .append("g")
    .attr("id", "path-area")
    .attr("clip-path", "url(#clipPath-area)")
    .data( data )
    .enter()
    .append( 'circle' )
    .attr("cx", function(d) {
        return scale_x(d[0]);
    })
    .attr("cy", function(d) {
        return scale_y(d[1]);
    })
    .attr("r", 10)
    .attr( 'fill', primaryColour );


    // Events
    d3.selectAll('button').on('click', function(){
        // Random data
        //data = [];
        
        // Generate a maximum number for the possible numbers that can be generated
        var maximum_num = Math.random() * 800;

        for (var i = 0; i < 6; i++) {
            // Generate the two coordinates for the circles
            var coord_x = Math.floor(Math.random() * maximum_num);
            var coord_y = Math.floor(Math.random() * maximum_num);
            // Call the push function on the data array
            data.push([coord_x, coord_y]);
        }

        // Updating the scales
        scale_x.domain([0, d3.max(data, function(d){
            return d[0];
        })]);
        scale_y.domain([0, d3.max(data, function(d){
            return d[1];
        })]);

        //Apply to the graph
        svg.selectAll('circle')
            .data(data)
            .transition()
            .on("start", function(){
                d3.select(this)
                    .attr("fill", "blue");
            })
            .duration(1500)
            .attr("cx", function(d) {
                return scale_x(d[0]);
            })
            .attr("cy", function(d) {
                return scale_y(d[1]);
            })
            .on("end", function(){
                var colours = ['white', 'yellow', 'red', 'black'];
                var random_colour = Math.floor(Math.random() * colours.length);
                d3.select(this)
                    .attr("fill", colours[random_colour]);
            });

        //Updating the axis
        svg.select('.x-axis')
        .transition()
        .duration(1500)
        .call(axis_x);
        
        svg.select('.y-axis')
        .transition()
        .duration(1500)
        .call(axis_y);    
    });

}


function buildLineChart(ref, data, height, width) {

  var dataset1 = [
    [1,1], [12,20], [24,36],
    [32, 50], [40, 70], [50, 100],
    [55, 106], [65, 123], [73, 130],
    [78, 134], [83, 136], [89, 138],
    [100, 140]
];
 
var margin = 0;
height = 300;
width = 500;
var svg = d3.select(ref)
            .append("svg")
            .attr("width", width - margin)
            .attr("height", height - margin);

    
var xScale = d3.scaleLinear().domain([0, 100]).range([0, width]);
var yScale = d3.scaleLinear().domain([0, 200]).range([height, 0]);

/*
// Title
svg.append('text')
.attr('x', width/2 + 100)
.attr('y', 100)
.attr('text-anchor', 'middle')
.style('font-family', 'Helvetica')
.style('font-size', 20)
.text('Line Chart');

// X label
svg.append('text')
.attr('x', width/2 + 100)
.attr('y', height - 15 + 350)
.attr('text-anchor', 'middle')
.style('font-family', 'Helvetica')
.style('font-size', 12)
.text('Independant');

            // Y label
        svg.append('text')
        .attr('text-anchor', 'middle')
        .attr('transform', 'translate(60,' + height + ')rotate(-90)')
        .style('font-family', 'Helvetica')
        .style('font-size', 12)
        .text('Dependant');
*/
    
        svg.append("g")
        .attr("transform", "translate(0," + height + ")")
        .call(d3.axisBottom(xScale));
       
       svg.append("g").call(d3.axisLeft(yScale));

        svg.append('g')
        .selectAll("dot")
        .data(dataset1)
        .enter()
        .append("circle")
        .attr("cx", function (d) { return xScale(d[0]); } )
        .attr("cy", function (d) { return yScale(d[1]); } )
        .attr("r", 2)
        .attr("transform", "translate(" + 0 + "," + 0 + ")")
        .style("fill", "#CC0000");

        var line = d3.line()
        .x(function(d) { return xScale(d[0]); }) 
        .y(function(d) { return yScale(d[1]); }) 
        .curve(d3.curveMonotoneX)
        
        svg.append("path")
        .datum(dataset1) 
        .attr("class", "line") 
        .attr("transform", "translate(" + 0 + "," + 0 + ")")
        .attr("d", line)
        .style("fill", secondaryColour)
        .style("stroke", primaryColour)
        .style("stroke-width", "2");


}


function buildPieChart(ref, data, height, width){

  data = [2, 4, 8, 10];

  var svg = d3.select(ref).append("svg");
      
      svg.attr("width", 500)
      svg.attr("height", 500);
      var radius = Math.min(width, height) / 3,
      g = svg.append("g").attr("transform", "translate(" + width / 2 + "," + height / 2 + ")");

  var color = d3.scaleOrdinal(['#4daf4a','#377eb8','#ff7f00','#984ea3','#e41a1c']);

  // Generate the pie
  var pie = d3.pie();

  // Generate the arcs
  var arc = d3.arc()
              .innerRadius(0)
              .outerRadius(radius);

  //Generate groups
  var arcs = g.selectAll("arc")
              .data(pie(data))
              .enter()
              .append("g")
              .attr("class", "arc")

  //Draw arc paths
  arcs.append("path")
      .attr("fill", function(d, i) {
          return color(i);
      })
      .attr("d", arc);

}

function buildGuageChart(ref, data, height, width){

  data = [24];

  var svg = d3.select(ref).append("svg");
      
      svg.attr("width", 500)
      svg.attr("height", 140);
      var radius = Math.min(width, height) / 3,
      g = svg.append("g").attr("transform", "translate(" + width / 2 + "," + height / 2 + ")");

  var color = d3.scaleOrdinal(['#4daf4a','#377eb8','#ff7f00','#984ea3','#e41a1c']);

  // Generate the pie
  var pie = d3.pie();

  // Generate the arcs
  var arc = d3.arc()
              .innerRadius(0)
              .outerRadius(radius);

  //Generate groups
  var arcs = g.selectAll("arc")
              .data(pie(data))
              .enter()
              .append("g")
              .attr("class", "arc")

  //Draw arc paths
  arcs.append("path")
      .attr("fill", function(d, i) {
          return primaryColour;  
        return color(i);
      })
      .attr("d", arc);

}

function buildLinkChart(ref, data, height, width) {
  
// The SVG Element
var barchart_width  =   width;
var barchart_height =   height;

// Create the force layout
var simulation = d3.forceSimulation(data.nodes)
    .force("charge", d3.forceManyBody().strength(-10))
    .force("link", d3.forceLink(data.links))
    .force("center", d3.forceCenter()
    	.x(barchart_width / 2)
		.y(barchart_height / 2));


var svg = d3.select(ref)
    		.append( 'svg' )
    		.attr( 'width', barchart_width )
    		.attr( 'height', barchart_height );

// Drawing the links
var lines  =   svg.selectAll("line")
    .data(data.links)
    .enter()
    .append( "line" )
    .style( "stroke", primaryColour )
    .style( "stroke-width", 2 );

// Drawing the nodes
var nodes  =  svg.selectAll("circle")
    .data(data.nodes)
    .enter()
    .append( "circle" )
    .attr( "r", function(d){
      return (d.name.includes("Carer")) ? 20 : 10;
    })
    .style( "fill", function(d){ return (d.name.includes("Carer")) ? primaryColour : secondaryColour; });

//    nodes.append("text").text("test")

  
// Tooltip
  nodes.append("title")
     .text(function( d ) {
        return d.name;
    });

    simulation.on('tick', function(){
        lines.attr('x1', function(d) {return d.source.x;})
             .attr('y1', function(d) {return d.source.y;})
             .attr('x2', function(d) {return d.target.x;})
             .attr('y2', function(d) {return d.target.y;});
		 
	nodes.attr('cx', function(d) {return d.x;})
	     .attr('cy', function(d) {return d.y;});
});
  

}

const linkData  = {
  nodes: [
      { name: "Carer 1" }, 
      { name: "Connie" },
      { name: "Carer 2" }, 
      { name: "Cephas" },
      { name: "Thomas" }, 
      { name: "Carer 3" },
      { name: "Dorene" }, 
      { name: "Alfred" }
  ],
  links: [
      { source: 0, target: 1 }, 
      { source: 2, target: 3 }, 
      { source: 2, target: 4 },
      { source: 5, target: 6 },
      { source: 5, target: 7 },
      { source: 2, target: 7 },
      { source: 2, target: 5 },
             
  ]
};



export default ChartSelector;