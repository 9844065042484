import { Charter } from "../Charter/Charter";
import { Resident_Notes } from "../DummyData";

export const ResidentNotesScreen = () => {
    
    return(
        <div className='resident_call_screen'>
            <div className="flex-column">
                <div>
                    <Charter chartType={"DataTable"} data={Resident_Notes} showFields={[
                        {display:"Date", field:"date"},
                        {display:"Carer", field:"carer"},
                        {display:"Note", field:"note"}
                    ]} />
                </div>
            </div>
        </div>
    );
}