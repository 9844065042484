import {useState, useEffect} from 'react';
import { VictoryBar, VictoryChart, VictoryAxis, VictoryTheme  } from 'victory';
import { API_POST } from '../../../components/API/API_POST';

export const VerticalBarChart=({options})=>{

    const [data, setData] = useState([]);
    const [tickValues, setTickValues] = useState([]);

    function gotData(postdata){
        console.log({postdata});
        setData(postdata);
        
    }

    useEffect(()=>{
        const ticks = data.map(d=>d["CallData"]);
        setTickValues(ticks);
    },[data])

    function gotError(err){
      console.log({err});

    }

    useEffect(()=>{
      const postData = {
        firstName: 'andrewchapman@arquella.co.uk',
        lastName: 'Flintstone',
        repType: options.data,
        dataID: "",
        startDate: "",
        endDate: ""
      };
      
      API_POST('/trends/data', postData, gotData, gotError);
      console.log("posting data");  
    },[])

   
    return (
        <VictoryChart
          // domainPadding will add space to each side of VictoryBar to
          // prevent it from overlapping the axis
          theme={VictoryTheme.material}
          domainPadding={20}
        >
          <VictoryAxis
            // tickValues specifies both the number of ticks and where
            // they are placed on the axis
            tickValues={tickValues}
            //tickFormat={["Quarter 1", "Quarter 2", "Quarter 3", "Quarter 4"]}
          />
          <VictoryAxis
            dependentAxis
            // tickFormat specifies how ticks should be displayed
            //tickFormat={(x) => (`$${x / 1000}k`)}
          />
          <VictoryBar
            data={data}
            x="CallDate"
            y="Value"
            style={{ data: { fill: '#4cc1bd' } }}
            animate={{
                onExit: {
                  duration: 500,
                  before: () => ({
                    _y: 0,
                    fill: "orange",
                    label: "BYE"
                  })
                }
            }}
          />
        </VictoryChart>
    )
    
}