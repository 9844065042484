import {useState, useEffect} from 'react';
import './Dashboard.css';
import DashBoardItem from './DashboardItem';
import '../../../node_modules/react-grid-layout/css/styles.css';
import '../../../node_modules/react-resizable/css/styles.css';
import './DashboardGrid.css';
import Widget from './graphs/widgets/Widget';
import { VerticalBarChart } from './graphs/VerticalBarChart';
import {LineChart} from './graphs/LineChart';
import { PieChart } from './graphs/PieChart';
import {ColumnStack} from './graphs/ColumnStack';
import {ScatterChart} from './graphs/ScatterChart';
import {GuageChart} from './graphs/GuageChart';
import {ProgressBar} from './graphs/ProgressBar';
import {HorizontalGroupedBarChart} from './graphs/HorizontalGroupedBarChart';



const Dashboard=({configjson})=>{

    const [layout, setLayout] = useState(null); 
    
    useEffect(()=>{
        const tmpLayout = [];
        if (configjson==null) return;
        configjson.map((c,i)=>{
            tmpLayout.push({ i: c.key, 
                x: c.x, y: c.y, 
                w: c.colspan, h: c.rowspan })
        })

        setLayout(tmpLayout);

    },[configjson])
   
    const handleLayoutChange = (layout, layouts) => {
        localStorage.setItem("grid-layout", JSON.stringify(layouts));
    };

    const getLayouts = () => {
        const savedLayouts = localStorage.getItem("grid-layout");
        return savedLayouts ? JSON.parse(savedLayouts) : { lg: layout };
    };

      

    if (!layout) return (<div>Not ready yet</div>);

      return (
        <div 
          className="grid_dash"
          //layouts={getLayouts()}
          //breakpoints={{ lg: 1200, md: 996, sm: 768, xs: 480, xxs: 0 }}
          //cols={{ lg: 5, md: 4, sm: 3, xs: 2, xxs: 1 }}
          //rowHeight={150}
          //width={1200}
          //onLayoutChange={handleLayoutChange}
        >
        {
        configjson.map((c,i)=> {
            console.log(c.chartName);
            let strClassName = "grid_item fade-in";
            strClassName = strClassName + " col_span_" + c.colspan;
            strClassName = strClassName + " row_span_" + c.rowspan;

            return(
                <div key={c.key} className={strClassName}>
                    {(c.chartName==="widget") && <Widget data={c}></Widget>}
                    {(c.chartName=="VerticalBarChart") && <GetChart options={c}><VerticalBarChart options={c} /></GetChart>}
                    {(c.chartName=="LineChart") && <GetChart options={c}><LineChart options={c} /></GetChart>}
                    {(c.chartName=="PieChart") && <GetChart options={c}><PieChart options={c} /></GetChart>}
                    {(c.chartName=="ColumnStack") && <GetChart options={c}><ColumnStack options={c} /></GetChart>}
                    {(c.chartName=="ScatterChart") && <GetChart options={c}><ScatterChart options={c} /></GetChart>}
                    {(c.chartName=="GuageChart") && <GetChart options={c}><GuageChart options={c} /></GetChart>}
                    {(c.chartName=="ProgressBar") && <GetChart options={c}><ProgressBar options={c} /></GetChart>}
                    {(c.chartName=="HorizontalGroupedBarChart") && <GetChart options={c}><HorizontalGroupedBarChart options={c} /></GetChart>}
                    
                    
                </div>
            )
             
            console.log({c});
            
        })
        }
        </div>
      );
}


const GetChart=(props)=>{

    return(
        <div className="dashboard_widget">
        <div>
            <div className="title">{props.options.title}</div>
            {props.children}
            <div className="subtitle">{props.options.subTitle}</div>
            <div className="caption">{props.options.caption}</div>
        </div>
        </div>

    );

}

export default Dashboard;

{/* 
                                            <DashBoardItem 
                            key={"di" + i}
                            colspan={c.colspan}
                            rowspan={c.rowspan}
                            index={i}
                            widgetName={c.widgetName}
                            dataSource={c.data}
                            config={c}
//                            handleDragStart={handleDragStart}
 //                           handleDragEnter={handleDragEnter}
  //                          handleDragLeave={handleDragLeave}
   //                         handleDrop={handleDrop}
     //                       editLayout={editLayout}
                        >
                        </DashBoardItem>
*/}