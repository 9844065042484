import { ResidentHeader } from "./ResidentHeader";
import { ResidentContent } from "./ResidentContent";

export const Resident=({firstName, surname, dob, roomName})=>{

    return(
        <div className="resident_container">
            <ResidentHeader firstName={firstName} surname={surname} dob={dob} roomName={roomName} />
            <hr />
            <ResidentContent />
        </div>
    );
}