import './LiveDashboard.css';
import DashboardIcon from './DashboardIcon';
import './Modal.css';

const LiveDashboard=({data, selectedValueCallBack})=>{

    const callTypesList = ["All_Calls", "Emergency", "Call", "Accessory", "Attendance", "Assistance", "Care_Call"];
    

    function getDataVal(key, property){

        const dVal = data[key.toString()];
        if (dVal==undefined) {
            if (property=="total") return 0;
            if (property=="averageTime") return "00:00:00";
            return "";
        }

        if (property=="total") return dVal.total;
        if (property=="averageTime") {
            if (dVal.averageTime==="NaN:NaN:NaN") return "00:00:00"; 
            return dVal.averageTime;
        }
        
        return "";
    
    }
    
    
    return(
        <div 
            className={"dashboard_call_summary_container"}
            //onClick={()=> {}}
            //dashboard_call_summary_layout
        > 
        {
            callTypesList.map((site, index) => {
                return (
                    <div 
                        key={"callTypesList"+index}
                        title={site}
                        className={"dashboard_completed_calls_container dashboard_call_summary_layout dashboard_" + site} 
                        onClick={()=> {
                            if (selectedValueCallBack!=null) selectedValueCallBack(site)
                        }}
                    >
                        <DashboardIcon callType={site} />
                        <div className='dashboard_completed_calls_item align-left'>{site}</div>
                        <div className='dashboard_completed_calls_item text-align-center'>{getDataVal(site, "total")}</div>
                        <div className='dashboard_completed_calls_item text-align-center'>{getDataVal(site, "averageTime")}</div>
                    </div>
                );
            })
        }

    </div>
    );
}

export default LiveDashboard;
