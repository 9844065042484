import { ResidentAvatar } from './ResidentAvatar';
import { ResidentDetails } from './ResidentDetails';

export const ResidentHeader = ({firstName, surname, dob, roomName}) => {

    return(
        <div className='resident_header'>
            <ResidentAvatar />
            <ResidentDetails firstName={firstName} surname={surname} dob={dob} roomName={roomName} />
            
        </div>
    );
};