import React from "react";
import './HeaderMain.css';
import IMG_LOGO from '../../../images/logo.jpg';

export const HeaderMain=()=>{

    return(
        <header className="header">
        <img className="main_logo_header" src={IMG_LOGO} />
        <div className="menu_user_container">
            <div>
                <input className="side-menu" type="checkbox" id="side-menu"/>
                <label className="hamb" htmlFor="side-menu"><span className="hamb-line"></span></label>
                <nav className="nav">
                    <ul className="menu">
                        <li><a href="call">Call</a></li>
                        <li><a href="move">Move</a></li>
                        <li><a href="care">Care</a></li>
                        <li><a href="sense">Sense</a></li>
                        <li><a href="live">Live</a></li>
                    </ul>
                </nav>
            </div>
            <div>UM</div>
        </div>
        
        </header>
    );
}
