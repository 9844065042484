import React from 'react';
import './ResidentCard.css';

/**
 * Primary UI component for user interaction
 */
export const ResidentCard = ({name, room, id, onClickEvent}) => {
  
  function clickEvent(id){
    console.log("ResidentCard: "+id);
    onClickEvent(id);

  }

  return (
    <div className='ResidentCard' onClick={()=>clickEvent(id)}>
        <div className='avatar'>
          <img src='https://www.w3schools.com/howto/img_avatar.png' alt='Avatar' />
        </div>
        <div className='details'>
          <div className='name'>{name}</div>
          <div className='room'>{room}</div>
        </div>
    </div>
  );
};
