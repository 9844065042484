import Widget from './graphs/widgets/Widget';
import ChartSelector from '../../components/graphs/ChartSelector';
import { useState } from 'react';
import getDashboardData from './get_dashboard_data';
import { useEffect } from 'react';
import { Charter } from '../../components/Charter/Charter';

const DashBoardItem=(props)=>{

    const [tData, setTData] = useState(null); 
    let className = "col";
    //if (props.editLayout) className = className + " drag_item";
    //if (props.colspan) className = className + " col_span_" + props.colspan;
    //if (props.rowspan) className = className + " row_span_" + props.rowspan;

    function getData(){        
        getDashboardData(props.dataSource)
            .then((val)=>{ setTData(val) });
    }
    useEffect(()=>{
        if (tData == null) { getData(); }
    }, []);
    
    if (tData == null) return <div className={className + " greyItem"}></div>;

    return (
        <div 
            key={props.index}
            draggable={props.editLayout}
//            onDragStart={() => props.handleDragStart(props.index)}
//            onDragEnter={(e) => props.handleDragEnter(e, props.index)}
//            onDragLeave={(e) => props.handleDragLeave(e, props.index)}
//            onDrop={(e) => props.handleDrop(e, props.index)}
//            onDragOver={(e) => e.preventDefault()}
            className={className}
            >
            <GetChart chartType={props.config.chartName} data={tData} config={props.config} />
            {props.children}
            </div>);
}


const GetChart=({data, chartType, config})=>{

    const showFields = [
        {display:"Call type", field:"cll_type"},
        {display:"Zone", field:"cll_zone"},
        {display:"Room", field:"cll_room"},
        {display:"Start", field:"cll_start_date"},
        {display:"End", field:"cll_end_date"},
        {display:"Duration", field:"cll_duration"},
        {display:"Carer", field:"cll_carer"}
    ];


    if (chartType=="DataTable") return <Charter chartType={chartType} data={data} showFields={showFields} />;
    if (chartType=="widget") return <Widget data={data} /> ;

    console.log("ChartSelectorData", data);

    return(
        <ChartSelector 
            data={data} 
            chartType={chartType}
            config={config}
        />
    )

}

export default DashBoardItem;