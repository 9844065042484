import { group } from 'd3';
import firebase from 'firebase';
import { useEffect, useState } from 'react';
import LiveDashboard from './LiveDashboard';
import LiveCall from './LiveCall';

export const LiveCallsController = () => {
    
    const [calls, setCalls] = useState([]);
    
    
    const groupBy=(list, keyGetter)=> {
        const map = new Map();
        list.forEach((item) => {
             const key = keyGetter(item);
             const collection = map.get(key);
             if (!collection) {
                 map.set(key, [item]);
             } else {
                 collection.push(item);
             }
        });
        return map;
    }
    

    function addCallSnapshot(snapshot){
        const val = snapshot.val();
        console.log("we have a snapshot", val);
        
        if (val==null) {
            setCalls([]);
            return;
        }
        const recs = Object.keys(val).map(k => val[k]);
        console.log("recs", recs);

        setCalls(recs);
    }

    
    const getfb = (house, rDate, houseTitle, ref)=> new firebase.database().ref(ref).on("value", snapshot => addCallSnapshot(snapshot));

    function convertLongDateStringToDateTime(longDate){
        const date = new Date(longDate);
        return date;
    }


    function calculateDuration(start, end){
        //  end: "13:12:28"
        //  start: "2023-01-19 13:12:27.562682Z"
        if (start==null || end==null) return 0;
        const startDateTime = convertLongDateStringToDateTime(start);
        const clipStart = start.substring(0, 11);
        const clipEnd = start.substring(19);
        const endDateTime = convertLongDateStringToDateTime(clipStart + end + clipEnd);
        return secondsBetweenDates(startDateTime, endDateTime);
        return 0;
    }


    function convertSecondsToHMS(seconds) {
        let h = Math.floor(seconds / 3600);
        let m = Math.floor(seconds % 3600 / 60);
        let s = Math.floor(seconds % 3600 % 60);
        let hStr = (h.toString().length==1) ? "0"+h : h;
        let mStr = (m.toString().length==1) ? "0"+m : m;
        let sStr = (s.toString().length==1) ? "0"+s : s;
        
        return `${hStr}:${mStr}:${sStr}`;

        /*
        const date = new Date(null);
        date.setSeconds(SECONDS); // specify value for SECONDS here
        const result = date.toISOString().slice(11, 19);
         */
    }

    function calculateAverageDuration(calls) {
        let total = 0;
        calls.forEach(c => {
            total += calculateDuration(c.start, c.end);
        });
        return convertSecondsToHMS(total/calls.length);
    }
    

    function secondsBetweenDates(start, end) {
        var difference = (end - start) / 1000;
        return difference;
    }

    function getTotalObjectFromList(list) {
        return {
            "total": list.length,
            "averageTime": calculateAverageDuration(list),
        };
    }
    
    useEffect(() => {
        
        //getfb("weir", "24012023", "Weir House", "weirPhoneLiveCalls");
        getfb("adctest", "24012023", "ADC Care Home", "adctestPhoneLiveCalls");

    }, [])

    return (
        <>
        { 
        calls.map((c, i)=>{

            return(   
                <div
                    className="live_call_container"
                    key={i}>
                    <div className='livecallheader'>Live Calls</div>
                    <LiveCall data={c} />    
                </div>
            );

        })
        }
        </>
        );
}