import { group } from 'd3';
import firebase from 'firebase';
import { useEffect, useState } from 'react';
import LiveDashboard from './LiveDashboard';
import {DataTable} from '../../components/Charter/DataTable';

export const LiveDashboardController = () => {
    
    const [calls, setCalls] = useState([]);
    const [groupedCalls, setGroupedCalls] = useState(null);
    const [callTotals, setCallTotals] = useState({});
    const [breakDownCalls, setBreakdownCalls] = useState("");
    
    const groupByKey = (list, key, {omitKey=false}) => list.reduce((hash, {[key]:value, ...rest}) => ({...hash, [value]:( hash[value] || [] ).concat(omitKey ? {...rest} : {[key]:value, ...rest})} ), {})
    
    const groupBy=(list, keyGetter)=> {
        const map = new Map();
        list.forEach((item) => {
             const key = keyGetter(item);
             const collection = map.get(key);
             if (!collection) {
                 map.set(key, [item]);
             } else {
                 collection.push(item);
             }
        });
        return map;
    }
    

    function addCallSnapshot(snapshot){
        const val = snapshot.val();
        console.log("we have a snapshot", val);
        
        if (val==null) return;
        const recs = Object.keys(val).map(k => val[k]);
        console.log("recs", recs);

        setCalls(recs);
    }

    
    const getfb = (house, rDate, houseTitle, ref)=> new firebase.database().ref(ref).on("value", snapshot => addCallSnapshot(snapshot));

    function convertLongDateStringToDateTime(longDate){
        const date = new Date(longDate);
        return date;
    }


    function calculateDuration(start, end){
        //  end: "13:12:28"
        //  start: "2023-01-19 13:12:27.562682Z"
        if (start==null || end==null) return 0;
        const startDateTime = convertLongDateStringToDateTime(start);
        const clipStart = start.substring(0, 11);
        const clipEnd = start.substring(19);
        const endDateTime = convertLongDateStringToDateTime(clipStart + end + clipEnd);
        return secondsBetweenDates(startDateTime, endDateTime);
        return 0;
    }


    function convertSecondsToHMS(seconds) {
        let h = Math.floor(seconds / 3600);
        let m = Math.floor(seconds % 3600 / 60);
        let s = Math.floor(seconds % 3600 % 60);
        let hStr = (h.toString().length==1) ? "0"+h : h;
        let mStr = (m.toString().length==1) ? "0"+m : m;
        let sStr = (s.toString().length==1) ? "0"+s : s;
        
        return `${hStr}:${mStr}:${sStr}`;

        /*
        const date = new Date(null);
        date.setSeconds(SECONDS); // specify value for SECONDS here
        const result = date.toISOString().slice(11, 19);
         */
    }

    function calculateAverageDuration(calls) {
        let total = 0;
        calls.forEach(c => {
            total += calculateDuration(c.start, c.end);
        });
        return convertSecondsToHMS(total/calls.length);
    }
    

    function secondsBetweenDates(start, end) {
        var difference = (end - start) / 1000;
        return difference;
    }

    function getTotalObjectFromList(list) {
        return {
            "total": list.length,
            "averageTime": calculateAverageDuration(list),
        };
    }

    function generateCallTotals(callsList) {
        if (groupedCalls==null) return;
        let newObj = {};
        groupedCalls.forEach((value, key) => {
            newObj[key] = getTotalObjectFromList(value);
            /*
            newObj[key] = {
                "total": value.length,
                "averageTime": calculateAverageDuration(value),
            };
            */
        });

        const tCalls = calls.filter(c => c.callType!="Visit");
        newObj["All_Calls"] = getTotalObjectFromList(tCalls);

        setCallTotals(newObj);
    }

    useEffect(() => {
        if (groupedCalls==null) return;
        generateCallTotals(...groupedCalls);
    }, [groupedCalls]);
    
    useEffect(() => {
        const tCalls = calls.filter(c => c.callType!="Visit");
        const groupedCalls = groupBy(tCalls,  d => d["callType"]);
        setGroupedCalls(groupedCalls);
    }, [calls])

    useEffect(() => {
        getfb("weir", "24012023", "Weir House", "weir_site/calls/24012023");
    }, [])

    function selectedValueCallBack(selectedValue) {
        setBreakdownCalls(selectedValue);
    }

    function getCallsForBreakdown() {
        console.log("selectedValue", breakDownCalls);
        console.log(groupedCalls.get(breakDownCalls));
        console.log(groupedCalls);

        let getVal = breakDownCalls;
        if (getVal==="Care_Call") getVal = "CareCall";

        let rtnCalls = (breakDownCalls==="All_Calls") ? calls : groupedCalls.get(getVal)

        return rtnCalls;
    }

    if (groupedCalls==null) return (<div>Loading...</div>);

    const ShowModal=()=> {
        
        const calls = getCallsForBreakdown();
        const tCalls = calls.filter(c => c.callType!="Visit");
        console.log("calls", tCalls);

        const showFields =
        [
            {display:"Zone", field:"zone"},
            {display:"Room", field:"room"},
            {display:"Call Type", field:"callType"},
            {display:"Start", field:"startFullDate"},
            {display:"End Time", field:"end"},
            {display:"Duration", field:"duration"},
            {display:"Carer", field:"carer"},
            {display:"Care", field:"Care Delivered"},
                                        
        ]

        
        return (
        <div id="myModal" className="modal">
            <div className="modal-content">
            <span onClick={()=>setBreakdownCalls("")} className="close">&times;</span>
            
            <DataTable data={tCalls} showFields={showFields} />

            </div>
      
        </div>
      );
    }
    
    
    return ( 
        <>
            {(breakDownCalls!="") ? <ShowModal /> : null}
            <LiveDashboard selectedValueCallBack={selectedValueCallBack} data={callTotals} /> 
        </>
    );
}



const DisplayCalls = ({calls}) => {


    return(
        <table>
            <thead>
                <tr>
                    {Object.keys(calls[0]).map((k,i) => <th key={"displaycallsth" + i}>{k}</th>)}
                </tr>
            </thead>
            <tbody>
                {calls.map((c,i) => {
                    return(
                        <tr key={"callsrow" + i}>
                        {Object.keys(c).map((k,i) => {
                        return(<td key={"displaycallstd" + i}>{c[k]}</td>)})}
                        </tr>
                    );
                })}
            </tbody>
        </table>

    );

}