import axios from 'axios';

export function API_POST (path, data, onData, onError) {
  if (path === "careplan") path = "/careplan/data";
  let sData = {
    "user": localStorage.getItem("userEmail"),
    "userKey": localStorage.getItem("userEmail"),
    ...data
  };
  
  console.log({sData});
  
  axios.post(path, sData)
  .then(res => {
    if (("data" in res)) {
      if (res.data.length > 0) {
        const data = res.data;
        onData(data);
      }
    } 
            
  })
  .catch((e)=>{  onError(e);  });
}
