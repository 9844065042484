import * as d3 from "d3";
import { useEffect, useRef } from "react";
import './Charter.css';
import { DataTable } from "./DataTable";

export const Charter=({chartType, data, showFields})=>{

    const width = "500";
    const height = "300";
    const gRef = useRef(null);
    const margins = {top: 20, right: 20, bottom: 20, left: 20};

    function breakdownReturn(data){
        console.log("breakdownReturn",data.selectionVal);
    }


    useEffect(()=>{
        if (chartType=="DataTable") return;
        if (chartType=="HorizontalBarChart") buildHorizontalBarChart(gRef.current, data, width, height, margins);
        if (chartType=="VerticalBarChart") buildVerticalBarChart(gRef.current, data, margins, breakdownReturn, {height: height, width: width});
        if (chartType=="ScatterChart") buildScatterChart(gRef.current, data, margins, breakdownReturn, {height: height, width: width});
        if (chartType=="BloodPressure") buildBloodPressureChart(gRef.current, data, margins, breakdownReturn, {height: height, width: width});
    },[]);

    if (chartType=="DataTable") return(<DataTable data={data} showFields={showFields} />);

    return(<div className="chart_container" height={height} width={width} ref={gRef}></div>);

}

export function buildVerticalBarChart(ref, data, margins, chartDataBreakdown, dimensions) {
    
    const svgDiv = d3.select(ref);
    svgDiv.selectAll("*").remove();
    //const dimensions = chartfunctions.getWidthAndHeight(ref, margins);
    const datasets = data.map(d=>d.value);
    const headers = data.map(d=>d.item);

    const svg = appendToParent(svgDiv, 'svg', "svgchart", ``);
    
        svg.attr("width", dimensions.width);
        svg.attr("height", dimensions.height);
    
        addDefsToSVG(svg);
        const g = appendToParent(svg, 'g', "gwrapper", `translate(${margins.left}, ${margins.top})`);

        var yScale = createScaleLinear([0, d3.max(datasets, (d)=>d)], [(dimensions.height-(margins.bottom + margins.top)), (margins.top)]);
        var xScale = createScaleBand(d3.range(data.length), [0, dimensions.width-(margins.left+margins.right)], 0.1)
        let yAxisGenerator = getLeftAxis(yScale);
        let yAxis = appendToParent(g, 'g', "yAxis", null).call(yAxisGenerator);
    
        let rects = g.selectAll(".barchart_bar")

        let rectsEnter = rects.data(datasets).enter();
        rectsEnter.append("rect")
            .attr("class", "barchart_bar")
            .attr("x", (d,i)=>xScale(i))
            .attr("y", dimensions.height-(margins.bottom+margins.top))
            .attr("dIndex", function(d, i){ return i; })
            .on('click', function(d, i){ 
                try {
                    chartDataBreakdown({"selectionVal" : d.target.attributes.dIndex.value});
                } catch(e){console.log(e)}
            })
            .attr("width", xScale.bandwidth())
            .attr("height", 0);


            
/********************** Chart value text ***********************************/

            let fSize = 12;
            if (dimensions.width<200) fSize=8;
            //fSize=3;

        let text = g.selectAll(".barchart_bartext")
        let textEnter = text.data(datasets).enter();

        textEnter.append("text")
            .attr("class", "barchart_bartext")
            .text(d=>d)
            .attr("y", dimensions.height-(margins.bottom+margins.top))
            .attr("x", function(d,i){ return xScale(i) + (xScale.bandwidth()/2)-7;})
            .attr("font-size", fSize)
            .attr("text-anchor", "center")
           
         
        textEnter.selectAll("text")
            .transition()
            .duration(10)
            .ease(d3.easeCircle)
            .attr("y", (d,i)=>(yScale(d) - 3))
            .delay(function(d,i){return(i*100)})
/***********************************************************************/

      // Animation
      g.selectAll("rect")
        .transition()
        .delay(10)  
        .duration(200)
        .ease(d3.easeCircle)
        .attr("x", (d,i)=>xScale(i))
        .attr("y", d=>yScale(d))
        .delay(function(d,i){return(i*100)})
        .attr("height", d=>dimensions.height - yScale(d)-margins.bottom-margins.top);

        
/***********************************************************************/

let xAxisGenerator = 
    d3.axisBottom(xScale)
    .tickFormat((d,i)=>headers[i]);

let xAxis = appendToParent(g, 'g', "xAxis", `translate(0, ${dimensions.height-(margins.bottom+margins.top)})`)
              .call(xAxisGenerator);

/***********************************************************************/

}

export function buildDataTable(ref, data, margins, chartDataBreakdown, dimensions) {
    
    const svgDiv = d3.select(ref);
    //const dimensions = chartfunctions.getWidthAndHeight(ref, margins);
    const datasets = data.map(d=>d.value);
    const headers = data.map(d=>d.item);

    const svg = appendToParent(svgDiv, 'svg', "svgchart", ``);
    
        svg.attr("width", dimensions.width);
        svg.attr("height", dimensions.height);
    
        addDefsToSVG(svg);
        const g = appendToParent(svg, 'g', "gwrapper", `translate(${margins.left}, ${margins.top})`);

        var yScale = createScaleLinear([0, d3.max(datasets, (d)=>d)], [(dimensions.height-(margins.bottom + margins.top)), (margins.top)]);
        var xScale = createScaleBand(d3.range(data.length), [0, dimensions.width-(margins.left+margins.right)], 0.1)
        let yAxisGenerator = getLeftAxis(yScale);
        let yAxis = appendToParent(g, 'g', "yAxis", null).call(yAxisGenerator);
    
        let rects = g.selectAll(".barchart_bar")

        let rectsEnter = rects.data(datasets).enter();
        rectsEnter.append("rect")
            .attr("class", "barchart_bar")
            .attr("x", (d,i)=>xScale(i))
            .attr("y", dimensions.height-(margins.bottom+margins.top))
            .attr("dIndex", function(d, i){ return i; })
            .on('click', function(d, i){ 
                try {
                    chartDataBreakdown({"selectionVal" : d.target.attributes.dIndex.value});
                } catch(e){console.log(e)}
            })
            .attr("width", xScale.bandwidth())
            .attr("height", 0);


            
/********************** Chart value text ***********************************/

            let fSize = 12;
            if (dimensions.width<200) fSize=8;
            //fSize=3;

        let text = g.selectAll(".barchart_bartext")
        let textEnter = text.data(datasets).enter();

        textEnter.append("text")
            .attr("class", "barchart_bartext")
            .text(d=>d)
            .attr("y", dimensions.height-(margins.bottom+margins.top))
            .attr("x", function(d,i){ return xScale(i) + (xScale.bandwidth()/2)-7;})
            .attr("font-size", fSize)
            .attr("text-anchor", "center")
           
         
        textEnter.selectAll("text")
            .transition()
            .duration(10)
            .ease(d3.easeCircle)
            .attr("y", (d,i)=>(yScale(d) - 3))
            .delay(function(d,i){return(i*100)})
/***********************************************************************/

      // Animation
      g.selectAll("rect")
        .transition()
        .delay(10)  
        .duration(200)
        .ease(d3.easeCircle)
        .attr("x", (d,i)=>xScale(i))
        .attr("y", d=>yScale(d))
        .delay(function(d,i){return(i*100)})
        .attr("height", d=>dimensions.height - yScale(d)-margins.bottom-margins.top);

        
/***********************************************************************/

let xAxisGenerator = 
    d3.axisBottom(xScale)
    .tickFormat((d,i)=>headers[i]);

let xAxis = appendToParent(g, 'g', "xAxis", `translate(0, ${dimensions.height-(margins.bottom+margins.top)})`)
              .call(xAxisGenerator);

/***********************************************************************/

}
export function buildBloodPressureChart(ref, data, margins, chartDataBreakdown, dimensions) {
    
    const svgDiv = d3.select(ref);
    //const dimensions = chartfunctions.getWidthAndHeight(ref, margins);
    const datasets = data.map(d=>d.value);
    const headers = data.map(d=>d.item);

    const svg = appendToParent(svgDiv, 'svg', "svgchart", ``);
    
        svg.attr("width", dimensions.width);
        svg.attr("height", dimensions.height);
    
        addDefsToSVG(svg);
        const g = appendToParent(svg, 'g', "gwrapper", `translate(${margins.left}, ${margins.top})`);

        var yScale = createScaleLinear([0, d3.max(datasets, (d)=>d)], [(dimensions.height-(margins.bottom + margins.top)), (margins.top)]);
        var xScale = createScaleBand(d3.range(data.length), [0, dimensions.width-(margins.left+margins.right)], 0.1)
        let yAxisGenerator = getLeftAxis(yScale);
        let yAxis = appendToParent(g, 'g', "yAxis", null).call(yAxisGenerator);
    
        let rects = g.selectAll(".barchart_bar")

        let rectsEnter = rects.data(datasets).enter();
        rectsEnter.append("rect")
            .attr("class", "barchart_bar")
            .attr("x", (d,i)=>xScale(i))
            .attr("y", dimensions.height-(margins.bottom+margins.top))
            .attr("dIndex", function(d, i){ return i; })
            .on('click', function(d, i){ 
                try {
                    chartDataBreakdown({"selectionVal" : d.target.attributes.dIndex.value});
                } catch(e){console.log(e)}
            })
            .attr("width", xScale.bandwidth())
            .attr("height", 0);


            
/********************** Chart value text ***********************************/

            let fSize = 12;
            if (dimensions.width<200) fSize=8;
            //fSize=3;

        let text = g.selectAll(".barchart_bartext")
        let textEnter = text.data(datasets).enter();

        textEnter.append("text")
            .attr("class", "barchart_bartext")
            .text(d=>d)
            .attr("y", dimensions.height-(margins.bottom+margins.top))
            .attr("x", function(d,i){ return xScale(i) + (xScale.bandwidth()/2)-7;})
            .attr("font-size", fSize)
            .attr("text-anchor", "center")
           
         
        textEnter.selectAll("text")
            .transition()
            .duration(10)
            .ease(d3.easeCircle)
            .attr("y", (d,i)=>(yScale(d) - 3))
            .delay(function(d,i){return(i*100)})
/***********************************************************************/

      // Animation
      g.selectAll("rect")
        .transition()
        .delay(10)  
        .duration(200)
        .ease(d3.easeCircle)
        .attr("x", (d,i)=>xScale(i))
        .attr("y", d=>yScale(d))
        .delay(function(d,i){return(i*100)})
        .attr("height", d=>dimensions.height - yScale(d)-margins.bottom-margins.top);

        
/***********************************************************************/

let xAxisGenerator = 
    d3.axisBottom(xScale)
    .tickFormat((d,i)=>headers[i]);

let xAxis = appendToParent(g, 'g', "xAxis", `translate(0, ${dimensions.height-(margins.bottom+margins.top)})`)
              .call(xAxisGenerator);

/***********************************************************************/

}
export function buildScatterChart(ref, data, margins, chartDataBreakdown, dimensions) {
    
    const svgDiv = d3.select(ref);
    const datasets = data.map(d=>d.value);
    const headers = data.map(d=>d.item);
    

    const h = dimensions.height-40;//-(dimensions.top+dimensions.bottom);

    console.log("h: " + h);
    const svg = appendToParent(svgDiv, 'svg', "svgchart", ``);
    
        svg.attr("width", dimensions.width);
        svg.attr("height", dimensions.height);
    
        addDefsToSVG(svg);
        const g = appendToParent(svg, 'g', "gwrapper", `translate(${margins.left}, ${margins.top})`);
    
        var yScale = createScaleLinear([0, 50], [(dimensions.height-(margins.bottom + margins.top)), (margins.top)]);
        var xScale = createScaleLinear([24, 0], [(dimensions.width-(margins.left + margins.right)), (margins.left)]);
        let yAxisGenerator = getLeftAxis(yScale);
        let yAxis = appendToParent(g, 'g', "yAxis", null).call(yAxisGenerator);
        let xAxisGenerator = getBottomAxis(xScale);
        let xAxis = appendToParent(g, 'g', "xAxis", `translate(-20, ${h})`).call(xAxisGenerator);
        //let xAxis = appendToParent(g, 'g', "xAxis", "translate(0," + (dimensions.height-(margins.bottom + margins.top)) + ")").call(xAxisGenerator);
    
    
        // Add dots
        svg.append('g')
        .selectAll("dot")
        .data(data)
        .enter()
        .append("circle")
        .attr("cx", function (d) { 
            return 20;
            //return x(d.GrLivArea); 
        } )
        .attr("cy", function (d) { 
            return 20;
            //return y(d.SalePrice); 
        } )
        .attr("r", 1.5)
        .style("fill", "#69b3a2")

    /*
        

        const chartArea = {
            height: (dimensions.height-(margins.bottom + margins.top)),
            width: (dimensions.width-(margins.left + margins.right))
        }



        g.append("rect")
            .attr("class", "barchart_bar-bp-red")
            .attr("x", 0)
            .attr("y", chartArea.height-yScale(90))
            .attr("width", xScale(60))
            .attr("height", chartArea.height - yScale(90));

    
        
        let rects = g.selectAll(".barchart_bar")

        let rectsEnter = rects.data(datasets).enter();
        rectsEnter.append("rect")
            .attr("class", "barchart_bar")
            .attr("x", (d,i)=>xScale(i))
            .attr("y", dimensions.height-(margins.bottom+margins.top))
            .attr("dIndex", function(d, i){ return i; })
            .on('click', function(d, i){ 
                try {
                    chartDataBreakdown({"selectionVal" : d.target.attributes.dIndex.value});
                } catch(e){console.log(e)}
            })
            .attr("width", xScale.bandwidth())
            .attr("height", 0);

*/
            
/********************** Chart value text ***********************************/

/*
            let fSize = 12;
            if (dimensions.width<200) fSize=8;
            //fSize=3;

        let text = g.selectAll(".barchart_bartext")
        let textEnter = text.data(datasets).enter();

        textEnter.append("text")
            .attr("class", "barchart_bartext")
            .text(d=>d)
            .attr("y", dimensions.height-(margins.bottom+margins.top))
            .attr("x", function(d,i){ return xScale(i) + (xScale.bandwidth()/2)-7;})
            .attr("font-size", fSize)
            .attr("text-anchor", "center")
           
         
        textEnter.selectAll("text")
            .transition()
            .duration(10)
            .ease(d3.easeCircle)
            .attr("y", (d,i)=>(yScale(d) - 3))
            .delay(function(d,i){return(i*100)})

      // Animation
      g.selectAll("rect")
        .transition()
        .delay(10)  
        .duration(200)
        .ease(d3.easeCircle)
        .attr("x", (d,i)=>xScale(i))
        .attr("y", d=>yScale(d))
        .delay(function(d,i){return(i*100)})
        .attr("height", d=>dimensions.height - yScale(d)-margins.bottom-margins.top);

        

let xAxisGenerator = 
    d3.axisBottom(xScale)
    .tickFormat((d,i)=>headers[i]);

let xAxis = appendToParent(g, 'g', "xAxis", `translate(0, ${dimensions.height-(margins.bottom+margins.top)})`)
              .call(xAxisGenerator);

*/

}

export function buildScatterChartbop(ref, data, margins, chartDataBreakdown, dimensions) {
    
    const svgDiv = d3.select(ref);
    const datasets = data.map(d=>d.value);
    const headers = data.map(d=>d.item);

    const svg = appendToParent(svgDiv, 'svg', "svgchart", ``);
    
        svg.attr("width", dimensions.width);
        svg.attr("height", dimensions.height);
    
        addDefsToSVG(svg);
        const g = appendToParent(svg, 'g', "gwrapper", `translate(${margins.left}, ${margins.top})`);

        const chartArea = {
            height: (dimensions.height-(margins.bottom + margins.top)),
            width: (dimensions.width-(margins.left + margins.right))
        }

        var yScale = createScaleLinear([70, 190], [(dimensions.height-(margins.bottom + margins.top)), (margins.top)]);
        var xScale = createScaleLinear([100, 40], [(dimensions.width-(margins.left + margins.right)), (margins.left)]);
        let yAxisGenerator = getLeftAxis(yScale);
        let yAxis = appendToParent(g, 'g', "yAxis", null).call(yAxisGenerator);
        let xAxisGenerator = getBottomAxis(xScale);
        let xAxis = appendToParent(g, 'g', "xAxis", "translate(0," + (dimensions.height-(margins.bottom + margins.top)) + ")").call(xAxisGenerator);

        g.append("rect")
            .attr("class", "barchart_bar-bp-red")
            .attr("x", 0)
            .attr("y", chartArea.height-yScale(90))
            .attr("width", xScale(60))
            .attr("height", chartArea.height - yScale(90));

        


/*        
        
        let rects = g.selectAll(".barchart_bar")

        let rectsEnter = rects.data(datasets).enter();
        rectsEnter.append("rect")
            .attr("class", "barchart_bar")
            .attr("x", (d,i)=>xScale(i))
            .attr("y", dimensions.height-(margins.bottom+margins.top))
            .attr("dIndex", function(d, i){ return i; })
            .on('click', function(d, i){ 
                try {
                    chartDataBreakdown({"selectionVal" : d.target.attributes.dIndex.value});
                } catch(e){console.log(e)}
            })
            .attr("width", xScale.bandwidth())
            .attr("height", 0);

*/
            
/********************** Chart value text ***********************************/

/*
            let fSize = 12;
            if (dimensions.width<200) fSize=8;
            //fSize=3;

        let text = g.selectAll(".barchart_bartext")
        let textEnter = text.data(datasets).enter();

        textEnter.append("text")
            .attr("class", "barchart_bartext")
            .text(d=>d)
            .attr("y", dimensions.height-(margins.bottom+margins.top))
            .attr("x", function(d,i){ return xScale(i) + (xScale.bandwidth()/2)-7;})
            .attr("font-size", fSize)
            .attr("text-anchor", "center")
           
         
        textEnter.selectAll("text")
            .transition()
            .duration(10)
            .ease(d3.easeCircle)
            .attr("y", (d,i)=>(yScale(d) - 3))
            .delay(function(d,i){return(i*100)})

      // Animation
      g.selectAll("rect")
        .transition()
        .delay(10)  
        .duration(200)
        .ease(d3.easeCircle)
        .attr("x", (d,i)=>xScale(i))
        .attr("y", d=>yScale(d))
        .delay(function(d,i){return(i*100)})
        .attr("height", d=>dimensions.height - yScale(d)-margins.bottom-margins.top);

        

let xAxisGenerator = 
    d3.axisBottom(xScale)
    .tickFormat((d,i)=>headers[i]);

let xAxis = appendToParent(g, 'g', "xAxis", `translate(0, ${dimensions.height-(margins.bottom+margins.top)})`)
              .call(xAxisGenerator);

*/

}


export function buildHorizontalBarChart(ref, data, width, height, margins, chartDataBreakdown){

    const svgDiv = d3.select(ref);
    svgDiv.selectAll("*").remove();
    //let {width, height} = getWidthAndHeight(svgDiv, margins);
    const leftSpaceBeforeChart = margins.left + 100;
    
    let datasets = data.map(d=>d.value);    
    let headers = data.map(d=>d.item);    
      
    const chartHeight = data.length * 50;
    if (height>chartHeight) height = chartHeight;
  
    var xScale = d3.scaleLinear()
                  .domain([0, d3.max(datasets, (d)=>d)])
                  .rangeRound([(margins.left), (width-(margins.right + margins.left))]);
   
    var yScale = createScaleBand(d3.range(data.length), [margins.top, (height - (margins.top + margins.bottom))], 0.1)
    var svg = createSVG(ref, width, height);
        
      addDefsToSVG(svg);
  
          svg.selectAll("rect")
            .data(datasets)
            .enter()
            .append("rect")
            .attr("class", "barchart_bar")
  //          .attr("fill", function(d,i){ return data[i].colour; })
            .attr("y", function(d, i){ return yScale(i); })
            .attr("x", function(d){ return leftSpaceBeforeChart; })
            .attr("width", function(d){ return xScale(d); })
            .attr("height", function(d,i){ 
              return (yScale.bandwidth() > 35) ? 35 : yScale.bandwidth();
            });
      
          svg.selectAll("text")
          .data(headers)
          .enter()
          .append("text")
          .text(function(d, i){ return d; })
          .attr("x", function(d,i){ return margins.left; })
          .attr("y", function(d,i){ return yScale(i) + (yScale.bandwidth()/3); })
          .attr("class", "barchart_bartext_title");
          //.attr("font-size", "16")
          //.attr("text-anchor", "left")
          //.attr("fill", primaryColour);   
    
    }
    



export function addCirclesChart(ref, config){
        
    const width = 500;
    const height = 500;

    var svg = d3.select(ref).append("svg")
            .attr("width", width)
            .attr("height", height)
            //.style('background-color', "whi")
            ;

    var numRows = 10;
    var numCols = 10;
    var percentNumber = 92;

    var twitterFill = "#4D908E";
    var twitterFillActive = "#adf7b6";

    var data = d3.range(numCols*numRows);
 
    var y = d3.scaleBand()
        .range([0,250])
        .domain(d3.range(numRows));

    var x = d3.scaleBand()
        .range([0, 250])
        .domain(d3.range(numCols));
    
    var container = svg.append("g")
        .attr("transform", "translate(120,120)");

        container
            .selectAll("circle")
            .data(data)
            .enter().append("circle")
            .attr('cx', function(d){return x(d%numCols);})
            .attr('cy', function(d){return y(Math.floor(d/numCols));})
            .attr('r', 12)
            .attr('fill', function(d){return d < percentNumber ? twitterFillActive : twitterFill;})
            .style('stroke', 'black');

}


export function addWidget(ref, data, margins, config){
    if (data.length<=0) return;
    const svg = d3.select(ref).append("svg");
    const width = 150;
    const height = 120;

    svg.attr("width", width);
    svg.attr("height", height);

    svg.append("text")
        .text(data[0].value)
        .attr("x", "10")
        .attr("y", "100")
        .attr("font-size", 40)
        .attr("class", "widget_value");
        
        //widgetArrow(svg, "");
        widgetArrow(svg, "down");
}

function widgetArrow(svg, direction) {

    let startPoint = {x:"10", y:"30"};
    let endPoint = {x:"130", y:"90"};
    
    if (direction == "down") {
        startPoint.y = 70;
        endPoint.y = 20;
    } 
    
    const defs = svg.append("defs")
    const marker = defs.append("marker")
        .attr("id", "arrowhead")
        .attr("markerWidth", "6")
        .attr("markerHeight", "10")
        .attr("refX", "0.1")
        .attr("refY", "2")
        .attr("orient", "auto")
        .append("path")
        .attr("d", "M0,0 V4 L2,2 Z")
        .attr("fill", "#4cc1bd");
  
    const line = svg.append("line")
        .attr("x1", startPoint.x)
        .attr("y1", startPoint.y)
        .attr("x2", endPoint.x)
        .attr("y2", endPoint.y)
        .attr("stroke", "#4cc1bd")
        .attr("stroke-width", "8")
        .attr("marker-end", "url(#arrowhead)");


} 

export function addDefsToSVG(svg){
    var defs = svg.append("defs");
                var gradient = defs.append("linearGradient")
                .attr("id", "svgGradient")
                .attr("x1", "0%")
                .attr("x2", "100%")
                .attr("y1", "0%")
                .attr("y2", "100%");
                
                gradient.append("stop")
                .attr("class", "start")
                .attr("offset", "0%")
                .attr("stop-color", "#4cc1bd")
                .attr("stop-opacity", 1);
                
                gradient.append("stop")
                .attr("class", "end")
                .attr("offset", "100%")
                .attr("stop-color", "#dcf5f3")
                .attr("stop-opacity", 1);
  
}

export const createSVG=(ref, width, height)=>{
    const svg = d3.select(ref)
        .append("svg")
        .attr("width", width)
        .attr("height", height);
        addDefsToSVG(svg);
        return svg;
}


export const getWidthAndHeight=(obj, m)=> {
    let width = obj.clientHeight;
    let height = obj.clientWidth;
    return {width, height};
}

export function appendToParent(parent, type, className, transformation){return parent.append(type).attr("class", className).attr("transform", transformation);}
export function createScaleLinear(domain, range){return d3.scaleLinear().domain(domain).rangeRound(range);}
export function createScaleBand(domain, range, paddingInner){return d3.scaleBand().domain(domain).rangeRound(range).paddingInner(paddingInner);}
export function getLeftAxis(scale){return d3.axisLeft(scale);}
export function getBottomAxis(scale){return d3.axisBottom(scale);}
