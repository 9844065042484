import {useState, useEffect} from 'react';
import { VictoryPie, VictoryTheme  } from 'victory';
import { API_POST } from '../../../components/API/API_POST';


export const PieChart=({options})=>{

    const [data, setData] = useState([0,10,24,85,32,76,13]);
    const [tickValues, setTickValues] = useState([]);

    function gotData(postdata){
        console.log({postdata});
        setData(postdata);  
    }

    useEffect(()=>{
        const ticks = data.map(d=>d["CallData"]);
        setTickValues(ticks);
    },[data])

    function gotError(err){
      console.log({err});

    }

    useEffect(()=>{
      return;
      const postData = {
        firstName: 'andrewchapman@arquella.co.uk',
        lastName: 'Flintstone',
        repType: options.data,
        dataID: "",
        startDate: "",
        endDate: ""
      };
      
      API_POST('/trends/data', postData, gotData, gotError);
      console.log("posting data");  
    },[])

    
    return (

<VictoryPie
  data={[
    { x: "Cats", y: 35 },
    { x: "Dogs", y: 40 },
    { x: "Birds", y: 55 }
  ]}
/>      )
    
}