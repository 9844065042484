import React from 'react';
import './LiveCall.css';
import './LiveDashboard.css';
import DashboardIcon from  './DashboardIcon';
import IMG_PCARE from '../../images/icons/personal_care.png';
import IMG_CONT from '../../images/icons/continence.png';
import IMG_NUT from '../../images/icons/nutrition_hydration.png';
import IMG_SLP from '../../images/icons/sleep_rest.png';
import IMG_SOC from '../../images/icons/social.png';
import IMG_MOV from '../../images/icons/moving.png';
import IMG_TOGGLE_NEG from '../../images/icons/toggle_negative.png';

/*
import FadeIn from '../../../components/FadeIn/FadeIn';
import UpTimer from '../../../components/timers/UpTimer';
import moment from "moment";
import './LiveCall.css';

*/


const LiveCall=({data})=>{


    const colours = {
        "All Calls": "#4cc1bd",
        "Emergency": "#EC1848",
        "Call": "#F4804D",
        "Accessory": "#914397",
        "Attendance": "#93C967",
        "Assistance": "#F8DA3C",
        "CareCall": "#e11583"
    };
    
    const CareComment = (props) => {
        let comment = props;
    
        return (
            <div class="caretooltip_comment">
                <div className="dashboard_careclick_comment_icon">i</div>
                <div class="caretooltiptext_comment">
                    <div className="caretooltiptext_comment_title">Comment</div>
                    <div className="caretooltiptext_comment_text">{props.comment}</div>
                </div>
            </div>
        );
    }
    
    const BuildCareIcon = (props) => {
       
        return (
            <div class="caretooltip">
                <img className="care_selected" alt={props.tooltip} src={props.src} />
                <span class="caretooltiptext">{props.tooltip}</span>
            </div>
        );
    }

    const CareDelivered=({care})=>{
        console.log("care", care);
        if (!care) return null;
        return( 

            <div className='flex-column align-left dashboard_item_section'>
                {//<hr style={{width:'100%'}} />
    }
                <div className='text-align-left'><b>Care</b></div>
                <div className='flex-row align-left flex-wrap-wrap'>
                {care&&Object.values(care).map(c=>{
                if (c.CallType == "personal") return <BuildCareIcon tooltip="Personal Care" alt="Personal Care" src={IMG_PCARE} />;
                if (c.CallType == "continence") return <BuildCareIcon tooltip="Continence" alt="Continence" src={IMG_CONT} />;
                if (c.CallType == "nutandhyd") return <BuildCareIcon tooltip="Nutrition and Hydration" alt="Nutrition and Hydration" src={IMG_NUT} />;
                if (c.CallType == "sleep") return <BuildCareIcon tooltip="Sleep" alt="Sleep" src={IMG_SLP} />;
                if (c.CallType == "social") return <BuildCareIcon tooltip="Social" alt="Social" src={IMG_SOC} />;
                if (c.CallType == "moving") return <BuildCareIcon tooltip="Moving" alt="Moving" src={IMG_MOV} />;
                if (c.CallType == "Comments") return <CareComment 
                                        tooltip="Care Comment" 
                                        alt="Care Comment" 
                                        src={IMG_TOGGLE_NEG} 
                                        comment={c.CallValue} />;

                return null;
            })}    
                </div>
            </div>    
        );

    }



    return(
        <button className={'live_call_button dashboard_' + data.callType}>
            <div className='live_call_card'>
                {(data.callType)&&<DashboardIcon callType={data.callType}/>}
                <div className='flex-column align-left'>
                    <div className='flex-column align-left'>
                        <div>{data.room}</div>
                        <div><small>{data.zone}</small></div>
                        <div><small>{data.Resident}</small></div>
                    </div>

                    {(data.Carer)&&<div className='dashboard_item_section'><b>Carer:</b> {data.Carer}</div>}

                    <CareDelivered care={data["Care Delivered"]} />

                </div>
                
                <div>{//00:00:00
} 
                        {//<UpTimer startTime={dateVal} />
                        }
                </div>
            
            </div>
        </button>
        
    );
}





/*

                        <AttendanceData attendanceData={data.Attendance} />

                        
                        
                        
                        {(data.AccessoryType)&&<div className='dashboard_item_section'><b>Accessory Type:</b> {data.AccessoryType}</div>}

                    </div>

            
*/

/*
const LiveCall=({data})=>{
  
    
    const AttendanceData=({attendanceData})=>{
        
        if (!attendanceData) return null;
        return( 

            <div className='flex-column align-left dashboard_item_section'>
                <hr style={{width:'100%'}} />
                <div><b>Attended:</b></div>
                <div className='flex-column align-left'>
                    {attendanceData&&attendanceData.map((item, index)=>{
                        return(
                            <div key={index} className='flex-row align-left justify-content-between'>
                                <div>{item.user}</div>
                                <AttendanceTimer start={item.start} end={item.end} />
                            </div>
                        );
                    })}
                </div>
            </div>    
        );
    }

    //const dateVal = getDateVal(data.startTime, data.start);
    return(
        <FadeIn duration="500">
            <button className={'live_call_button ' + data.callType}>
                <div className='live_call_card'>
                    {(data.callType)&&<DashboardIcon callType={data.callType}/>}
                    <div className='flex-column align-left'>
                        <div className='flex-column align-left'>
                            <div>{data.room}</div>
                            <div><small>{data.zone}</small></div>
                            <div><small>{data.Resident}</small></div>
                            
                        </div>
                        
                        {//(data.AccessoryType)&&<div className='dashboard_item_section'><b>Accessory Type:</b> {data.AccessoryType}</div>
                        }

                        <AttendanceData attendanceData={data.Attendance} />

                        <CareDelivered care={data["Care Delivered"]} />
                        
                        {(data.Carer)&&<div className='dashboard_item_section'><b>Carer:</b> {data.Carer}</div>}
                        {(data.AccessoryType)&&<div className='dashboard_item_section'><b>Accessory Type:</b> {data.AccessoryType}</div>}

                    </div>
    

                    <div>00:00:00 
                        {//<UpTimer startTime={dateVal} />
                        }

                    </div>
                </div>
            </button>
        </FadeIn>    
    );

}

const AttendanceTimer=({start, end})=>{

    return(
        <div>17:24 <br /><small>(3 mins)</small></div>
    );

}


function getDateVal(startTime, strDate){
    
    function getTimeFromLongDate(strDate) {
        let time = strDate;
        if (strDate.length > 8) {
            time = strDate.substring(11, 19);
        }
        return time;
    }

    startTime = getTimeFromLongDate(startTime);
    const dt = strDate.substring(0,10);
    let d = moment(dt + " " + startTime, "YYYY-MM-DD HH:mm:ss");
    
    if (d.year!=="2022") {
        console.log("is mot valid");
        d = moment(dt + " " + startTime, "DDMMYYYY HH:mm:ss");
    }

    if (d.add(1,"hour").diff(moment())) {
        console.log("is greater than");
    }
    const nowVal = moment();

    let dateVal;
    
    if (d > nowVal) {
        dateVal = nowVal.format("YYYY/MM/DD HH:mm:ss");
    }
    else {
        dateVal = d.format("YYYY/MM/DD HH:mm:ss");
    }
    return dateVal;
}


const LiveCallOld=(props)=>{
    
    const buttonStyle2 = {
        borderRadius: "8px",
        width: "100%",
        border: "none",
        boxSizing: "border-box",
        display: "flex",
        flexDirection: "row",
        alignItems: "top",
        color: "white",
        backgroundColor: colours[props.callType ? props.callType : ""],
        position: "relative",
        padding: "2px",
        boxShadow: "0px -5px 15px rgba(50, 50, 50, 0.15)",
        marginBottom: "2px"
    }
    

    function getTimeFromLongDate(strDate) {
        let time = strDate;
        if (strDate.length > 8) {
            time = strDate.substring(11, 19);
        }
        return time;
    }

    let startTime = getTimeFromLongDate(props.startTime);
    const dt = props.startDate.substring(0,10);
    let d = moment(dt + " " + startTime, "YYYY-MM-DD HH:mm:ss");
    
    if (d.year!=="2022") {
        console.log("is mot valid");
        d = moment(dt + " " + startTime, "DDMMYYYY HH:mm:ss");
    }

    if (d.add(1,"hour").diff(moment())) {
        console.log("is greater than");
    }
    const nowVal = moment();

    let dateVal;
    
    if (d > nowVal) {
        dateVal = nowVal.format("YYYY/MM/DD HH:mm:ss");
    }
    else {
        dateVal = d.format("YYYY/MM/DD HH:mm:ss");
    }
    console.log("DAATTEVAL", dateVal);
    let callType = (typeof(callType)=="undefined") ? "" : props.callType;

    const CareDelivered=({care})=>{
        
        return(<div className='flex flex-row'>
            {Object.values(care).map(c=>{
                if (c.CallType == "personal") return <BuildCareIcon tooltip="Personal Care" alt="Personal Care" src={IMG_PCARE} />;
                if (c.CallType == "continence") return <BuildCareIcon tooltip="Continence" alt="Continence" src={IMG_CONT} />;
                if (c.CallType == "nutandhyd") return <BuildCareIcon tooltip="Nutrition and Hydration" alt="Nutrition and Hydration" src={IMG_NUT} />;
                if (c.CallType == "sleep") return <BuildCareIcon tooltip="Sleep" alt="Sleep" src={IMG_SLP} />;
                if (c.CallType == "social") return <BuildCareIcon tooltip="Social" alt="Social" src={IMG_SOC} />;
                if (c.CallType == "moving") return <BuildCareIcon tooltip="Moving" alt="Moving" src={IMG_MOV} />;
                if (c.CallType == "Comments") return <CareComment 
                                        tooltip="Care Comment" 
                                        alt="Care Comment" 
                                        src={IMG_TOGGLE_NEG} 
                                        comment={c.CallValue} />;
                //c.CallType
                return null;
            })}    
            </div>);
    }
    

    const CarerView=({carer, careDelivered, reason, comments})=>{
        if ((carer=="Room Unit") && (careDelivered.length==0) && (reason=="") && (comments=="")) return null;
        return (
            <tr>
                <td colSpan={3}>
                    <div className='flex flex-column p-3'>
                        {((props.carer)&&(props.carer!="Room Unit")&&(props.carer!="")) 
                        &&  <div className='flex flex-row'>
                                <div><b>Attending: </b></div>
                                <div>{props.carer}</div>
                            </div>}
                        {((props.carer)&&(props.careDelivered)) 
                            &&  <div className='flex flex-row'>
                                    <div><CareDelivered care={props.careDelivered}/></div>
                                </div>}
                    </div>
                </td>
            </tr>
        );
    }


    return(
        <FadeIn duration="500">
            <button 
                    style={buttonStyle2}
            >
                <div className='live_call_card'>

                    <DashboardIcon callType={props.callType}/>
                    <div className='text-align-left'>
                        {props.room}
                        <br/>
                        {((props.zone!=undefined)&&(props.zone!="")) ? <small>{props.zone}</small> : ""}
                        <br/>
                        <small>{props.callType}</small>
                    </div>
                    <UpTimer startTime={dateVal} />
                    {((props.carer!="Room Unit")) && <div className='span-rows-two flex flex-row'><div><small><b>Attending: </b></small>&nbsp;</div><div><small>{props.carer}</small></div></div>}
                    {((props.reason=="Room Unit")) && <div className='span-rows-two flex flex-row'><div><small><b>Reason: </b></small>&nbsp;</div><div><small>{props.reason}</small></div></div>}
                    {((props.comment=="Room Unit")) && <div className='span-rows-two flex flex-row'><div><small><b>Comment: </b></small>&nbsp;</div><div><small>{props.comment}</small></div></div>}
                    {((props.careDelivered)) && <div className='span-rows-two flex flex-row justify-self-right'><div><CareDelivered care={props.careDelivered}/></div></div>}
                </div>
            </button>
        </FadeIn>    
    );

}







*/



export default LiveCall;