import './Live.css';
import {LiveDashboardController} from '../../components/LiveDashboard/LiveDashboardController';
import {LiveCallsController} from '../../components/LiveCalls/LiveCallsController';

const Live = () => {


    return(
        <div className="LiveDashboard_Container">
            <LiveDashboardController />
            <LiveCallsController />
        </div>
    );

};

export default Live;