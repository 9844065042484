import { Charter } from "../Charter/Charter";
import { Call_Data, Call_Data_List, Carer_Time_In_Room, Move_Data } from "../DummyData";

export const ResidentMoveScreen = () => {

    return(
        <div className='resident_call_screen'>
        <div className="flex-column">
            <div className="flex-row">
                <Charter data={Carer_Time_In_Room} chartType="HorizontalBarChart" />
            </div>
            <div>
                <Charter chartType={"DataTable"} data={Move_Data} showFields={[
                    {display:"Carer", field:"carer"},
                    {display:"In", field:"start"},
                    {display:"Out", field:"end"},
                    {display:"Duration", field:"duration"},
                    {display:"Call Raised?", field:"callRaised"}
                ]} />
            </div>
        </div>
    </div>
    );
}