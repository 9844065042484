import { Charter } from "../Charter/Charter";
import { Call_Data, Call_Data_List } from "../DummyData";

export const ResidentCallScreen = () => {
    
    return(
        <div className='resident_call_screen'>
            <div className="flex-column">
                <div className="flex-row">
                <Charter data={Call_Data} chartType="HorizontalBarChart" />
                </div>
                <div>
                <Charter 
                        chartType={"DataTable"} data={Call_Data_List} 
                        showFields={[
                            {display:"Journey Reference", field:"journeyRef"},
                            {display:"Zone", field:"zone"},
                            {display:"Room", field:"room"},
                            {display:"Call Type", field:"callType"},
                            {display:"Start", field:"startFullDate"},
                            {display:"End Time", field:"end"},
                            {display:"Duration", field:"duration"},
                            {display:"Carer", field:"carer"},
                                                        
                        ]}
                    />
                </div>
            </div>
        </div>
    );
}