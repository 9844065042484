const getDashboardConfig=()=>{

    return new Promise(async (resolve,reject)=>{

        const configjson = [

            {
                key: "C000",
                x: 0,
                y: 0,
                colspan: 2,
                rowspan: 2,
                chartName:"VerticalBarChart",
                data: "TotalCallsByDay",
                title: "Total Calls",
                subTitle: "Total calls per day.",
                caption:"",
                information: ""
            },
            
             {
                key: "C001",
                x: 0,
                y: 0,
                colspan: 1,
                rowspan: 1,
                chartName:"LineChart",
                data: "TotalCallsByDay",
                title: "Non emergency v emergency calls",     
                subTitle: "",
                caption:"",
                information: ""
            },
            {
                key: "C002",
                x: 0,
                y: 0,
                colspan: 1,
                rowspan: 1,
                chartName:"PieChart",
                data: "TotalCallsByDay",
                title: "Average call response time",     
                subTitle: "",
                caption:"",
                information: ""
            },
            {
                key: "C003",
                x: 0,
                y: 0,
                colspan: 1,
                rowspan: 1,
                chartName:"ColumnStack",
                data: "TotalCallsByDay",
                title: "Average call response time by zone",     
                subTitle: "",
                caption:"",
                information: ""
            },
            {
                key: "C004",
                x: 0,
                y: 0,
                colspan: 1,
                rowspan: 1,
                chartName:"ScatterChart",
                data: "",
                title: "Average call response time against KPI for both of the above",     
                subTitle: "",
                caption:"",
                information: ""
            },
            {
                key: "C005",
                x: 0,
                y: 0,
                colspan: 1,
                rowspan: 1,
                chartName:"GuageChart",
                data: "",
                title: "Average call duration",     
                subTitle: "",
                caption:"",
                information: ""
            },
            {
                key: "C006",
                x: 0,
                y: 0,
                colspan: 1,
                rowspan: 1,
                chartName:"ProgressBar",
                data: "",
                title: "Average call duration by zone",     
                subTitle: "",
                caption:"",
                information: ""
            },
            {
                key: "C007",
                x: 0,
                y: 0,
                colspan: 1,
                rowspan: 1,
                chartName:"HorizontalGroupedBarChart",
                data: "",
                title: "Call volume by room/resident",     
                subTitle: "",
                caption:"",
                information: ""
            },
            {
                key: "C008",
                x: 0,
                y: 0,
                colspan: 1,
                rowspan: 1,
                chartName:"LineChart",
                data: "",
                title: "Call responses by carer",     
                subTitle: "",
                caption:"",
                information: ""
            },
            {
                key: "C009",
                x: 0,
                y: 0,
                colspan: 1,
                rowspan: 1,
                chartName:"widget",
                data: "",
                title: "Average call duration by carer",     
                subTitle: "",
                caption:"",
                information: ""
            },
            {
                key: "C010",
                x: 0,
                y: 0,
                colspan: 1,
                rowspan: 1,
                chartName:"VerticalBarChart",
                data: "",//CareClicksByClick
                title: "Care delivered by type",     
                subTitle: "",
                caption:"",
                information: ""
            }






/*
            {
                key: "C000",
                x: 0,
                y: 0,
                colspan: 1,
                rowspan: 1,
                chartName:"widget",
                data: "AvrRespTime",
                title: "Average call response time",     
                subTitle: "Average call response time.",
                caption:"",
                information: ""
            },
            {
                key: "C001",
                x: 0,
                y: 0,
                colspan: 1,
                rowspan: 1,
                chartName:"widget",
                data: "AvrDuration",
                title: "Average call duration",     
                subTitle: "Average call duration time.",
                caption:"",
                information: ""
            },
            {
                key: "C003",
                x: 5,
                y: 0,
                colspan: 1,
                rowspan: 1,
                chartName:"VerticalBarChart",
                title: "Calls By Type",
                subTitle: "Number of calls by type.",
                data: "calls_per_day"
            },
            
            
        ];
        */
/*
        const configjson = [
            {
                key: "C000",
                x: 0,
                y: 0,
                colspan: 1,
                rowspan: 1,
                chartName:"widget",
                data: "TotalCallsWidget",
                title: "Total calls",     
                subTitle: "Number of Emergency calls.",
                caption:"This is a 10% increase from last week",
                information: ""
            },
         
            {
                key: "C001",
                x: 1,
                y: 0,
                colspan: 1,
                rowspan: 1,
                chartName:"widget",
                data: "EmergencyCallsWidget",
                title: "Emergency",
                subTitle: "Number of Emergency calls.",
                status: "negative",
                value: "23",
                caption:"This is a 10% increase from last week",
                tooltip: "",
                information: ""
            },
            {
                key: "C002",
                x: 2,
                y: 0,
                colspan: 1,
                rowspan: 1,
                chartName:"widget",
                data: "EscalatedCallsWidget",
                title: "Escalated",
                subTitle: "Number of Escalated calls.",
                status: "negative",
                value: "23",
                caption:"This is a 10% increase from last week",
                tooltip: "",
                information: ""
            },
            {
                key: "C003",
                x: 3,
                y: 0,
                colspan: 1,
                rowspan: 1,
                chartName:"widget",
                data: "CareTotalTimeWidget",
                title: "Care Score",
                subTitle: "Hours of care.",
                status: "negative",
                value: "23",
                caption:"This is a 10% increase from last week",
                tooltip: "",
                information: ""
            },
            {
                key: "C004",
                x: 4,
                y: 0,
                colspan: 1,
                rowspan: 1,
                chartName:"widget",
                data: "MaintenanceCallsWidget",
                title: "Maintenance",
                subTitle: "Maintenance warnings.",
                status: "negative",
                value: "23",
                caption:"This is a 10% increase from last week",
                tooltip: "",
                information: ""
            },
         



            
            {
                key: "C006",
                x: 0,
                y: 0,
                colspan: 3,
                rowspan: 2,
                chartName:"VerticalBarChart",
                title: "Daily Calls",
                subTitle: "Number of calls per day.",
                data: "calls_per_day"
            },


            {
                key: "C007",
                x: 5,
                y: 1,
                colspan: 1,
                rowspan: 2,
                chartName:"VerticalBarChart",
                title: "Hourly Calls",
                subTitle: "Number of calls per day.",
                data: "calls_per_day"
            },
            
            {
                key: "C009",
                x: 3,
                y: 1,
                colspan: 2,
                rowspan: 2,
                chartName:"HorizontalBarChart",
                title: "Call Types",
                subTitle: "Total of calls by call types.",
                data: "calls_by_type"
            }
            ,
            
            {
                key: "C010",
                x: 0,
                y: 2,
                colspan: 6,
                rowspan: 5,
                chartName:"DataTable",
                title: "All calls",
                subTitle: "",
                data: "all_calls"
            }
            
        
  */    
        ];  
        resolve(configjson);

    });
}

export default getDashboardConfig;