import { Charter } from "../Charter/Charter";
import { Call_Data, Call_Data_List, Carer_Time_In_Room, Care_Summary } from "../DummyData";

export const ResidentCareScreen = () => {

    return(
        <div className='resident_call_screen'>
        <div className="flex-column">
            <div className="flex-row">
                <Charter data={Care_Summary} chartType="HorizontalBarChart" />
            </div>
            <div>
                <Charter chartType={"DataTable"} data={Call_Data_List} />
            </div>
        </div>
    </div>
    );
}