import {useState, useEffect} from 'react';
import { VictoryLine, VictoryChart, VictoryAxis, VictoryTheme  } from 'victory';
import { API_POST } from '../../../components/API/API_POST';


export const LineChart=({options})=>{

    const [data, setData] = useState([0,10,24,85,32,76,13]);
    const [tickValues, setTickValues] = useState([]);

    function gotData(postdata){
        console.log({postdata});
        setData(postdata);  
    }

    useEffect(()=>{
        const ticks = data.map(d=>d["CallData"]);
        setTickValues(ticks);
    },[data])

    function gotError(err){
      console.log({err});

    }

    useEffect(()=>{
      return;
      const postData = {
        firstName: 'andrewchapman@arquella.co.uk',
        lastName: 'Flintstone',
        repType: options.data,
        dataID: "",
        startDate: "",
        endDate: ""
      };
      
      API_POST('/trends/data', postData, gotData, gotError);
      console.log("posting data");  
    },[])

    
    return (

      <VictoryLine
        style={{
          data: { stroke: "#c43a31" }
        }}
        
        events={[{
          target: "parent",
          eventHandlers: {
            onClick: () => {
              return [
                {
                  target: "data",
                  eventKey: "all",
                  mutation: ({ style }) => {
                    return style.stroke === "black"
                      ? null
                      : { style: { stroke: "black", strokeWidth: 5 } };
                  }
                }
              ];
            }
          }
        }]}
        data={data}
      />
      )
    
}